import { defineStore } from "pinia";
import { ProductType, useServicesStore } from "@/stores/services";
import { computed, ComputedRef, ref, Ref } from "vue";
import { BaseApiServiceV4 } from "@/services/api/v4/BaseApiServiceV4";
import { useProductConfigurationStore } from "@/stores/v4/product-configuration";
import {useCreateCsvString} from "@/composables/useCreateCsvString";
import {useDownloadFile} from "@/composables/useDownloadFile";

export type ProductAssignment = {
    id: number,
    legacy_id: number,
    property_type_id: number,
    date_sent: number,
    status: string,
    source: string,
    name: string,
    address: string,
    email: string,
    phone: string,
    cost: number,
    chargeable: boolean,
    exclude_budget: number,
    best_time_to_call: string,
    invoice_id: number,
    sale_type: string,
    campaign_name: string,
    data: any[],
    appointment: {
        appointment_type: string,
        appointment_date: string,
        appointment_time: string
    },
    appointment_status: string,
    single_product_sale: boolean,
    rejection_expiry: number,
    rejection_timer: number,
    rejected: boolean,
    demoted_status?: string,
}

export type PaginatedCollection<T> = {
    data: T[],
    limit: number,
    total: number,
    offset: number,
    total_spend: number,
}

export type ProductAssignmentCollection = {
    [key in ProductType]: PaginatedCollection<ProductAssignment>
}

export type ProductAssignmentSearchOptions = {
    date_range: string[],
    name_email: string,
    state_zip_code: string,
    invoice_id: number | null,
    campaign_reference: string,
    product_assignment_id: number | null,
    status: string,
    over_budget: boolean | null,
    page: number,
    per_page: number,
    download: undefined | boolean
    [key: string]: any
}

export type RejectionPayload = {
    product_assignment_id: number,
    reason: string|null,
    description: string|null,
}

const getDefaultSearchOptions = (): ProductAssignmentSearchOptions => {
    return {
        date_range: [],
        name_email: '',
        state_zip_code: '',
        invoice_id: null,
        campaign_reference: '',
        product_assignment_id: null,
        status: '',
        over_budget: null,
        page: 1,
        per_page: 10,
        download: undefined
    }
}

const getDefaultPaginatedCollection = (): PaginatedCollection<any> => {
    return {
        data: [],
        limit: 10,
        total: 0,
        offset: 0,
        total_spend: 0,
    }
}

export const useProductAssignmentStore = defineStore('products', () => {
    const services = useServicesStore();
    const productConfigurationStore = useProductConfigurationStore();

    const productAssignments: Ref<ProductAssignmentCollection> = ref({
        [ProductType.Lead]: getDefaultPaginatedCollection(),
        [ProductType.Appointment]: getDefaultPaginatedCollection(),
        [ProductType.DirectLeads]: getDefaultPaginatedCollection(),
    });
    
    const productScope: ComputedRef<ProductType> = computed(() => services.apiServiceV4.getProductKey());
    
    const scopedProductAssignments: ComputedRef<PaginatedCollection<ProductAssignment>> = computed(() => {
        return productAssignments.value[productScope.value];
    });
    
    const searchOptions: Ref<ProductAssignmentSearchOptions> = ref(getDefaultSearchOptions());
    
    const initialized: Ref<{[key in ProductType]: boolean}> = ref({
        [ProductType.Lead]: false,
        [ProductType.Appointment]: false,
        [ProductType.DirectLeads]: false,
    });

    const productToReject: Ref<ProductAssignment | null> = ref(null);

    const showOverBudget = true;
    
    const initialize = async (): Promise<StatusResponse> => {
        if (initialized.value[productScope.value]) return { status: true }
        else return await search();
    }
    
    const search = async(): Promise<StatusResponse> => {
        const resp = await services.apiServiceV4.searchProductAssignments(searchOptions.value).catch(e => e);
        if (resp.data?.data?.status) {
            productAssignments.value[productScope.value] = resp.data.data.product_assignments;
            productAssignments.value[productScope.value].total_spend = resp.data.data.total_spend;
            productAssignments.value[productScope.value].limit = resp.data.data.product_assignments.per_page;
            productAssignments.value[productScope.value].offset = (resp.data.data.product_assignments.current_page - 1) * resp.data.data.product_assignments.per_page;

            return { status: true }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const clearSearchOptions = () => {
        searchOptions.value = getDefaultSearchOptions();
    }

    const getProductById = (productAssignmentId: number|null): ProductAssignment|null => {
        return scopedProductAssignments.value.data.find(product => product.id === productAssignmentId) ?? null;
    }

    const rejectProduct = async (payload: RejectionPayload): Promise<StatusResponse> => {
        const resp = await services.apiServiceV4.rejectProductAssignment(payload).catch(e => e);
        if (resp.data?.data?.status) {
            updateProductAssignment(resp.data.data.product_assignment);
            productConfigurationStore.updateRejectionStatus(resp.data.data.can_reject ?? null);
            return { status: true }
        }
        else return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const unrejectProduct = async (productAssignmentId: number): Promise<StatusResponse>  => {
        const resp = await services.apiServiceV4.unrejectProductAssignment(productAssignmentId).catch(e => e);
        if (resp.data?.data?.status) {
            updateProductAssignment(resp.data.data.product_assignment);
            productConfigurationStore.updateRejectionStatus(resp.data.data.can_reject ?? null);
            return { status: true }
        }
        else return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const downloadCsv = async () => {
        const resp = await services.apiServiceV4.searchProductAssignments({...searchOptions.value, download: true}).catch(e => e);
        if (resp.data?.data?.status) {
            try {
                const header = [`${productScope.value} ID`, "Campaign Name", "Email Address", "Phone Number", "Name",
                    "Address", "Date Sent", "Status", "Quote Cost", "Invoice ID", "Sale Type", "Best Time To Call"];

                //@ts-ignore
                const formattedLeads = resp.data.data.product_assignments.map(lead => [
                    lead.id, lead.campaign_name, lead.email, lead.phone, lead.name, lead.address, toLocalString(lead.date_sent), lead.status,
                    lead.cost, lead.invoice_id, lead.sale_type, lead.best_time_to_call
                ]);

                const filename = `Fixr_${productScope.value}s_${Date.now()}.csv`;

                const encodedCsv = useCreateCsvString(header, formattedLeads);

                useDownloadFile(encodedCsv, filename);
            } catch (e) {
                return { status: false, message: `There was an error downloading ${productScope.value}s, please contact support.` };
            }

            return { status: true }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const toLocalString = (timestamp: number): string => {
       if (!timestamp) return '';

       return new Date(timestamp).toLocaleString();
    }

    const updateProductAssignment = (productAssignment: ProductAssignment) => {
        const targetIndex = scopedProductAssignments.value.data.findIndex(product => product.id === productAssignment.id);
        if (targetIndex >= 0)
            scopedProductAssignments.value.data[targetIndex] = productAssignment;
    }

    const checkRejectionWillExceedQuota = async (productAssignmentId: number): Promise<DataResponse> => {
        const resp = await services.apiServiceV4.checkRejectionWillExceedQuota(productAssignmentId).catch(e => e);
        if (resp.data?.data?.status) {
            return {
                status: true,
                data: { message: resp.data.data.message ?? null },
            }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const setProductToReject = (productAssignment: ProductAssignment|null) => {
        productToReject.value = productAssignment
    }

    const setSearchOptions = (searchOptionValues: ProductAssignmentSearchOptions) => {
        searchOptions.value = searchOptionValues
    }

    return {
        productAssignments,
        scopedProductAssignments,
        searchOptions,
        showOverBudget,
        productScope,
        productToReject,

        initialize,
        search,
        clearSearchOptions,
        getProductById,
        rejectProduct,
        unrejectProduct,
        downloadCsv,
        checkRejectionWillExceedQuota,
        setProductToReject,
        setSearchOptions
    }
});