<template>
    <div>
        <Modal
            @clicked:confirm="saveCrmDeliverer"
            @clicked:cancel="closeModal"
            :confirm-label="newConfiguration ? 'Create' : 'Update'"
            key="crmDeliveryModal"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <svg  class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                    <h5 class="text-md">{{ newConfiguration ? 'Add' : 'Edit'}} {{ editingTemplate ? 'CRM Template' : 'Campaign CRM Delivery' }}</h5>
                </div>
            </template>
            <template v-slot:body>
                <div v-if="loading">
                    <p class="text-gray-500 text-center">Loading...</p>
                </div>
                <div v-else
                     :class="[saving ? 'pointer-events-none grayscale-[50%] opacity-50' : '']"
                >
                    <div v-if="editingTemplate"
                         class="pb-4 mb-4 border-b border-gray-200"
                    >
                        <div class="flex items-center gap-x-4 mb-2">
                            <ToggleSwitch
                                :small="true"
                                v-model="useCampaignSync"
                            />
                            <p class="italic">
                                Quick Sync Campaign Deliveries
                            </p>
                            <Tooltip :large="true">
                                {{ tooltips.sync }}
                            </Tooltip>

                        </div>
                        <div v-if="useCampaignSync">
                            <div v-for="campaignOption in campaignOptions"
                                class="flex items-center gap-x-3 ml-2"
                            >
                                <Checkbox
                                    :model-value="campaignOption.active"
                                    @update:model-value="toggleCampaignActive(campaignOption)"
                                />
                                <div>
                                    {{ campaignOption.name }}
                                </div>
                            </div>
                        </div>
                        <div v-else-if="deliveryStore.templateHasActiveCampaigns()" class="ml-2">
                            <p class="italic mb-1">This Template is currently in use by these Campaigns:</p>
                            <p class="whitespace-pre-wrap ml-2">{{ deliveryStore.getTemplateCampaignNames().join("\n") }}</p>
                        </div>
                    </div>
                    <div v-else-if="!editingTemplate"
                        class="grid grid-cols-2 items-start gap-x-4 pb-4 mb-4 border-b border-gray-200"
                    >
                        <div class="flex items-center gap-x-2 w-full">
                            <CustomSelect
                                class="w-full"
                                :options="crmSourceOptions"
                                v-model="selectedCrmSource"
                                @update:model-value="loadTemplateValues"
                                type="number"
                                label="Campaign CRM Delivery Source:"
                            />
                            <Tooltip :very-large="true">
                                <template v-slot:default>
                                    <p class="whitespace-pre-wrap">{{ tooltips.source[selectedCrmSource] }}</p>
                                </template>
                            </Tooltip>
                        </div>
                        <div v-if="selectedCrmSource === CrmSource.FromTemplate">
                            <CustomSelect
                                :options="crmTemplateOptions"
                                v-model="selectedCrmTemplate"
                                type="number"
                                @update:model-value="loadTemplateValues"
                                label="Create from Template:"
                            />
                        </div>
                    </div>
                    <div
                        :class="[(selectedCrmSource === CrmSource.FromTemplate || deliveryStore.editingCrmConfiguration.template_id) && 'pointer-events-none opacity-50']"
                    >
                        <div class="grid grid-cols-2 items-start gap-4">
                            <CustomInput
                                label="* Name"
                                v-model="deliveryStore.editingCrmConfiguration.display_name"
                                :required-field="true"
                                :placeholder="`${editingTemplate ? 'Template' : 'Delivery'} name...`"
                            />
                            <CustomSelect
                                :dropdown-disabled="!newConfiguration"
                                :options="crmConfigurationOptions"
                                label="Integrated CRM Providers"
                                v-model="deliveryStore.editingCrmConfiguration.crm_type"
                                type="number"
                                @change="deliveryStore.loadDefaultFields()"
                            />
                        </div>
                        <div v-if="deliveryStore.editingCrmConfiguration.crm_type >= 0">
                            <!--    SYSTEM FIELDS    -->
                            <div v-if="deliveryStore.editingCrmConfiguration.payload.system_fields" class="border-t border-gray-200 pt-6 mt-8">
                                <h2 class="font-semibold pb-3">System Fields</h2>
                                <div class="grid grid-cols-2 items-start gap-x-4">
                                    <div v-for="systemField in deliveryStore.editingCrmConfiguration.payload.system_fields"
                                        :key="systemField.key"
                                    >
                                        <div v-if="systemField.display_flag">
                                            <CustomSelect
                                                v-if="systemField.type === CrmFieldType.Dropdown"
                                                :options="getCrmDropdownOptions(systemField.payload.options)"
                                                v-model="systemField.value"
                                                :label="`${systemField.required ? '* ' : ''}${systemField.display_name}`"
                                            />
                                            <CustomInput
                                                v-else
                                                v-model="systemField.value"
                                                :label="`${systemField.required ? '* ' : ''}${systemField.display_name}`"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--    INTERACTABLES    -->
                            <div v-if="deliveryStore.currentInteractables?.length" class="flex gap-x-4 mt-6">
                                <div v-for="interactable in deliveryStore.currentInteractables">
                                    <div class="flex items-center gap-x-4">
                                        <component
                                            v-if="interactable.display_on_front_end"
                                            :is="deliveryStore.getCrmInteractableComponentMap(interactable.type)"
                                            @click="executeInteractable(interactable.method)"
                                        >
                                            {{ interactable.display_name }}
                                        </component>
                                        <div v-if="interactable.description"
                                            class="flex items-center justify-center gap-1 py-2 px-4 rounded font-display text-xs whitespace-pre-line"
                                        >
                                            {{ interactable.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--    CUSTOM HEADERS    -->
                            <div v-if="deliveryStore.usesCustomHeaders"
                                 class="border-t border-gray-500 pt-6 mt-8"
                            >
                                <h2 class="font-semibold pb-3">Custom Headers</h2>
                                <div class="grid grid-cols-2 items-start gap-4 gap-y-6">
                                    <div v-for="(customHeader, index) in deliveryStore.editingCrmConfiguration.payload.headers"
                                         :key="index"
                                    >
                                        <div class="grid grid-cols-2 gap-x-2">
                                            <CustomInput
                                                label="Key"
                                                v-model="customHeader.key"
                                                @change="validateCustomHeaders(index)"
                                            />
                                            <div class="relative">
                                                <CustomInput
                                                    label="Value"
                                                    v-model="customHeader.value"
                                                />
                                                <div class="absolute top-[0.25rem] right-2 z-10 cursor-pointer text-red-900 hover:text-red-700"
                                                     @click="deleteCustomHeader(index)"
                                                     title="Delete Field"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4">
                                                        <path fill-rule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <OutlineButton
                                    class="my-4"
                                    @click="addCustomHeader"
                                >
                                    <p>Add Custom Header</p>
                                </OutlineButton>
                            </div>
                            <!--    ADDITIONAL FIELDS    -->
                            <div v-if="deliveryStore.editingCrmConfiguration.payload.additional_fields?.length || deliveryStore.editingCrmType === CrmType.StandardWebForm" class="border-t border-gray-200 pt-6 mt-8">
                                <div class="flex items-center gap-x-3 pb-3">
                                    <h2 class="font-semibold">Preset Fields</h2>
                                    <div class="flex items-center ml-12 gap-x-3"
                                         v-if="deliveryStore.editingCrmType === CrmType.StandardWebForm"
                                    >
                                        <ToggleSwitch
                                            v-model="usePresetFields"
                                            @update:model-value="handlePresetToggle"
                                            :small="true"
                                        />
                                        <p class="italic">
                                            Use Preset Fields
                                        </p>
                                    </div>
                                </div>
                                <div class="grid grid-cols-2 items-start gap-4 gap-y-6"
                                     v-if="usePresetFields || deliveryStore.editingCrmType !== CrmType.StandardWebForm"
                                >
                                    <div v-for="additionalField in deliveryStore.editingCrmConfiguration.payload.additional_fields"
                                         :key="additionalField.key"
                                    >
                                        <div v-if="additionalField.type === CrmFieldType.Dropdown">
                                            <div class="flex items-center gap-x-4 mb-1 justify-between px-2">
                                                <p>
                                                    {{ `${additionalField.required ? '* ' : ''}${additionalField.display_name}` }}
                                                </p>
                                                <p class="text-sm text-slate-600">
                                                    key: {{ `${additionalField.key}` }}
                                                </p>
                                            </div>
                                            <CustomSelect
                                                placeholder="-"
                                                :options="(additionalField.payload.options as string[] ?? []).map(option => ({ label: option, value: option }))"
                                                v-model="additionalField.value"
                                            />
                                        </div>
                                        <div v-else>
                                            <div class="flex items-center gap-x-4 mb-1 justify-between px-2">
                                                <p>
                                                    {{ `${additionalField.required ? '* ' : ''}${additionalField.display_name}` }}
                                                </p>
                                                <p class="text-sm text-slate-600">
                                                    key: {{ `${additionalField.key}` }}
                                                </p>
                                            </div>
                                            <CustomInput
                                                v-model="additionalField.value"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--    INTERACTABLE FIELDS    -->
                            <div v-if="Object.keys(deliveryStore.editingCrmConfiguration.payload.interactable_fields ?? {}).length" class="border-t border-gray-500 pt-6 mt-8 overflow-y-auto">
                                <div v-for="[interactableGroupName, interactableFieldGroup] in Object.entries(deliveryStore.editingCrmConfiguration.payload.interactable_fields ?? {})"
                                     class="pb-6"
                                >
                                    <h2 class="font-semibold pb-3">{{ useProperCase(interactableGroupName) }}</h2>
                                    <div class="grid grid-cols-2 items-start gap-4 gap-y-6 px-2">
                                        <div v-for="interactableField in interactableFieldGroup"
                                             :key="interactableField.key"
                                        >
                                            <CustomSelect
                                                v-if="interactableField.type === CrmFieldType.Dropdown"
                                                placeholder="-"
                                                :label="interactableField.display_name"
                                                :options="[{label: '', value: null}, ...(interactableField.payload.options ?? []).map(option => ({ label: option, value: option }))]"
                                                v-model="interactableField.value"
                                            />
                                            <CustomInput
                                                v-else
                                                v-model="interactableField.value"
                                                :label="interactableField.display_name"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--    JSON FIELDS    -->
                            <div v-if="deliveryStore.usesJsonFields" class="border-t border-gray-500 pt-6 mt-8">
                                <h2 class="font-semibold pb-3">Custom JSON keys</h2>
                                <div class="flex flex-col gap-y-6">
                                    <div v-for="(jsonKey, index) in deliveryStore.editingCrmConfiguration.payload.json_fields"
                                         :key="index"
                                    >
                                        <div class="flex items-start gap-x-2">
                                            <CustomInput
                                                class="w-64"
                                                label="Key"
                                                v-model="jsonKey.key"
                                                @change="validateJsonKey(index)"
                                            />
                                            <div class="relative w-full">
                                                <textarea
                                                    class="rounded text-sm font-medium w-full border focus:outline-none outline-none focus:ring-0 focus:border-primary-500 focus:shadow-lg focus:shadow-primary-500/10"
                                                    v-model="jsonKey.value"
                                                    @change="validateJsonField(index)"
                                                    :name="jsonKey.key"
                                                    rows="4"
                                                />
                                                <div class="absolute top-[0.25rem] right-2 z-10 cursor-pointer"
                                                     @click="deleteJsonKey(index)"
                                                     title="Delete Field"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4">
                                                        <path fill-rule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <OutlineButton
                                    class="my-4"
                                    @click="addJsonKey"
                                >
                                    <p>Add JSON Key</p>
                                </OutlineButton>
                            </div>
                            <!--    CUSTOM FIELDS    -->
                            <div v-if="deliveryStore.usesCustomFields" class="border-t border-gray-200 pt-6 mt-8">
                                <h2 class="font-semibold pb-3">Custom Fields</h2>
                                <div class="grid grid-cols-2 items-start gap-4 gap-y-6">
                                    <div v-for="(customField, index) in deliveryStore.editingCrmConfiguration.payload.custom_fields"
                                         :key="index"
                                    >
                                        <div class="grid grid-cols-2 gap-x-2">
                                            <CustomInput
                                                label="Key"
                                                v-model="customField.key"
                                                @change="validateCustomFields(index)"
                                            />
                                            <div class="relative">
                                                <CustomInput
                                                    label="Value"
                                                    v-model="customField.value"
                                                />
                                                <div class="text-red-900 hover:text-red-700 absolute top-[0.25rem] right-2 z-10 cursor-pointer"
                                                    @click="deleteCustomField(index)"
                                                    title="Delete Field"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4">
                                                        <path fill-rule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <OutlineButton
                                    class="my-4"
                                    @click="addCustomField"
                                >
                                    <p>Add Custom Field</p>
                                </OutlineButton>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { computed, ComputedRef, nextTick, onMounted, Ref, ref } from "vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { CrmCustomField, CrmFieldType, CrmSource, CrmType, DeliveryType, useDeliveryStore } from "@/stores/v4/delivery";
import { useAlertStore } from "@/stores/v4/alerts";
import { useProperCase } from "@/composables/useProperCase";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import ToggleSwitch from "@/components/inputs/ToggleSwitch.vue";
import Tooltip from "@/components/Tooltip.vue";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import Checkbox from "@/components/inputs/Checkbox.vue";

interface Props {
    dataKey?: string,
    editingId: number|null,
}
const props = defineProps<Props>();

const deliveryStore = useDeliveryStore();
const alertStore = useAlertStore();
const campaignStore = useFutureCampaignStore();

const emit = defineEmits(['close:modal', 'update:delivery', 'update:template-delivery']);

const saving = ref(false);
const loading = ref(true);

const newConfiguration: ComputedRef<boolean> = computed(() => !props.editingId);
const crmConfigurationOptions: ComputedRef<CustomSelectOption[]> = computed(() => deliveryStore.availableCrmConfigurations.map(config => {
    return { label: config.name, value: config.id }
}));

const usePresetFields: Ref<boolean> = ref(true);

// Template related variables
const editingTemplate: ComputedRef<boolean> = computed(() => deliveryStore.editingDeliveryType === DeliveryType.Template);
const crmSourceOptions = [
    { label: 'Standalone', value: CrmSource.Standalone },
    { label: 'From Template', value: CrmSource.FromTemplate }
];
const selectedCrmSource: Ref<CrmSource> = ref(CrmSource.Standalone);
const crmTemplateOptions: ComputedRef<CustomSelectOption[]> = computed(() => {
    return [
        { label: '-', value: 0 },
        ...deliveryStore.companyCrmTemplates.map(template => ({label: template.display_name, value: template.id })),
    ];
});
const selectedCrmTemplate: Ref<number|null> = ref(null);
const campaignOptions: Ref<GenericObject[]> = ref(campaignStore.scopedCampaignList.map(campaign => {
    const campaignReferences = deliveryStore.getCampaignReferences();
    return {
        name: campaign.name,
        reference: campaign.reference,
        active: campaignReferences.includes(campaign.reference),
    }
}));
const useCampaignSync: Ref<boolean> = ref(false);

onMounted(async () => {
    initialize();
});

const initialize = () => {
    if (newConfiguration.value) {
        deliveryStore.clearEditingConfiguration(false);
        deliveryStore.loadDefaultFields();
    }
    else {
        checkAdditionalFieldsShouldDisplay();
        if ('template_id' in deliveryStore.editingCrmConfiguration && deliveryStore.editingCrmConfiguration.template_id) {
            selectedCrmTemplate.value = deliveryStore.editingCrmConfiguration.template_id;
            selectedCrmSource.value = CrmSource.FromTemplate;
        }
    }

    loading.value = false;
}

/**
 * Hide Additional Fields section if it contains no values
 */
const checkAdditionalFieldsShouldDisplay = () => {
    if (deliveryStore.editingCrmType === CrmType.StandardWebForm) {
        const additionalFieldsWithValues = deliveryStore.editingCrmConfiguration.payload.additional_fields?.filter(v => v.value);
        if (!additionalFieldsWithValues?.length) {
            usePresetFields.value = false;
            deliveryStore.toggleAdditionalFields(false, true);
        }
    }
}

const executeInteractable = async (methodName: string) => {
    if (saving.value) return;
    saving.value = true;

    const { status, message, data } = await deliveryStore.executeInteractable(methodName, editingTemplate.value);
    if (!status) {
        alertStore.showError(message ?? "CRM Integration interaction failed.");
    }
    else {
        if (!data || !data.status) {
            alertStore.showError(data?.message || "An error occurred.");
        }
        else {
            alertStore.closeAlert();

            if (data.interactable_fields) {
                deliveryStore.editingCrmConfiguration.payload.interactable_fields = data.interactable_fields;
            }
            if (Object.keys(data.system_fields ?? {})?.length) {
                deliveryStore.updateFieldValues(data.system_fields, 'system_fields');
            }
            if (Object.keys(data.additional_fields ?? {})?.length) {
                deliveryStore.updateFieldValues(data.additional_fields, 'additional_fields');
            }
            if (data.interactables?.length) {
                deliveryStore.editingCrmConfiguration.interactables = data.interactables;
            }
         }
    }

    saving.value = false;
}

const getDefaultCustomField = (): CrmCustomField => ({
    key: '',
    value: '',
});

const addCustomField = () => {
    deliveryStore.editingCrmConfiguration.payload.custom_fields = deliveryStore.editingCrmConfiguration.payload.custom_fields ?? [];
    deliveryStore.editingCrmConfiguration.payload.custom_fields.push(getDefaultCustomField());
}

const addCustomHeader = () => {
    deliveryStore.editingCrmConfiguration.payload.headers = deliveryStore.editingCrmConfiguration.payload.headers ?? [];
    deliveryStore.editingCrmConfiguration.payload.headers.push(getDefaultCustomField());
}

const addJsonKey = () => {
    deliveryStore.editingCrmConfiguration.payload.json_fields = deliveryStore.editingCrmConfiguration.payload.json_fields ?? [];
    deliveryStore.editingCrmConfiguration.payload.json_fields.push(getDefaultCustomField());
}

const validateCustomFields = (validateIndex?: number): boolean => {
    const systemKeys = deliveryStore.editingCrmConfiguration.payload.system_fields.map(field => field.key);
    const customKeys = deliveryStore.editingCrmConfiguration.payload.custom_fields.map(field => field.key);
    const errors: string[] = [];

    const validateField = (customField: CrmCustomField, fieldIndex: number) => {
        if (!customField.key)
            errors.push(`Custom field ${fieldIndex + 1} has no Key name.`);
        else if (systemKeys.includes(customField.key))
            errors.push(`"${customField.key}" is a System Field key and cannot be used as a Custom Field key.`);
        else {
            customKeys.forEach((key, index) => {
                if (key === customField.key && index !== fieldIndex)
                    errors.push(`"${customField.key}" is used as more than one Custom Field key name. Keys must be unique`);
            });
        }
    }

    if (!validateIndex) {
        for (let i = 0; i < deliveryStore.editingCrmConfiguration.payload.custom_fields.length; i++) {
            validateField(deliveryStore.editingCrmConfiguration.payload.custom_fields[i], i);
        }
    }
    else {
        const target = deliveryStore.editingCrmConfiguration.payload.custom_fields[validateIndex];
        validateField(target, validateIndex);
    }

    if (errors.length)
        alertStore.showError(errors.join("\n"));

    return !errors.length;
}

const validateCustomHeaders = (validateIndex: number) => {
    const errors = [];
    const target = deliveryStore.editingCrmConfiguration.payload.headers[validateIndex];
    if (!target.key)
        errors.push("Key must have a value");

    if (errors.length) {
        alertStore.showError(errors.join("\n"));
        return false
    }

    return true;
}

const validateJsonKey = (validateIndex: number): boolean => {
    const errors = [];
    const target = deliveryStore.editingCrmConfiguration.payload.json_fields[validateIndex];
    if (!target.key)
        errors.push("Key must have a value");

    if (errors.length) {
        alertStore.showError(errors.join("\n"));
        return false
    }

    return true;
};

const validateJsonField = (validateIndex: number): boolean => {
    const errors = [];
    const target = deliveryStore.editingCrmConfiguration.payload.json_fields[validateIndex];
    if (target.value) {
        try {
            JSON.parse(target.value);
        }
        catch(e) {
            errors.push("Invalid JSON content");
        }
    }

    if (errors.length) {
        alertStore.showError(errors.join("\n"));
        return false
    }

    return true;
};

const validateRequiredFields = (): boolean => {
    const errors: string[] = [];
    const validFieldValue = (inputValue: any): boolean =>
        inputValue != null
            && (typeof(inputValue) === 'number' || !!`${inputValue}`.trim());

    deliveryStore.editingCrmConfiguration.payload.system_fields.forEach(field => {
        if (field.required && !validFieldValue(field.value))
            errors.push(`The System Field "${field.display_name}" must have a value.`);
    });
    deliveryStore.editingCrmConfiguration.payload.additional_fields.forEach(field => {
        if (field.required && !validFieldValue(field.value))
            errors.push(`The Additional Field "${field.display_name}" must have a value.`);
    });

    if (errors.length) alertStore.showError(errors.join("\n"));

    return !errors.length;
}

const validateCrm = (): boolean => {
    const errors = [];
    if (selectedCrmSource.value === CrmSource.FromTemplate && !selectedCrmTemplate.value)
        errors.push("A Template must be selected when the 'From Template' source is used.");
    if (!deliveryStore.editingCrmConfiguration.display_name)
        errors.push("The CRM Configuration must have a name.");
    else if (!validateRequiredFields()) return false;
    else if (!validateCustomFields()) return false;

    if (errors.length) alertStore.showError(errors.join("\n"));

    return !errors.length;
}

const deleteCustomField = (index: number) => {
    deliveryStore.editingCrmConfiguration.payload.custom_fields.splice(index, 1);
}

const deleteCustomHeader = (index: number): void => {
    deliveryStore.editingCrmConfiguration.payload.headers.splice(index, 1);
};

const deleteJsonKey = (index: number): void => {
    deliveryStore.editingCrmConfiguration.payload.json_fields.splice(index, 1);
};

const saveCrmDeliverer = async () => {
    if (!validateCrm()) return;
    if (deliveryStore.editingDeliveryType === DeliveryType.Template) {
        saving.value = true;
        const { status, message, data } = await deliveryStore.saveCompanyCrmTemplate(useCampaignSync.value);
        if (status) {
            alertStore.showInfo("Successfully saved Template.");
            if (data?.syncSelf != null) {
                const syncPayload = data.syncSelf
                    ? deliveryStore.convertTemplateToDeliverer(undefined, newConfiguration.value)
                    : deliveryStore.editingCrmConfiguration.id;

                if (syncPayload)
                    emit('update:template-delivery', data.syncSelf, syncPayload, props.dataKey);
            }
            closeModal();
        }
        else
            alertStore.showError(message ?? "An error occurred saving the Template");

        saving.value = false
    }
    else {
        const payload = JSON.parse(JSON.stringify(deliveryStore.editingCrmConfiguration));
        payload.crm_type_display = deliveryStore.getCrmDisplayNameById(payload.crm_type);

        emit('update:delivery', payload, props.dataKey);
        closeModal();
    }
}

const loadTemplateValues = async () => {
    await nextTick();
    if (selectedCrmTemplate.value) {
        deliveryStore.loadTemplateValues(selectedCrmTemplate.value);
        checkAdditionalFieldsShouldDisplay();
    }
    if (selectedCrmSource.value === CrmSource.Standalone) {
        deliveryStore.editingCrmConfiguration.template_id = null;
    }
}

const getCrmDropdownOptions = (options: GenericObject|GenericObject[]): CustomSelectOption[] => {
    if (Array.isArray(options))
        return options.map(option => ({ label: option, value: option }));
    else if (typeof(options) === 'object')
        return Object.entries(options ?? {}).reduce((output, [key, value]) =>
            [...output, { label: key, value: value }], [] as CustomSelectOption[]);
    else
        return [];
}

const toggleCampaignActive = (campaignOption: GenericObject) => {
    if (!('campaigns' in deliveryStore.editingCrmConfiguration)) return;
    if (deliveryStore.editingCrmConfiguration.campaigns?.includes(campaignOption.reference)) {
        campaignOption.active = false;
        deliveryStore.editingCrmConfiguration.campaigns = deliveryStore.editingCrmConfiguration.campaigns?.filter(reference => reference !== campaignOption.reference) ?? [];
    }
    else {
        campaignOption.active = true;
        deliveryStore.editingCrmConfiguration.campaigns.push(campaignOption.reference);
    }
}

const handlePresetToggle = (newValue: boolean) => {
    deliveryStore.toggleAdditionalFields(newValue);
}

const closeModal = () => {
    deliveryStore.clearEditingConfiguration();
    emit('close:modal');
}

const tooltips = {
    source: {
        [CrmSource.Standalone]: 'This Campaign CRM Integration is unique to this Campaign, and may be freely edited.',
        [CrmSource.FromTemplate]: `This Campaign CRM Integration is linked to the CRM Template with the same name. This cannot be edited directly, changes must be made to the parent CRM Template as required.
                    \nChanges to the parent Template will affect ALL Campaigns with an integration linked to the same Template.
                    \nYou may unlink this integration from its parent Template by selecting Standalone from the Delivery Source options.`,

    },
    sync: `This CRM Template will have an active CRM Delivery attached to each selected Campaign, and removed from any Campaign which is de-selected.`
}

</script>