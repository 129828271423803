// Format date function expects date string as 2024-05-27T04:30:24.000000Z
// Converts to MM/DD/YYYY, HH:mm (am|pm)
export function formatDate(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    let hours = date.getHours();
    const amPm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12; // Convert hours to 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${month}/${day}/${year}, ${hours}:${minutes} ${amPm}`;
}

export const hash = Math.floor(Math.random() * 90000) + 10000;