import { defineStore } from "pinia";
import { useServicesStore } from "@/stores/services";
import { Component, computed, ComputedRef, Ref, ref } from "vue";
import CampaignLocationsSlide from "@/components/v4/wizard/slides/CampaignLocationsSlide.vue";
import { AxiosResponse } from "axios";
import { ModuleConfiguration, ReservedComponent, WizardComponentMap } from "@/stores/v4/wizard";
import { ApiResponse } from "@/services/api/v4/BaseApiServiceV4";
import CampaignWizardHeader from "@/components/v4/wizard/CampaignWizardHeader.vue";
import CampaignBudgetSlide from "@/components/v4/wizard/slides/CampaignBudgetSlide.vue";
import CampaignDeliverySlide from "@/components/v4/wizard/slides/CampaignDeliverySlide.vue";
import CampaignBiddingSlide from "@/components/v4/wizard/slides/CampaignBiddingSlide.vue";
import { CampaignType, useFutureCampaignStore } from "@/stores/v4/future-campaigns";

export enum CampaignSlide {
    Location = 'location',
    Budget = 'budget',
    Bidding = 'bidding',
    Delivery = 'delivery',
}

export type ModuleComponentNavigation = {
    id: string,
    component: Component,
    meta: GenericObject,
    enabled: boolean,
    parentRoute?: string,
    name: string,
    image?: string,
    child?: boolean,
    global?: boolean,
};

type ConfigurationCollection = {
    [key in CampaignType]: {
        moduleConfiguration: ModuleConfiguration|null,
        wizardConfiguration: ModuleConfiguration[],
    }
}

const getDefaultConfiguration = () => ({
    moduleConfiguration: null,
    wizardConfiguration: [],
});

export const useCampaignModulesStore = defineStore('modules', () => {
    const servicesStore = useServicesStore();
    const campaignStore = useFutureCampaignStore();

    const initialized: Ref<{ [key in CampaignType]: boolean }> = ref({
        [CampaignType.SOLAR_LEAD_CAMPAIGN]: false,
        [CampaignType.SOLAR_APPOINTMENT_CAMPAIGN]: false,
        [CampaignType.LEAD_CAMPAIGN]: false,
        [CampaignType.APPOINTMENT_CAMPAIGN]: false,
        [CampaignType.EXCLUSIVE_ONLY]: false,
    });

    const configurations: Ref<ConfigurationCollection> = ref({
        [CampaignType.LEAD_CAMPAIGN]: getDefaultConfiguration(),
        [CampaignType.APPOINTMENT_CAMPAIGN]: getDefaultConfiguration(),
        [CampaignType.SOLAR_APPOINTMENT_CAMPAIGN]: getDefaultConfiguration(),
        [CampaignType.SOLAR_LEAD_CAMPAIGN]: getDefaultConfiguration(),
        [CampaignType.EXCLUSIVE_ONLY]: getDefaultConfiguration(),
    });

    const moduleConfiguration: ComputedRef<ModuleConfiguration|null> = computed(() => {
        return configurations.value[campaignStore.currentCampaignType].moduleConfiguration;
    });
    const wizardConfiguration: ComputedRef<ModuleConfiguration[]> = computed(() => {
        return configurations.value[campaignStore.currentCampaignType].wizardConfiguration;
    });

    const wizardComponentMap: WizardComponentMap = {
        [ReservedComponent.Header]: CampaignWizardHeader,
        [CampaignSlide.Location]: CampaignLocationsSlide,
        [CampaignSlide.Budget]: CampaignBudgetSlide,
        [CampaignSlide.Bidding]: CampaignBiddingSlide,
        [CampaignSlide.Delivery]: CampaignDeliverySlide,
    }

    const initialize = async (): Promise<StatusResponse> => {
        const campaignType = campaignStore.currentCampaignType;
        if (initialized.value[campaignType]) return { status: true };

        return await getWizardConfiguration().catch(e => transformErrorResponse(e));
    }

    const getWizardConfiguration = async (): Promise<StatusResponse> => {
        const resp = await servicesStore.apiServiceV4.getModuleConfigurations(campaignStore.editingCampaign.type ?? 0).catch((e: AxiosResponse) => e);
        if (resp.data?.data?.status) {
            const campaignType: CampaignType = resp.data.data.campaign_type;

            if (!resp.data.data.modules?.length && !resp.data.data.wizard?.length) {
                return { status: false, message: `The requested configuration contains no Wizard or Module data.` }
            }
            configurations.value[campaignType] = {
                moduleConfiguration: resp.data.data.modules,
                wizardConfiguration: resp.data.data.wizard,
            }

            initialized.value[campaignType] = true;

            return { status: true }
        }
        else {
            return transformErrorResponse(resp);
        }
    }

    const transformErrorResponse = (response: ApiResponse|Error) => {
        return (response instanceof Error)
            ? ({ status: false, message: response.message || 'An unknown error occurred.' })
            : ({ status: false, message: response?.data?.data?.message || 'An unknown error occurred.' });
    }

    return {
        moduleConfiguration,
        wizardConfiguration,
        wizardComponentMap,
        configurations,
        initialized,

        initialize,
        getWizardConfiguration
    }
});