<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                </svg>

                <h5 class="text-md">Leads To Be Invoiced</h5>
            </div>
        </template>
        <div class="relative min-h-[16rem]">
            <LoadingSpinner v-if="!storesInitialized" :small="true" />
            <div v-else>
                <div class="bg-cyan-50 border border-cyan-100 p-4 rounded text-sm">
                    You have <strong>{{ getLeadsTotalCost() }}</strong> in un-invoiced leads.
                </div>

                <div class="flex flex-col justify-center mt-4">
                    <div class="grid grid-cols-3 gap-2 border-b border-gray-200 pb-2">
                        <strong>Campaign</strong>
                        <strong># of Leads</strong>
                        <strong>Cost</strong>
                    </div>
                    <div v-for="(campaign, i) in billingStore.billingConfigData.company_summary.campaigns"
                         :key="i"
                        class="grid grid-cols-3 gap-2 border-b border-gray-200 py-1"
                    >
                        <p>{{ campaign.name }}</p>
                        <p>{{ campaign.number_of_leads }}</p>
                        <p>${{ new Intl.NumberFormat('en-US').format(campaign.total_price ) }}</p>
                    </div>

                    <div class="grid grid-cols-3 gap-2 mt-2 mb-2 last:mb-0">
                        <strong>Summary:</strong>
                        <strong>{{ getLeadsTotal() }}</strong>
                        <strong>{{ getLeadsTotalCost() }}</strong>
                    </div>
                </div>

                <div v-if="billingStore.billingConfigData.company_summary.credits?.available_credit" class="bg-green-50 border border-green-100 p-4 rounded text-sm mt-8">
                    <div class="mb-2">
                        <strong>Credit Summary</strong>
                    </div>
                    <div v-for="credit in billingStore.billingConfigData.company_summary.credits?.types">
                        {{ credit.name }}
                        <strong>
                            ${{ credit.balance }}
                        </strong>
                    </div>
                    <div>
                        Total available credit:
                        <strong>
                            ${{ billingStore.billingConfigData.company_summary.credits?.available_credit }}
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import { useBillingStore } from "@/stores/billing.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const props = defineProps({
    storesInitialized: {
        type: Boolean,
        default: false,
    }
});

const billingStore = useBillingStore();

const getLeadsTotal = () => {
    return billingStore.billingConfigData.company_summary.campaigns?.reduce((total, campaign) => total + (parseInt(campaign.number_of_leads) ?? 0), 0) ?? 0;
}

const getLeadsTotalCost = () => {
    const total = billingStore.billingConfigData.company_summary.campaigns?.reduce((total, campaign) => total + (parseFloat(campaign.total_price) ?? 0), 0) ?? 0;
    return `$${new Intl.NumberFormat('en-US').format(total)}`;
}

</script>