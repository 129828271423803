<template>
    <div :class="[campaign.active ? 'text-green-500' : 'text-red-500']">
        <div v-if="campaign.active" class="text-green-500">
            Active
        </div>
        <div v-else-if="campaign.status_display === `Paused Temporarily`" class="text-orange-500">
            Paused, Reactivating on {{formatDate(campaign.reactivate_at)}}
        </div>
        <div v-else class="text-red-500">
            Off
        </div>
    </div>
</template>
<script setup lang="ts">
interface Props {
    campaign: Object,
}
defineProps<Props>();

const formatDate = (reactivate) => {
    const date = new Date(reactivate)

    const options = {
        month: 'short', // 'Jul'
        day: 'numeric', // 25
        year: 'numeric', // 2024
        hour: 'numeric', // 12
        minute: '2-digit', // 00
        hour12: true, // 12-hour clock
        timeZoneName: 'short' // PDT
    };

    return date.toLocaleString('en-US', options);
}
</script>

<style scoped>

</style>