<template>
    <div class="min-h-[50vh]">
        <NestedCheckboxSelectNavigation
            :navigation-data="navigationSetup"
            :current-nested-index="currentNestedIndex"
            @nav:click="navigateToLevel"
        />

        <div>
            <LoadingSpinner v-if="loading || !storesInitialized" :fullscreen="true" />
            <!--  STATE  -->
            <NestedCheckboxSelectChild
                v-if="currentNestedIndex === 0"
                :child-table-map="stateTableSetup"
                :child-table-data="stateData"
                @toggle-all="toggleAllZipsByState"
                @select-item="handleSelectState"
            />

            <!--  COUNTY  -->
            <NestedCheckboxSelectChild
                v-else-if="currentNestedIndex === 1"
                :child-table-map="(campaignStore.zipCodeCampaign || campaignStore.unrestrictedZipCodes) ? zipCodeEnabledCountyTableSetup : standardCountyTableSetup"
                :child-table-data="countyData"
                @toggle-all="toggleAllZipsByCounty"
                @toggle-checkbox="toggleAllZipsByCounty"
                @select-item="handleSelectCounty"
            />

            <!--  ZIP CODE  -->
            <NestedCheckboxSelectChild
                v-else-if="(campaignStore.zipCodeCampaign || campaignStore.unrestrictedZipCodes) && currentNestedIndex === 2"
                :child-table-map="zipCodeTableSetup"
                :child-table-data="zipCodeData"
                @toggle-checkbox="handleSelectZipCode"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, onMounted, reactive, ref, Ref, toRaw, watch } from "vue";
import NestedCheckboxSelectNavigation from "@/components/v4/wizard/generics/NestedCheckboxSelectNavigation.vue";
import NestedCheckboxSelectChild, { ChildTableHeaderItem } from "@/components/v4/wizard/generics/NestedCheckboxSelectChild.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { ReactiveVariable } from "vue/macros";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";

export type StateLocation = {
    id: number,
    state_key: string,
    state_name: string,
    total_zip_codes: number,
    total_counties: number,
    serviced_zip_codes: number,
    serviced_counties: number,
    available_counties?: number,
}

export type CountyLocation = {
    id: number,
    county_key: string,
    county_name: string,
    county?: string,
    state_key: string,
    total_zip_codes: number,
    serviced_zip_codes: number,
    serviced?: boolean,
    targeting_enabled?: boolean,
}

export type ZipCodeLocation = {
    id: number,
    county_key: string,
    state_key: string,
    city_key: string,
    city_name: string,
    zip_code: string,
}

export type KeyedZipCodeCollection = {
    [zipCode: string]: ZipCodeLocation,
}

type ServicedCountyCollection = {
    [stateKey: string]: string[],
}

enum NestedLevel {
    State = 'state',
    County = 'county',
    ZipCode = 'zipCode',
}

const campaignStore = useFutureCampaignStore();
// Bouncing the localityStore through the FutureCampaignStore as a reminder that it's still on Eloquent models
const localityStore = campaignStore.localityStore;

interface Props {
    modelValue: KeyedZipCodeCollection,
    storesInitialized: boolean,
    inputKey: string,
    importedZipCodes: { append: boolean, zipCodes: ZipCodeLocation[] },
}
const props = defineProps<Props>();

const emit = defineEmits([
    'update:modelValue'
]);

const selectedState: Ref<StateLocation|null> = ref(null);
const selectedCounty: Ref<CountyLocation|null> = ref(null);

const nestedLevelOrder: NestedLevel[] = [NestedLevel.State, NestedLevel.County, NestedLevel.ZipCode];
const currentNestedLevel: Ref<NestedLevel> = ref(nestedLevelOrder[0]);
const currentNestedIndex: ComputedRef<number> = computed(() => nestedLevelOrder.findIndex(level => level === currentNestedLevel.value));

const selectedStateName: ComputedRef<string> = computed(() => selectedState.value?.state_name ?? '');
const selectedStateKey: ComputedRef<string> = computed(() => selectedState.value?.state_key ?? '');
const selectedCountyName: ComputedRef<string> = computed(() => selectedCounty.value?.county_name ?? '');
const selectedCountyKey: ComputedRef<string> = computed(() => selectedCounty.value?.county_key ?? '');
//@ts-ignore
const servicedZipCodes: ReactiveVariable<{ [stateKey: string]: { [countyKey: string]: { total: number, ids: number[] }, total: number } }> = reactive({});

const stateData: Ref<StateLocation[]> = ref([]);
const countyData: Ref<CountyLocation[]> = ref([]);
const zipCodeData: Ref<ZipCodeLocation[]> = ref([]);

const activeZipCodes: Ref<KeyedZipCodeCollection> = ref({});
const servicedCounties: ComputedRef<ServicedCountyCollection> = computed(() =>
    Object.values(activeZipCodes.value).reduce((output: ServicedCountyCollection, zipCode) => {
        output[zipCode.state_key] = output[zipCode.state_key] ?? [];
        if (!output[zipCode.state_key].includes(zipCode.county_key)) output[zipCode.state_key].push(zipCode.county_key);
        return output;
    }, {})
);

const loading: Ref<boolean> = ref(false);

const initialize = () => {
    const initialData = JSON.parse(JSON.stringify(props.modelValue));
    activeZipCodes.value = Object.keys(initialData)?.length ? initialData : {};
    updateStateData();
    updateActiveZipCodeTotals();
}
onMounted(() => initialize());

const setNestedLevel = (level: NestedLevel) => {
    currentNestedLevel.value = level;
}

const toggleAllZipsByState = async (stateKey: string, addCodes: boolean) => {
    const countyFilter = (!campaignStore.unrestrictedZipCodes && campaignStore.zipCodeCampaign) ? (campaignStore.zipCodeCountyExceptions[stateKey] ?? []) : null;
    const stateCollection: ZipCodeLocation[][] = Object.values(await campaignStore.localityStore.getFilteredCountiesInState(stateKey, countyFilter));
    const allZipCodes = stateCollection.reduce((output, county) => {
        return Array.isArray(county) ? [ ...output, ...county ] : output;
    }, []);
    updateZipCodes(allZipCodes, addCodes);
}

const toggleAllZipsByCounty = async (countyKey: string, addCodes: boolean) => {
    await localityStore.getCountyDetails(countyKey, selectedStateKey.value);
    const countyZipCodes = toRaw((localityStore.countyDetailsStore as GenericObject)[selectedStateKey.value]?.[countyKey] ?? []);
    updateZipCodes(countyZipCodes, addCodes);
}

const handleSelectZipCode = (zipCodeId: number, addCode: boolean) => {
    const targetZipCode = zipCodeData.value.find(zipCode => zipCode.id === zipCodeId);
    if (targetZipCode) updateZipCodes([targetZipCode], addCode);
}

const updateZipCodes = (zipCodeArray: ZipCodeLocation[], addCodes: boolean) => {
    loading.value = true;

    if (addCodes) {
        zipCodeArray.forEach(zipCode => {
            if (zipCode.zip_code)
                activeZipCodes.value[zipCode.zip_code] = activeZipCodes.value[zipCode.zip_code] ?? zipCode;
        });
    }
    else {
        zipCodeArray.forEach(zipCode => {
            if (activeZipCodes.value[zipCode.zip_code]) delete activeZipCodes.value[zipCode.zip_code];
        });
    }
    updateActiveZipCodeTotals();
    updateModelValue();
    loading.value = false;
}

const handleSelectState = async (stateKey: string) => {
    const targetState = stateData.value.find(state => state.state_key === stateKey);
    if (targetState) {
        selectedState.value = targetState;
        loading.value = true;

        const countyStoreData = await localityStore.getStateDetails(stateKey);
        if (updateCountyData(countyStoreData)) {
            setNestedLevel(NestedLevel.County);
        }
        loading.value = false;
    }
}

const updateStateData = () => {
    stateData.value = (localityStore.states as StateLocation[]).reduce((output: StateLocation[], state) => {
        if ((!campaignStore.zipCodeCampaign || campaignStore.unrestrictedZipCodes) || Object.keys(campaignStore.zipCodeCountyExceptions ?? {}).includes(state.state_key)) {
            output.push({
                ...state,
                serviced_counties: servicedCounties.value[state.state_key]?.length ?? 0,
                available_counties: (campaignStore.unrestrictedZipCodes || !campaignStore.zipCodeCampaign)
                    ? state.total_counties
                    : (campaignStore.zipCodeCountyExceptions[state.state_key] ?? []).length,
            });
        }
        return output;
    }, []);
}

const handleSelectCounty = async (countyKey: string) => {
    const targetCounty = countyData.value.find(county => county.county_key === countyKey);
    if (targetCounty) {
        selectedCounty.value = targetCounty;
        loading.value = true;

        const zipCodeStoreData = await localityStore.getCountyDetails(targetCounty.county_key, selectedStateKey.value);
        if (updateZipCodeData(zipCodeStoreData)) {
            setNestedLevel(NestedLevel.ZipCode);
        }
        loading.value = false;
    }
}

const updateCountyData = (countyStoreData: CountyLocation[]) => {
    if (!countyStoreData?.length) return false;

    countyData.value = (countyStoreData as CountyLocation[]).reduce((output: CountyLocation[], county) => {
        if ((!campaignStore.zipCodeCampaign || campaignStore.unrestrictedZipCodes) || (campaignStore.zipCodeCountyExceptions[selectedStateKey.value] ?? []).includes(county.county_key)) {
            output.push({
                ...county,
                serviced_zip_codes: servicedZipCodes[selectedStateKey.value]?.[county.county_key]?.total ?? 0,
                serviced: servicedCounties.value[selectedStateKey.value]?.includes(county.county_key) ?? false,
            });
        }
        return output;
    }, []);

    return true;
}

const updateZipCodeData = (zipCodeStoreData: ZipCodeLocation[]) => {
    if (!zipCodeStoreData?.length) return false;

    const servicedCodes = servicedZipCodes[selectedStateKey.value]?.[selectedCountyKey.value]?.ids ?? [];
    zipCodeData.value = (zipCodeStoreData as ZipCodeLocation[]).map(zipCode => {
        return { ...zipCode, active: servicedCodes.includes(zipCode.id) }
    });

    return true;
}

const updateActiveZipCodeTotals = () => {
    for (const stateKey in servicedZipCodes) servicedZipCodes[stateKey] = { total: 0 };
    Object.values(activeZipCodes.value).forEach((zipCode) => {
        servicedZipCodes[zipCode.state_key] = servicedZipCodes[zipCode.state_key] ?? { total: 0 };
        servicedZipCodes[zipCode.state_key][zipCode.county_key] = servicedZipCodes[zipCode.state_key][zipCode.county_key] ?? { total: 0, ids: [] };
        servicedZipCodes[zipCode.state_key].total ++;
        servicedZipCodes[zipCode.state_key][zipCode.county_key].total ++;
        servicedZipCodes[zipCode.state_key][zipCode.county_key].ids.push(zipCode.id);
    });

    updateZipCodeData(zipCodeData.value);
    updateCountyData(countyData.value);
    updateActiveCountyTotals();
}

const updateActiveCountyTotals = () => {
    stateData.value.forEach(state => {
        state.serviced_counties = servicedCounties.value[state.state_key]?.length ?? 0;
    });
}

const navigateToLevel = (nestedLevel: number) => {
    if (nestedLevel < 2)
        selectedCounty.value = null;
    if (nestedLevel < 1)
        selectedState.value = null;
    setNestedLevel(nestedLevelOrder[nestedLevel]);
}

const updateModelValue = () => {
    const clone = JSON.parse(JSON.stringify({ ...activeZipCodes.value }));
    emit('update:modelValue', clone, props.inputKey);
}

const importZipCodes = (zipCodes: ZipCodeLocation[], appendCodes: boolean) => {
    if (!appendCodes)
        activeZipCodes.value = {};

    updateZipCodes(zipCodes, true);
}

watch(() => props.importedZipCodes, (updatedZips) => {
    if (updatedZips?.zipCodes?.length) {
        importZipCodes(updatedZips.zipCodes, updatedZips.append);
    }
});

// Child component setup
const navigationSetup = [
    { key: NestedLevel.State, name: 'All States' },
    { key: NestedLevel.County, name: selectedStateName },
    { key: NestedLevel.ZipCode, name: selectedCountyName }
];

const stateTableSetup = [
    { header: 'State', key: 'state_name', emitClick: true, emitKey: 'state_key' },
    { header: 'Serviced Counties', key: 'serviced_counties' },
    { header: campaignStore.zipCodeCampaign ? 'Available Counties' : 'Total Counties', key: campaignStore.zipCodeCampaign ? 'available_counties' : 'total_counties' },
    { header: 'Actions', type: 'selectAll', key: 'state_key', show: true },
];

const standardCountyTableSetup: ChildTableHeaderItem[] = [
    { header: 'County', key: 'county_name' },
    { header: 'Serviced', type: 'checkbox', key: 'serviced', emitKey: 'county_key', show: true },
    { header: 'Total Zip Codes', key: 'total_zip_codes' },
];

const zipCodeEnabledCountyTableSetup: ChildTableHeaderItem[] = [
    { header: 'County', key: 'county_name', emitClick: true, emitKey: 'county_key' },
    { header: 'Serviced Zip Codes', key: 'serviced_zip_codes' },
    { header: 'Total Zip Codes', key: 'total_zip_codes' },
    { header: 'Actions', type: 'selectAll', key: 'county_key', show: true },
];

const zipCodeTableSetup = [
    { header: 'Zip Code', key: 'zip_code' },
    { header: 'City', key: 'city_name' },
    { header: 'Serviced', type: 'checkbox', key: 'active', emitKey: 'id', show: true },
];

</script>