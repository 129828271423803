import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/auth";
import {useCompanyStore} from "@/stores/company";

import LoginView from '../views/LoginView.vue'
import LoginWithTokenView from '../views/LoginWithTokenView.vue'
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import Home from "@/views/dashboard/Home.vue";
import Overview from "@/views/company/Overview.vue";
import LogoAndDescription from "@/views/company/LogoAndDescription.vue";
import UploadMedia from "@/views/company/UploadMedia.vue";
import Addresses from "@/views/company/Addresses.vue";
import Contacts from "@/views/company/Contacts.vue";
import Notifications from "@/views/company/Notifications.vue";
import BillingAndPayments from "@/views/billing/BillingAndPayments.vue";
import PaymentMethods from "@/views/billing/PaymentMethods.vue";
import MissedLeads from '@/views/leads/LeadsMissed.vue';
import CompanyProfitabilityView from "@/views/profitability/CompanyProfitabilityView.vue";
import LicensesCertifications from "@/views/company/LicensesCertifications.vue";
import NoServiceSelected from "@/views/NoServiceSelected.vue";
import CustomerReviews from "@/views/customer_reviews/CustomerReviews.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import DirectLeadsOverview from "@/components/v4/direct-leads/DirectLeadsOverview.vue";
import DirectLeadsCampaigns from "@/components/v4/direct-leads/DirectLeadsCampaigns.vue";
import BiddingModule from "@/components/v4/campaigns/modules/BiddingModule.vue";
import {useUserStore} from "@/stores/user";
import {usePasswordReset} from "@/stores/passwordReset";
import LeadOverview from "@/views/v4/leads/LeadOverview.vue";
import LeadCampaigns from "@/views/v4/leads/LeadCampaigns.vue";
import AppointmentOverview from "@/views/v4/appointments/AppointmentOverview.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: LoginView,
            meta: {requiresAuth: false}
        },
        {
            path: '/login-with-token',
            name: 'login-with-token',
            component: LoginWithTokenView,
            meta: {shadowing: true}
        },
        {
            path: '/terms-and-conditions',
            name: 'terms-and-conditions',
            component: TermsAndConditions,
            meta: {requiresAuth: true}
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: ResetPasswordView,
            meta: {requiresAuth: false}
        },
        {
            path: '/',
            name: 'dashboard',
            component: Home,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/dashboard',
            name: 'dashboard-alt',
            component: Home,
            meta: {requiresAuth: true, requiresIndustryService:true}
        },
        {
            path: '/company-profitability',
            name: 'company-profitability',
            component: CompanyProfitabilityView,
            meta: {requiresAuth: true}
        },
        {
            path: '/no-service-selected',
            name: 'no-service-selected',
            component: NoServiceSelected,
            meta: {requiresAuth: true}
        },

        // Leads Routes
        {
            path: '/leads',
            name: 'leads',
            component: LeadOverview,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/leads-campaigns',
            name: 'leads-campaigns',
            component: LeadCampaigns,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/leads-bidding',
            name: 'leads-bidding',
            component: BiddingModule,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/leads-missed',
            name: 'leads-missed',
            component: MissedLeads,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/appointments',
            name: 'appointments',
            component: AppointmentOverview,
            meta: {requiresAuth: true, requiresIndustryService: true, requiresAppointmentsActive: true}
        },
        {
            path: '/direct-leads',
            name: 'direct-leads',
            component: DirectLeadsOverview,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/direct-leads-campaigns',
            name: 'direct-leads-campaigns',
            component: DirectLeadsCampaigns,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },
        {
            path: '/direct-leads-bidding',
            name: 'direct-leads-bidding',
            component: BiddingModule,
            meta: {requiresAuth: true, requiresIndustryService: true}
        },

        // Company Routes
        {
            path: '/company-profile',
            name: 'company-profile',
            component: Overview,
            meta: {requiresAuth: true}
        },
        {
            path: '/logo-and-description',
            name: 'logo-and-description',
            component: LogoAndDescription,
            meta: {requiresAuth: true}
        },
        {
            path: '/upload-media',
            name: 'upload-media',
            component: UploadMedia,
            meta: {requiresAuth: true}
        },
        {
            path: '/addresses',
            name: 'addresses',
            component: Addresses,
            meta: {requiresAuth: true}
        },
        {
            path: '/contacts',
            name: 'contacts',
            component: Contacts,
            meta: {requiresAuth: true}
        },
        {
            path: '/notification-settings',
            name: 'notification-settings',
            component: Notifications,
            meta: {requiresAuth: true}
        },
        {
            path: '/licenses-certifications',
            name: 'licenses-certifications',
            component: LicensesCertifications,
            meta: {requiresAuth: true}
        },

        // Billing & Payments
        {
            path: '/billing-and-payments',
            name: 'billing-and-payments',
            component: BillingAndPayments,
            meta: {requiresAuth: true}
        },
        {
            path: '/payment-methods',
            name: 'payment-methods',
            component: PaymentMethods,
            meta: {requiresAuth: true}
        },

        //Customer reviews
        {
            path: '/customer-reviews',
            name: 'customer-reviews',
            component: CustomerReviews,
            meta: {requiresAuth: true, requiresReviewsActive: true}
        }


    ]
});

// Navigation Guard
router.beforeEach(async (to) => {
    if (to.meta.shadowing === true) return;

    const auth = useAuthStore();
    const user = useUserStore();
    const passwordReset = usePasswordReset()
    const authenticated = auth.isSessionValid();
    const company = useCompanyStore();

    if (passwordReset.token && to.name !== 'reset-password') {
        passwordReset.set({token: passwordReset.token, email: user.email})
        return {name: 'reset-password', query: {token: passwordReset.token}}
    }

    if (passwordReset.token) return

    if (to.meta.requiresIndustryService && !company.selectedService) {
        return company.autoSelectIndustryService()
            ? to
            : {name: 'no-service-selected'};
    }

    if (user.mustResetPasswordToken && to.name !== 'reset-password') {
        return {name: 'reset-password', query: {token: user.mustResetPasswordToken, email: user.email}}
    }

    else if (to.meta.requiresAuth && !authenticated)
        return {name: 'login'};

    else if (authenticated && !company.contractAccepted && to.name !== 'terms-and-conditions')
        return {name: 'terms-and-conditions'}
    else if (!to.meta.requiresAuth && authenticated)
        return {name: 'leads'};

    else if (to.meta.requiresAppointmentsActive && !company.appointmentsActive)
        return {name: 'leads'};

    else if (to.meta.requiresReviewsActive && !company.reviewsEnabled)
        return {name: 'leads'};
});

export default router
