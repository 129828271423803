import {BaseApiService} from "@/services/api/base";
import axios from "axios";

export class Api extends BaseApiService {
    constructor(baseUrl = null) {
        super();

        this.baseUrl = baseUrl;
    }

    get axios() {
        return axios.create({
            baseURL: `${this.baseUrl}/v3` ?? '',
            headers: {
                'X-CLIENT-BEARER': this.bearer ?? ''
            }
        });
    }


    searchInvoices(searchOptions = {}, withBillingConfig = false) {
        return this.axios.get(`/companies/${this.companyId}/billing/invoices`, {
            params: {
                with_config: withBillingConfig ? 1 : 0,
                ...searchOptions,
            }
        });
    }

    downloadInvoice(invoiceId) {
        return this.axios.get(`/companies/${this.companyId}/billing/invoices/${invoiceId}/download`);
    }

    getTransactionsForInvoice(invoiceId) {
        return this.axios.get(`/companies/${this.companyId}/billing/invoices/${invoiceId}/transactions`);
    }

    payInvoiceNow(invoiceId) {
        return this.axios.post(`/companies/${this.companyId}/billing/invoices/${invoiceId}/pay-now`);
    }
    getPaymentMethods() {
        return this.axios.get(`/companies/${this.companyId}/billing/payment-methods`);
    }

    createPaymentMethod(token, type = 'Credit Card') {
        return this.axios.put(`/companies/${this.companyId}/billing/payment-methods`, {
            stripe_token: token,
            payment_type: type
        });
    }

    deletePaymentMethod(id) {
        return this.axios.delete(`/companies/${this.companyId}/billing/payment-methods/${id}`);
    }

    makePaymentMethodPrimary(id) {
        return this.axios.post(`/companies/${this.companyId}/billing/payment-methods/${id}/make-primary`);
    }
}
