<template>
    <div>
        <LoadingSpinner v-if="loading || saving" :small="true" />
        <div v-if="!loading" class="gap-y-3 flex flex-col justify-center"
             :class="saving ? 'pointer-events-none opacity-50' : ''"
        >
            <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 gap-x-4 items-center ">
                <CustomSelect
                    label="State"
                    :options="stateOptions"
                    v-model="selectedState"
                    type="number"
                    @update:modelValue="(_newValue, oldValue) => getCountyOptions(oldValue)"
                />
                <CustomSelect
                    label="County"
                    :options="countyOptions"
                    v-model="selectedCounty"
                    type="number"
                    @update:modelValue="getPricingSetup"
                />
                <CustomSelect
                    label="Property Type"
                    :options="propertyTypeOptions"
                    :dropdown-disabled="propertyTypeOptions.length < 2"
                    v-model="selectedPropertyType"
                    type="number"
                    @update:modelValue="getPricingSetup"
                />
            </div>

            <div v-if="!selectedCounty">
                <div v-if="usingCustomFloorPricing">
                    <p class="whitespace-pre-line">
                        Note: This campaign is using custom floor pricing. Unlike normal pricing, the default State price will not enforce a limit on County prices.
                        <br>Both State and County bids may be set as low as their respective custom floor price.
                    </p>
                    <p v-if="biddingStore.scopedActiveBidCounties?.length" class="mt-4">
                        <span class="text-cyan-500">Counties with explicit bids set</span>
                        <span class="italic"> - {{ activeBidCountyDisplay }}</span>
                    </p>
                </div>
                <div v-else>
                    <p class="whitespace-pre-line">
                        Note: Setting a State-level bid will raise your minimum bid price in all Counties in that State. Any County with their bid price set higher than the State bid will retain their higher price.
                        <br>You can always lower your State bid at a later time - only Counties with a higher set bid price will remain at a higher rate.
                    </p>
                    <p v-if="biddingStore.scopedActiveBidCounties?.length" class="mt-4">
                        <span class="text-cyan-500">Counties with bids higher than State default</span>
                        <span class="italic"> - {{ activeBidCountyDisplay }}</span>
                    </p>
                </div>
            </div>

            <div v-if="loading">
                <LoadingSpinner />
            </div>
            <div v-else class="justify-center gap-y-12">
                <div class="relative" v-if="!loading && Object.keys(activePrices).length">
                    <div
                        v-for="[ qualityTier, saleType ] in Object.entries(activePrices)"
                        class="mb-8"
                        :key="qualityTier"
                    >
                        <div class="flex items-center">
                            <h4 class="font-semibold py-4 mr-2">{{ qualityTier }}</h4>
                            <Tooltip v-if="tooltips[qualityTier]">{{ tooltips[qualityTier] }}</Tooltip>
                        </div>
                        <div v-if="saleType"
                             class="md:odd:bg-gray-50 text-gray-600 text-sm md:text-center items-stretch grid grid-cols-4 max-w-5xl overflow-x-auto divide-x border border-b-0 py-0">
                            <div class="odd:bg-gray-50 border-gray-200 text-center items-center grid gap-x-3">
                                <div class="bg-gray-200 py-2">
                                    <div class="flex items-center justify-center text-xs text-gray-700 uppercase font-bold">
                                        <p>Type</p>
                                    </div>
                                </div>
                                <div class="py-3 border-b font-medium" v-for="saleTypeKey in Object.keys(saleType ?? {})">
                                    {{ useProperCase(saleTypeKey) }}
                                </div>
                            </div>

                            <div class="odd:bg-gray-50 border-gray-200 text-center items-center grid gap-x-3">
                                <div class="bg-gray-200 py-2">
                                    <div class="flex items-center justify-center text-xs text-gray-700 uppercase font-bold">
                                        <p>Available</p>
                                        <Tooltip>{{ tooltips.Available}}</Tooltip>
                                    </div>
                                </div>
                                <div class="py-3 border-b" v-for="saleTypeKey in Object.keys(saleType ?? {})"
                                     :key="`${saleTypeKey}-available`"
                                >
                                    {{ getAvailable(qualityTier, saleTypeKey) }}
                                </div>
                            </div>
                            <!--   Purchased leads    -->
                            <div class="odd:bg-gray-50 border-gray-200 text-center items-center grid gap-x-3">
                                <div class="bg-gray-200 py-2 text-xs text-gray-700 uppercase font-bold">
                                    <p>Purchased</p>
                                </div>
                                <div class="py-3 border-b" v-for="saleTypeKey in Object.keys(saleType ?? {})"
                                     :key="`${saleTypeKey}-purchased`"
                                >
                                    <p class="inline">{{  getPurchased(qualityTier, saleTypeKey) }}</p>
                                    <p class="inline text-slate-400 ml-2">[{{ getPercentagePurchased(qualityTier, saleTypeKey) }}%]</p>
                                </div>
                            </div>
                            <!--   Bid   -->
                            <div class="odd:bg-gray-50 border-gray-200 text-center items-center grid gap-x-3">
                                <div class="bg-cyan-50 py-2 text-xs text-gray-700 uppercase font-bold">
                                    <p>Bid</p>
                                </div>
                                <div class="py-3 border-b" v-for="[saleTypeKey, prices] in Object.entries(saleType as SaleTypePriceCollection ?? {})"
                                     :key="`${saleTypeKey}-bid`"
                                >
                                    <div v-if="saleTypeIsActive(saleTypeKey)">
                                        <NumberWithSpinners
                                            :min="prices.minimum_price ?? prices.floor_price ?? '-'"
                                            :model-value="prices.bid_price ?? '-'"
                                            :number-step="5"
                                            step-rounding
                                            @update:modelValue="(newValue) => updateBidPrice(prices, newValue, qualityTier as QualityTier, saleTypeKey as SaleTypeKey)"
                                        />
                                    </div>
                                    <div v-else>
                                        Inactive
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, nextTick, onMounted, reactive, Ref, ref, watch } from "vue";
import { useAlertStore } from "@/stores/v4/alerts";
import { ReservedComponent, useWizardStore } from "@/stores/v4/wizard";
import { ReactiveVariable } from "vue/macros";
import NumberWithSpinners from "@/components/inputs/NumberWithSpinners.vue";
import Tooltip from "@/components/Tooltip.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import { useLocalityDataStore } from "@/stores/locality-data";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { CountyLocation, StateLocation } from "@/components/v4/wizard/slides/components/LocationCheckboxSelect.vue";
import { LocationBidCollection, LocationBidPayload, PropertyType, QualityTier, SaleTypeKey, SaleTypePriceCollection, SaleTypePrices, useBiddingStore } from "@/stores/v4/bidding";
import { useProperCase } from "@/composables/useProperCase";
import { useProductConfigurationStore } from "@/stores/v4/product-configuration";

interface Props {
    initialData: LocationBidCollection,
}
const props = defineProps<Props>();

const emit = defineEmits(['update:biddingInput']);

const campaignStore = useFutureCampaignStore();
const localityStore = useLocalityDataStore();
const alertStore = useAlertStore();
const wizardStore = useWizardStore();
const biddingStore = useBiddingStore();
const productConfiguration = useProductConfigurationStore();

const activePrices: Ref<GenericObject> = ref({});

const stateOptions: Ref<CustomSelectOption[]> = ref([]);
const countyOptions: Ref<CustomSelectOption[]> = ref([]);
const propertyTypeOptions: ComputedRef<CustomSelectOption[]> = computed(() => {
    const properties: string[] = campaignStore.fetchModuleInputValue(null, 'property_types') || [];
    if (properties.length && !properties.includes(selectedPropertyType.value)) {
        selectedPropertyType.value = properties[0] as PropertyType;
        getPricingSetup();
    }
    return properties?.map(item => ({ label: item, value: item })) ?? [];
});

const selectedState: Ref<number|null> = ref(null);
const selectedStateKey: ComputedRef<string|null> = computed(() => (localityStore.states as StateLocation[]).find(state => state.id === selectedState.value)?.state_key ?? null);

const selectedCounty: Ref<number> = ref(0);
const selectedCountyKey: ComputedRef<string|null> = computed(() => {
    return selectedCounty.value === 0
        ? 'state'
        : ((localityStore.countyList as GenericObject)[selectedStateKey.value as string] as CountyLocation[]).find(county => county.id === selectedCounty.value)?.county_key ?? null
});
const defaultPropertyType = productConfiguration.getDefaultPropertyType();
const selectedPropertyType: Ref<PropertyType> = ref(defaultPropertyType as PropertyType);

const locationBids: ReactiveVariable<LocationBidCollection> = reactive({});
const budgetData = campaignStore.fetchModuleInputValue('budget', 'budgets');
const optionalSaleTypes = productConfiguration.getOptionalBudgetKeys();

const usingCustomFloorPricing: ComputedRef<boolean> = computed(() => campaignStore.usesCustomFloorPrices);
const activeBidCountyDisplay: ComputedRef<string> = computed(() => {
    return biddingStore.scopedActiveBidCounties?.map(key => useProperCase(key))
        .join(', ')
        ?? '';
});

const loading: Ref<boolean> = ref(false);
const saving: Ref<boolean> = ref(false);

const initialize = async () => {
    loading.value = true;
    await localityStore.initialize();

    Object.assign(locationBids, props.initialData ?? {});

    for (const saleType in locationBids) {
        locationBids[saleType] = locationBids[saleType].filter(bid => bid.location_id > 0);
    }
    handleInputUpdate();

    checkPropertyTypeSelected();
    getStateOptions();

    loadStatistics();

    await getCountyOptions();

    watch(() => locationBids, () => handleInputUpdate(), { deep: true });

    watch(() => selectedState, () => loadStatistics(), { deep: true });
    watch(() => selectedCounty, () => loadStatistics(), { deep: true });
    watch(() => selectedPropertyType, () => loadStatistics(), { deep: true });

    loading.value = false;
}
onMounted(() => initialize());

const checkPropertyTypeSelected = () => {
    const inputs = campaignStore.fetchModuleInputValue(ReservedComponent.Header, 'property_types') ?? [];
    if (!inputs?.length) {
        wizardStore.setSlideInputValue(ReservedComponent.Header, 'property_types', [defaultPropertyType]);
        campaignStore.updateModulePayload(ReservedComponent.Header, [defaultPropertyType]);
    }
}

const loadStatistics = () => {
    if (!selectedState.value || !selectedPropertyType.value)
        return;

    campaignStore.loadBidingLocationStatistics(selectedState.value, selectedCounty.value ?? null, selectedPropertyType.value);
}

const saleTypeIsActive = (saleTypeKey: string) => {
    return optionalSaleTypes.includes(saleTypeKey)
        ? budgetData?.[saleTypeKey]?.status ?? false
        : true;
}

const getPricingSetup = async () => {
    loading.value = true;
    if (selectedState.value) {
        const { status, data, message } = selectedCounty.value
            ? await biddingStore.loadCountyFloorPrices(selectedPropertyType.value, selectedStateKey.value as string, selectedCountyKey.value as string)
            : await biddingStore.loadStateFloorPrices(selectedPropertyType.value, selectedStateKey.value as string);

        if (!status || !data) {
            alertStore.showError(message ?? "An error occurred fetching floor prices");
        }
        else {
            activePrices.value = data;
            if (selectedCounty.value) {
                setMinimumBids();
            }
        }
    }

    loading.value = false;
}

const getStateOptions = () => {
    const validStates: string[] = Object.values(campaignStore.getActiveZipCodes()).reduce((output: string[], zipCode) => {
        return output.includes(zipCode.state_key) ? output : [...output, zipCode.state_key];
    }, []);

    stateOptions.value = validStates.reduce((output: CustomSelectOption[], stateKey) => {
        const stateEntry = (localityStore.states as StateLocation[]).find(state => state.state_key === stateKey);
        return stateEntry
            ? [...output, { label: stateEntry.state_name, value: stateEntry.id } ]
            : output;
    }, []).sort((a, b) => a.label < b.label ? -1 : 1);

    selectedState.value = selectedState.value ?? stateOptions.value[0]?.value ?? null;
}

const getCountyOptions = async (oldValue?: number) => {
    await nextTick();
    if (oldValue === selectedState.value) return;
    if (!selectedStateKey.value) {
        countyOptions.value = [];
        return;
    }

    const validCounties: string[] = Object.values(campaignStore.getActiveZipCodes()).reduce((output: string[], zipCode) => {
        return (output).includes(zipCode.county_key) || zipCode.state_key !== selectedStateKey.value
            ? output
            : [...output, zipCode.county_key];
    }, []);

    countyOptions.value = validCounties.reduce((output: CustomSelectOption[], countyKey): CustomSelectOption[] => {
        const countyEntry = ((localityStore.countyList as GenericObject)[selectedStateKey.value as string] as CountyLocation[])?.find(county => county.county_key === countyKey);
        return countyEntry
            ? [...output, { label: countyEntry.county ?? '', value: countyEntry.id }]
            : output;
    }, []).sort((a, b) => a.label < b.label ? -1 : 1);

    countyOptions.value.unshift({ label: 'Default', value: 0 });
    selectedCounty.value = 0;
    await getPricingSetup();
}

const updateBidPrice = (prices: SaleTypePrices, newBid: number, qualityTier: QualityTier, saleType: SaleTypeKey) => {
    prices.bid_price = newBid;
    const locationId = selectedCounty.value || selectedState.value;
    if (!locationId) return;

    pushLocationBid({
        bid: newBid,
        location_id: locationId as number,
        property_type: selectedPropertyType.value,
        quality_tier: qualityTier,
        is_state_bid: selectedCounty.value === 0,
        state_location_id: selectedState.value as number,
    }, saleType);

}

const pushLocationBid = (newBid: LocationBidPayload, saleType: SaleTypeKey) => {
    if (!locationBids[saleType])
        locationBids[saleType] = [];
    const existingBid = locationBids[saleType].find(bid => (
        bid.quality_tier === newBid.quality_tier
        && bid.location_id === newBid.location_id
        && bid.property_type === newBid.property_type
    ));

    if (existingBid)
        Object.assign(existingBid, newBid);
    else
        locationBids[saleType].push(newBid);
}

const getAvailable = (qualityTier: string, saleTypeKey: string): number => {
    if (!campaignStore.statistics[qualityTier] || !campaignStore.statistics[qualityTier][saleTypeKey])
        return 0

    return campaignStore.statistics[qualityTier][saleTypeKey]['available'] ?? 0;
};

const getPurchased = (qualityTier: string, saleTypeKey: string): number => {
    if (!campaignStore.statistics[qualityTier] || !campaignStore.statistics[qualityTier][saleTypeKey])
        return 0

    return campaignStore.statistics[qualityTier][saleTypeKey]['purchased'] ?? 0;
}

const getPercentagePurchased = (qualityTier: string, saleTypeKey: string) => {
    const available = getAvailable(qualityTier, saleTypeKey) > 0 ? getAvailable(qualityTier, saleTypeKey): 1;
    const purchased = getPurchased(qualityTier, saleTypeKey);

    return ((purchased/available) * 100).toFixed(1);
}

/**
 * This method sets the minimum county bids to
 *      - active state bid, if it is higher than the county floor price
 *      - county floor, if it exists
 *      - state floor otherwise
 * It will also adjust the active bid if required, to keep it valid after the minimum price is calculated
 */
const setMinimumBids = () => {
    if (!selectedStateKey.value || !selectedCounty.value) return;
    for (const qualityTier in activePrices.value) {
        for (const saleTypeKey in activePrices.value[qualityTier]) {
            if (usingCustomFloorPricing.value) {
                activePrices.value[qualityTier][saleTypeKey].minimum_price = activePrices.value[qualityTier][saleTypeKey].floor_price;
            }
            else {
                // If there is an active state bid (above the state floor) that is also higher than the county floor
                //  set it as the minimum price for the county. Otherwise, the county floor is the minimum (state floor if no county floor exists)
                const activeStateBid = biddingStore.getStateBid(selectedStateKey.value, selectedPropertyType.value, qualityTier, saleTypeKey as SaleTypeKey);
                activePrices.value[qualityTier][saleTypeKey].minimum_price = activeStateBid && activeStateBid > activePrices.value[qualityTier][saleTypeKey].floor_price
                    ? activeStateBid
                    : activePrices.value[qualityTier][saleTypeKey].floor_price;

                const liveCountyBid = locationBids[saleTypeKey]?.find(locationBid => locationBid.location_id === selectedCounty.value)?.bid ?? 0;
                const highestStateOrStoredCountyBid = Math.max(activeStateBid ?? 0, activePrices.value[qualityTier][saleTypeKey].initial_bid_price);

                // Set the current displayed price as the first valid price found from
                //   - live county bid (user has changed bid)
                //   - highest value out of any active parent state bid, and the initial stored price (either an explicit county bid, or the county floor, or the state floor)
                //   - the current price
                if (liveCountyBid >= activePrices.value[qualityTier][saleTypeKey].minimum_price)
                    activePrices.value[qualityTier][saleTypeKey].bid_price = liveCountyBid
                else if (highestStateOrStoredCountyBid >= activePrices.value[qualityTier][saleTypeKey].minimum_price)
                    activePrices.value[qualityTier][saleTypeKey].bid_price = highestStateOrStoredCountyBid;
                else
                    activePrices.value[qualityTier][saleTypeKey].bid_price = Math.max(activePrices.value[qualityTier][saleTypeKey].minimum_price, activePrices.value[qualityTier][saleTypeKey].bid_price);
            }
        }
    }
}

const handleInputUpdate = () => {
    emit('update:biddingInput', locationBids);
}

const tooltipStore: GenericObject = {
    lead: {
        Standard: `Delivered to a maximum of 3 installers (the owner's choice through the calculator), name, address, phone number, email, etc.`,
        Premium: `When the owner has requested 'appointment times', 'best time to contact', their 'monthly electric spend is over $300', or the lead is phone qualified by our QA team.`,
        Available: `It’s possible for you to change your mix of lead types through bidding. If the customer selects they want bids from 2 installers, we will generally attempt to match them with 2 installers (Duo), but with bidding it’s possible to override this preference and begin winning the Duo leads as Exclusive if your bid is higher than the next 2 Duo bids. `
    },
    appointment: {
        ['In-Home Appointment']: `Tooltip goes here`,
        ['Online Consultation']: `Tooltip goes here`,
        Available: `Tooltip goes here`,
    },
}
const tooltips: ComputedRef<GenericObject> = computed(() => tooltipStore[campaignStore.productScope] ?? {});

</script>
