<template>
    <Modal
        @clicked:confirm="confirmPause"
        @clicked:cancel="cancelPause"
        :class="[ saving ? 'grayscale-[60%] pointer-events-none' : '' ]"
        confirm-label="Yes"
    >
        <template v-slot:header>
            Pause Campaign
        </template>
        <template v-slot:body>
            <div class="relative">
                <div class="text-center w-[97%]">
                    <p>Pausing {{ campaignName }} will <span class="text-red-700">deactivate all {{ campaignStore.getProductLabel(true) }}</span>. If you wish to proceed, select an option below.</p>
                </div>
                <hr class="my-4">
                <div class="min-h-[24rem]">
                    <div class="grid grid-cols-2 gap-4 w-[97%] mx-auto pb-4">
                        <CustomSelect
                            label="Pause Campaign:"
                            :options="pauseOptions"
                            v-model="selectedStatus"
                            type="number"
                        />
                        <div v-if="selectedStatus === CampaignStatus.PausedTemporarily" class="flex flex-col">
                            <span class="mb-1 block text-sm font-medium">Reactivation Date:</span>
                            <DatePicker
                                v-model="selectedDate"
                                auto-apply
                                :close-on-auto-apply="true"
                                :is-24="false"
                                :minutes-increment="15"
                                :no-hours-overlay="true"
                                :no-minutes-overlay="true"
                                :clearable="false"
                                :min-date="tomorrow"
                                :max-date="new Date().setMonth(new Date().getMonth() + 2)"
                            />
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4 mx-auto w-[97%] items-end">
                        <CustomSelect
                            label="Reason for pausing Campaign:"
                            :options="reasonsOptions"
                            v-model="selectedReason"
                            :selected-option=null
                        />
                        <textarea
                            class="border-slate-300 border rounded-md"
                            v-if="selectedReason === 'other'"
                            placeholder="Please enter a reason..."
                            v-model="otherReason"
                        />
                    </div>
                </div>
                <LoadingSpinner v-if="saving" class="absolute" :small="true" />
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { computed, ComputedRef, Ref, ref } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { CampaignStatus, useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import { useAlertStore } from "@/stores/v4/alerts";
import { useProductConfigurationStore } from "@/stores/v4/product-configuration";

interface Props {
    campaignName: string,
    saving: boolean,
}
defineProps<Props>();

const campaignStore = useFutureCampaignStore();
const alertStore = useAlertStore();
const productConfigurationStore = useProductConfigurationStore();

const emit = defineEmits(['confirmPause', 'cancelPause']);

const pauseOptions: ComputedRef<CustomSelectOption[]> = computed(() => {
    return productConfigurationStore.campaignStatusConfiguration.statuses.reduce((output, status, index) => {
        return index !== CampaignStatus.Active
            ? [...output, { label: status, value: index }]
            : output;
    },[] as CustomSelectOption[]);
});

const reasonsOptions: ComputedRef<CustomSelectOption[]> = computed(() => {
    return Object.entries(productConfigurationStore.campaignStatusConfiguration.reasons).map(([reasonKey, reasonLabel]) => ({ label: reasonLabel, value: reasonKey }));
});

const tomorrow = new Date(new Date(Date.now() + (1000*3600*24)).setHours(8, 0, 0, 0));

const selectedReason: Ref<string> = ref(campaignStore.defaultPauseReason);
const selectedDate: Ref<Date> = ref(tomorrow);
const selectedStatus: Ref<number> = ref(CampaignStatus.PausedTemporarily);
const otherReason: Ref<string> = ref('');

const validate = () => {
    const errors = [];
    if (selectedStatus.value === CampaignStatus.PausedTemporarily && !selectedDate.value) {
        errors.push('Please select a date.');
    }
    if (!selectedReason.value) {
        errors.push('Please select a reason for pausing your Campaign.');
    }
    if (selectedReason.value === 'other' && !otherReason.value) {
        errors.push('Please type a reason for pausing your Campaign.');
    }

    if (errors.length) {
        alertStore.showError(errors.join("\n"));
        return false;
    }

    return true;
}

const confirmPause = () => {
    if (!validate()) return;

    const payload = {
        reactivate_at: (selectedStatus.value === CampaignStatus.PausedTemporarily) ? selectedDate.value.toISOString() : null,
        reason: selectedReason.value,
        other: otherReason.value,
        status: selectedStatus.value,
    }
    emit('confirmPause', payload);
}

const cancelPause = () => {
    emit('cancelPause');
}

</script>