<template>
    <div class="flex justify-center items-center">
        <button class="w-5 h-5 rounded-full text-red-700 border-red-700 border-2 cursor-pointer"
            :class="[modelValue <= min ? 'grayscale-[60%] opacity-50 cursor-default' : '']"
            @click="spinnerDown"
            :disabled="modelValue <= min"
        >
            <svg class="w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
            </svg>
        </button>
        <div class="flex justify-center mx-4">
            <span class="cursor-default">
                {{ prefix }}
            </span>
            <input
                class="w-[4ch] text-center"
                :value="modelValue"
                :disabled="true"
            >
            <span  class="cursor-default">
                {{ suffix }}
            </span>
        </div>
        <button class="w-5 h-5 rounded-full border-green-700 text-green-700 border-2 cursor-pointer"
            @click="spinnerUp"
        >
            <svg  class="w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        </button>
    </div>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: Number,
        default: 0,
    },
    prefix: {
        type: String,
        default: "$",
    },
    suffix: {
        type: String,
        default: null,
    },
    numberStep: {
        type: Number,
        default: 1,
    },
    min: {
        type: Number,
        default: null
    },
    max: {
        type: Number,
        default: null,
    },
    stepRounding: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits([ 'update:modelValue' ]);

const updateModel = (newValue) => {
    emit('update:modelValue', newValue);
}

const spinnerUp = () => {
    const newValue = props.max == null
        ? calculateStep(true)
        : Math.min(calculateStep(true), props.max);
    updateModel(newValue);
}

const spinnerDown = () => {
    const newValue = props.min == null
        ? calculateStep(false)
        : Math.max(calculateStep(false), props.min);
    updateModel(newValue);
}

const calculateStep = (up) => {
    let step = props.numberStep;

    if (props.stepRounding) { //either the step value or the number to move to the closest step up or down
        const remainder = props.modelValue % props.numberStep;
        step = remainder === 0 ? props.numberStep : up ? props.numberStep - remainder : remainder;
    }

    return up ? props.modelValue + step : props.modelValue - step
}

</script>