import { defineStore } from "pinia";
import { Component, computed, ComputedRef, nextTick, ref, Ref } from "vue";
import { useServicesStore } from "@/stores/services";
import { AxiosResponse } from "axios";
import { BaseApiServiceV4 } from "@/services/api/v4/BaseApiServiceV4";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";

type ContactDelivery = {
    active: boolean,
    contact_id: number,
    email_active: boolean,
    sms_active: boolean,
    name: string,
    email: string,
    cell_phone: string,
}

export type DeliveryCollection = {
    contact_deliveries: ContactDelivery[],
    crm_deliveries: CrmDeliverer[],
    schedules: number[],
}

export enum DeliveryType {
    Crm = 'crm',
    Contact = 'contact',
    Template = 'template',
}

export type CrmInteractable = {
    method: string,
    display_on_front_end: boolean,
    display_name: string,
    type: number,
    description?: string,
}

export type CrmDelivererPayload = {
    system_fields: CrmConfigurationField[],
    additional_fields: CrmConfigurationField[],
    custom_fields: CrmCustomField[],
    interactable_fields?: {
        [key:string]: CrmConfigurationField[]
    },
    headers: CrmCustomField[],
    json_fields: CrmCustomField[],
}

export type CrmDeliverer = {
    id: number|string,
    crm_type: number,
    active: boolean,
    display_name: string,
    campaign_name?: string,
    crm_type_display: string,
    payload: CrmDelivererPayload,
    interactables?: CrmInteractable[],
    template_id: number|null,
}

export type CrmTemplate = {
    id: number|string,
    crm_type: number,
    display_name: string,
    crm_type_display: string,
    payload: CrmDelivererPayload,
    interactables?: CrmInteractable[],
    campaigns: string[],
    template_id: null,
}

export type CrmConfigurationField = {
    key: string,
    value: string,
    display_name: string,
    type: number,
    required?: boolean,
    payload: {
        options: string[],
    },
    display_flag: boolean,
}

export type CrmConfiguration = {
    id: number,
    key: string,
    name: string,
    system_fields: CrmConfigurationField[],
    additional_fields: CrmConfigurationField[],
    interactables: CrmInteractable[],
    headers: CrmCustomField[] | null,
    json_fields: CrmInteractable[] | null,
}

export type CrmCustomField = {
    key: string,
    value: string,
}

enum CrmInteractableType {
    Button = 0,
}

export enum CrmFieldType {
    Text = 0,
    Dropdown = 1,
}

export enum CrmType {
    StandardWebForm = 0,
    LeadConduit = 1,
    JobNimbus = 2,
    Pipedrive = 3,
    ZohoOAuth = 4,
}

export enum CrmSource {
    Standalone = 1,
    FromTemplate = 2,
}

const CrmInteractableTypeMap = {
    [CrmInteractableType.Button]: SolidButton,
}

const getDefaultCrmDeliverer = (): CrmDeliverer => structuredClone({
    id: `temp-${Date.now()}`, // temp timestamp-as-id for unsaved deliverers, in case they need deleting before saving
    crm_type: 0,
    crm_type_display: 'Standard Web Form',
    active: true,
    display_name: '',
    payload: {
        system_fields: [],
        additional_fields: [],
        custom_fields: [],
        interactable_fields: {},
        headers: [],
        json_fields: [],
    },
    template_id: null,
    campaigns: [],
});

export const useDeliveryStore = defineStore('delivery', () => {
    const services = useServicesStore();
    const campaignStore = useFutureCampaignStore();

    const availableCrmConfigurations: Ref<CrmConfiguration[]> = ref([]);

    const editingCrmConfiguration: Ref<CrmDeliverer|CrmTemplate> = ref(getDefaultCrmDeliverer());
    const editingDeliveryType: Ref<DeliveryType> = ref(DeliveryType.Crm);

    const currentInteractables: ComputedRef<CrmInteractable[]> = computed(() =>
        mergeInteractables(availableCrmConfigurations.value.find(config => config.id === editingCrmConfiguration.value.crm_type)?.interactables ?? [], editingCrmConfiguration.value.interactables ?? []));

    const initialized: Ref<boolean> = ref(false);

    const crmConfigurationOptions: ComputedRef<CustomSelectOption[]> = computed(() => {
        return availableCrmConfigurations.value.map(config => ({ label: config.name, value: config.id }));
    });

    const crmImportOptions: Ref<CrmDeliverer[]> = ref([]);

    // For now, disable Custom Fields for Pipedrive as only fields fetched with the auth_token are relevant. We may want to move this setting to the backend.
    const usesCustomFields: ComputedRef<boolean> = computed(() => {
        return editingCrmConfiguration.value.crm_type !== CrmType.Pipedrive;
    });

    const usesCustomHeaders: ComputedRef<boolean> = computed(() => {
        const targetHeaders = availableCrmConfigurations.value.find(crm => crm.id === editingCrmConfiguration.value.crm_type)?.headers ?? null;

        return targetHeaders !== null;
    });

    const usesJsonFields: ComputedRef<boolean> = computed(() => {
        const targetFields = availableCrmConfigurations.value.find(crm => crm.id === editingCrmConfiguration.value.crm_type)?.json_fields ?? null;
        if (targetFields) {
            const sendFormat = editingCrmConfiguration.value.payload.system_fields.find(field => field.key === 'send_format');
            return /^json/i.test(`${sendFormat?.value}`);
        }
        return false;
    });

    const editingCrmType: ComputedRef<number> = computed(() => editingCrmConfiguration.value.crm_type);
    const additionalFieldsCache: Ref<CrmConfigurationField[]|null> = ref(null);

    const companyCrmTemplates: Ref<CrmTemplate[]> = ref([]);

    const initialize = async (): Promise<StatusResponse> => {
        if (initialized.value) return { status: true };

        const resp = await services.apiServiceV4.getCrmConfigurations().catch((e: AxiosResponse) => e);
        if (resp.data?.data?.status) {
            availableCrmConfigurations.value = resp.data.data.crm_configurations;
            companyCrmTemplates.value = resp.data.data.crm_templates;

            initialized.value = true;
            return { status: true }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const getCrmDisplayNameById = (id: number) => {
        const targetConfig = availableCrmConfigurations.value.find(config => config.id === id);
        return targetConfig ? targetConfig.name : 'N/A';
    }

    const getCrmInteractableComponentMap = (crmType: CrmInteractableType): Component => {
        return CrmInteractableTypeMap[crmType] ?? SolidButton
    }

    const executeInteractable = async (methodName: string, isTemplate: boolean = false): Promise<DataResponse> => {
        const crmId = editingCrmConfiguration.value.crm_type;
        const resp = await services.apiServiceV4.executeCrmInteractable(crmId, methodName, editingCrmConfiguration.value, isTemplate).catch(e => e);
        if (resp.data?.data?.status) {
            return { status: true, data: resp.data.data.crm_deliverer ?? {} }
        }
        else {
            return BaseApiServiceV4.transformErrorResponse(resp);
        }
    }

    const clearEditingConfiguration = (resetType = true) => {
        editingCrmConfiguration.value = getDefaultCrmDeliverer();
        if (resetType)
            editingDeliveryType.value = DeliveryType.Crm;
    }

    const loadDefaultFields = async () => {
        await nextTick();
        const targetConfiguration = availableCrmConfigurations.value.find(config => config.id === editingCrmConfiguration.value.crm_type);
        if (targetConfiguration) {
            Object.assign(editingCrmConfiguration.value.payload, {
                system_fields: targetConfiguration.system_fields ?? [],
                additional_fields: targetConfiguration.additional_fields ?? [],
            });
        }
    }

    const getCrmImportOptions = async (): Promise<StatusResponse> => {
        const currentCampaignReference = campaignStore.editingCampaign.reference;
        const resp = await services.apiServiceV4.getCrmImportOptions(currentCampaignReference).catch(e => e);
        if (resp.data?.data?.status) {
            crmImportOptions.value = resp.data.data.crm_deliverers;
            return { status: true }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const updateFieldValues = (keyValues: GenericObject, fieldType: string): void => {
        if (fieldType in editingCrmConfiguration.value.payload) {
            if (!Array.isArray(editingCrmConfiguration.value.payload[fieldType as keyof CrmDelivererPayload])) return;
            Object.entries(keyValues).forEach(([key, value]) => {
                const targetArray = editingCrmConfiguration.value.payload[fieldType as keyof CrmDelivererPayload];
                if (Array.isArray(targetArray)) {
                    const targetField = targetArray.find(field => field.key === key);
                    if (targetField) {
                        targetField.value = value;
                    }
                }
            });
        }
    }

    const mergeInteractables = (fieldArrayOne: CrmInteractable[], fieldArrayTwo: CrmInteractable[]) => {
        const outputArray = [...fieldArrayOne];
        fieldArrayTwo.forEach(field => {
            const targetIndex = outputArray.findIndex(targetField => targetField.method === field.method);
            if (targetIndex !== -1) outputArray[targetIndex] = field;
            else outputArray.push(field);
        });

        return outputArray;
    }

    /**
     * Allow disabling additional_fields for standard webform
     */
    const toggleAdditionalFields = (useFields: boolean, doNotCache = false) => {
        if (editingCrmConfiguration.value.crm_type !== CrmType.StandardWebForm) return;
        if (useFields) {
            if (!additionalFieldsCache.value) {
                const targetConfiguration = availableCrmConfigurations.value.find(config => config.id === editingCrmConfiguration.value.crm_type);
                additionalFieldsCache.value = targetConfiguration?.additional_fields ?? [];
            }
            editingCrmConfiguration.value.payload.additional_fields = additionalFieldsCache.value;
            additionalFieldsCache.value = null;
        }
        else {
            if (!doNotCache)
                additionalFieldsCache.value = [...editingCrmConfiguration.value.payload.additional_fields];

            editingCrmConfiguration.value.payload.additional_fields = [];
        }
    }

    /**
     * Check if the current CRM Template has active deliverers in any Campaign
     */
    const templateHasActiveCampaigns = (templateId?: number): boolean => {
        const id = templateId ?? editingCrmConfiguration.value.id;
        return !!(companyCrmTemplates.value.find(template => id === template.id)?.campaigns?.length);
    }

    /**
     * Get the Campaign names currently linked to the target CRM Template
     */
    const getTemplateCampaignNames = (templateId?: number): string[] => {
        const id = templateId ?? editingCrmConfiguration.value.id;
        if (typeof id !== 'number')
            return [];
        const references = companyCrmTemplates.value.find(template => template.id === id)?.campaigns ?? [];
        return campaignStore.scopedCampaignList.reduce((output, campaign) => {
            return references.includes(campaign.reference)
                ? [...output, `- ${campaign.name}`]
                : output;
        }, [] as string[]);
    }

    /**
     * Load a CRM template's values to current editing context
     */
    const loadTemplateValues = (templateId: number): void => {
        const clone = convertTemplateToDeliverer(templateId);
        const baseConfig: CrmDeliverer = editingCrmConfiguration.value as CrmDeliverer
            ?? getDefaultCrmDeliverer();

        Object.assign(baseConfig, clone);
        editingCrmConfiguration.value = baseConfig;
    }

    /**
     * Transform a CRM Template payload to a Deliverer payload
     */
    const convertTemplateToDeliverer = (templateId?: number, newTemplate: boolean = false) => {
        const id = templateId ?? editingCrmConfiguration.value.id;
        const targetTemplate = newTemplate
            ? companyCrmTemplates.value[companyCrmTemplates.value.length - 1]
            : companyCrmTemplates.value.find(template => template.id === id);

        if (!targetTemplate) return null;

        const clone = JSON.parse(JSON.stringify(targetTemplate));
        clone.template_id = targetTemplate.id;
        clone.active = true;
        clone.id = getTempId();

        return clone;
    }

    const getTempId = (): string => {
        return `temp-${Date.now()}`;
    }

    /**
     * Update a CRM Template. If syncCampaigns is true, the [campaigns] reference array will be used to
     * attach/detach Deliverers to this Company's campaigns
     */
    const saveCompanyCrmTemplate = async (syncCampaigns: boolean = false): Promise<DataResponse> => {
        const payload = JSON.parse(JSON.stringify(editingCrmConfiguration.value));
        if (syncCampaigns && 'campaigns' in editingCrmConfiguration.value) {
            payload.sync_campaigns = true;
            payload.campaigns = makeCampaignSyncPayload(editingCrmConfiguration.value.campaigns);
        }
        if (/^temp/i.test(payload.id))
            payload.id = null;

        const resp = await services.apiServiceV4.saveCompanyCrmTemplate(payload as CrmTemplate).catch((e: AxiosResponse) => e);
        if (resp.data?.data?.status) {
            companyCrmTemplates.value = resp.data.data.crm_templates;
            const data = {
                syncSelf: syncCampaigns
                    ? !!payload.campaigns[campaignStore.editingCampaign.reference]
                    : null,
            }

            return { status: true, data }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    /**
     * Delete a Template along with every Delivery using it
     */
    const deleteCompanyCrmTemplate = async (templateId: number): Promise<StatusResponse> => {
        const resp = await services.apiServiceV4.deleteCompanyCrmTemplate(templateId).catch((e: AxiosResponse) => e);
        if (resp.data?.data?.status) {
            companyCrmTemplates.value = resp.data.data.crm_templates;
            return { status: true }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    /**
     * Compile the template/campaign sync list from scoped campaigns (e.g. pagination) to ensure we don't
     * desync campaign deliveries outside the current scope
     */
    const makeCampaignSyncPayload = (templateCampaigns: string[]): GenericObject => {
        return campaignStore.scopedCampaignList.reduce((output, campaign) => {
            output[campaign.reference] = templateCampaigns.includes(campaign.reference);
            return output;
        }, {} as GenericObject);
    }

    const removeCampaignFromTemplate = (templateId: number): void => {
        const template = companyCrmTemplates.value.find(template => template.id === templateId);
        if (template) template.campaigns = template.campaigns.filter(reference => reference !== campaignStore.editingCampaign.reference);
    }

    const getCampaignReferences = (templateId?: number) => {
        const id = templateId ?? editingCrmConfiguration.value.id;
        const targetTemplate = companyCrmTemplates.value.find(template => template.id === id);

        return targetTemplate?.campaigns ?? [];
    }

    return {
        availableCrmConfigurations,
        initialized,
        crmConfigurationOptions,
        editingCrmConfiguration,
        crmImportOptions,
        usesCustomHeaders,
        usesJsonFields,
        editingCrmType,
        companyCrmTemplates,
        editingDeliveryType,

        initialize,
        currentInteractables,
        getCrmDisplayNameById,
        getCrmInteractableComponentMap,
        executeInteractable,
        clearEditingConfiguration,
        loadDefaultFields,
        getCrmImportOptions,
        usesCustomFields,
        updateFieldValues,
        toggleAdditionalFields,
        templateHasActiveCampaigns,
        getTemplateCampaignNames,
        loadTemplateValues,
        saveCompanyCrmTemplate,
        getCampaignReferences,
        convertTemplateToDeliverer,
        removeCampaignFromTemplate,
        deleteCompanyCrmTemplate,
    }
});