<template>
    <Module class="mt-4 mx-4 pb-4 relative min-h-[24rem]">
        <template v-slot:title>
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <slot name="title">
                      <svg class="w-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <h2 class="text-lg font-medium">{{ title }}</h2>
                    </slot>
                </div>
                <div class="flex items-center">
                    <div class="flex items-center cursor-pointer" @click="showTermsAndConditions">
                        <svg class="w-5 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19 8H5C3.34 8 2 9.34 2 11V17H6V21H18V17H22V11C22 9.34 20.66 8 19 8ZM16 19H8V14H16V19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM18 3H6V7H18V3Z"
                                fill="#14B1E7"/>
                        </svg>
                        <p class="text-right mr-5 pb-0 text-cyan-500 hidden md:block">View Terms and Conditions</p>
                    </div>
                    <div v-if="downloading">
                        <loading-spinner></loading-spinner>
                    </div>
                    <div class="flex items-center cursor-pointer" @click="downloadCsv">
                        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1666 15.8333L19.1666 17.5L3.83329 17.5L3.83329 15.8333L19.1666 15.8333ZM12.4583 10.8333L15.3333 10.8333L11.5 14.1667L7.66663 10.8333L10.5416 10.8333L10.5416 2.5L12.4583 2.5L12.4583 10.8333Z" fill="#20A5DC"/>
                        </svg>
                        <p class="text-right text-cyan-500 pb-0 block">Download</p>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:default>
            <div class="py-5 px-3 md:px-6 gap-y-3 flex flex-col justify-center relative min-h-[12rem]">
                <LoadingSpinner v-if="loading || saving || !storesInitialized" :small="true" />
                <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3 mb-6 items-end">
                    <div>
                        <p class="block mb-1 text-sm font-medium">Date Range</p>
                        <DatePicker
                            v-model="productStore.searchOptions.date_range"
                            range
                            input-class-name="h-11 rounded-md"
                            model-type="timestamp"
                            :enable-time-picker="false"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="productStore.searchOptions.name_email"
                            label="Name or Email"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="productStore.searchOptions.product_assignment_id"
                            label="Lead ID"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="productStore.searchOptions.state_zip_code"
                            label="State or Zipcode"
                        />
                    </div>
                    <div>
                        <CustomInput
                            v-model="productStore.searchOptions.invoice_id"
                            label="Invoice No."
                        />
                    </div>
                    <div>
                        <CustomSelect
                            v-model="productStore.searchOptions.campaign_reference"
                            :options="campaignOptions"
                            label="Campaign"
                        />
                    </div>
                    <div>
                        <CustomSelect
                            v-model="productStore.searchOptions.status"
                            label="Status"
                            :options="productStatusOptions"
                        />
                    </div>
                    <div v-if="productStore.showOverBudget">
                        <CustomSelect
                            v-model="productStore.searchOptions.over_budget"
                            label="Qualified Over Budget"
                            :options="overBudgetOptions"
                        />
                    </div>
                    <slot name="tableFilters">

                    </slot>
                    <div class="flex gap-2">
                        <SolidButton @click="submitSearch" classes="w-full h-11 font-semibold">
                            Search
                        </SolidButton>
                        <OutlineButton @click="clearSearch" color="gray" classes="w-full h-11 font-semibold">
                            Reset
                        </OutlineButton>
                    </div>
                </div>
                <div class="mt-8 min-h-[4rem]">
                    <div v-if="!(productStore.scopedProductAssignments.data.length > 0)" class="mt-8 text-center">
                        <p>No Leads were found.</p>
                    </div>
                    <div v-else>
                        <Pagination
                            :limit="productStore.scopedProductAssignments.limit"
                            :total="productStore.scopedProductAssignments.total"
                            :offset="productStore.scopedProductAssignments.offset"
                            @change-page="handlePageChange"
                        >
                            <div class="hidden md:sticky md:top-20 md:grid  text-xs text-gray-700 uppercase bg-cyan-50 text-center font-bold py-3 items-center border-b border-gray-200" :class="[productStore.showOverBudget ? 'md:grid-cols-8' : 'md:grid-cols-7']">
                                  <div>ID</div>
                              <slot name="tableHeaders">
                                  <div>Status</div>
                                  <div>Date Sent / Source</div>
                                  <div>Contact</div>
                                  <div>Address</div>
                                  <div>Cost</div>
                                  <div v-if="productStore.showOverBudget">Qualified Over Budget</div>
                                  <div>Reject</div>
                                </slot>
                            </div>
                            <div v-for="product in productStore.scopedProductAssignments.data"
                                 :key="product.id"
                                 class="border-b odd:bg-gray-50 text-gray-600 text-sm items-start md:items-center grid grid-cols-2 gap-4 md:gap-0 px-4 py-6 md:py-4 md:px-0" :class="[productStore.showOverBudget ? 'md:grid-cols-8' : 'md:grid-cols-7']"
                            >
                                <div>
                                  <p class="font-bold text-sm md:hidden text-gray-900">ID</p>
                                  <p @click="showProductDetail(product.id)" class="text-cyan-500 cursor-pointer md:text-center">
                                    {{ product.id }}
                                  </p>
                                </div>
                              <slot name="tableRow" :product="product">
                                <div>
                                  <p class="font-bold text-sm md:hidden text-gray-900">Status</p>
                                  <p class="md:text-center">{{ useProperCase(product.status) }}</p>
                                </div>
                                <div class="flex flex-col gap-1 md:text-center">
                                  <p class="font-bold text-sm md:hidden text-gray-900">Date Sent / Source</p>
                                  <p>{{ useFormatTimestamp(product.date_sent, {
                                      locale: 'en-US',
                                      date: true,
                                      time: true,
                                  }) }}</p>
                                  <p>{{product.source}}</p>
                                  <Badge v-if="product.single_product_sale && companyStore.missedLeadsEnabled" text="Missed Lead" size="xs" />
                                </div>
                                <div>
                                  <p class="font-bold text-sm md:hidden text-gray-900">Contact</p>
                                  <p class="md:text-center truncate" :title="product.name">{{ `${product.name}` }}</p>
                                  <p class="md:text-center truncate" :title="product.email">{{ product.email }}</p>
                                  <p class="md:text-center truncate" :title="product.phone">{{ product.phone }}</p>
                                </div>
                                <div>
                                  <p class="font-bold text-sm md:hidden text-gray-900">Address</p>
                                  <p class="md:text-center">{{ product.address }}</p>
                                </div>
                                <div>
                                  <p class="font-bold text-sm md:hidden text-gray-900">Cost</p>
                                  <p class="md:text-center">${{ product.cost }}</p>
                                </div>
                                <div class="col-span-2 md:col-span-1" v-if="productStore.showOverBudget">
                                  <p class="font-bold text-sm md:hidden text-gray-900">Qualified Over Budget</p>
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                       class="flex flex-row h-6 w-full justify-center text-cyan-500" fill="none" viewBox="0 0 12 24" stroke="currentColor"
                                       :class="[product.exclude_budget ? 'text-cyan-600' : 'text-transparent']"
                                       :stroke-width="[product.exclude_budget ?  '3' : '1.5']">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                  </svg>
                                </div>
                                <div class="col-span-2 md:col-span-1 md:flex md:justify-center">
                                  <div v-if="canRejectProduct(product)" class="flex flex-col items-center">
                                    <p class="text-sm md:justify-center text-red-500 cursor-pointer"
                                       @click="showLeadRejection(product)">
                                      Reject
                                    </p>
                                    <p class="text-gray-700 text-sm pb-0">Time remaining:</p>
                                    <p class="text-gray-900 text-sm font-bold text-center">{{useRelativeTimeRemaining(product.rejection_timer/1000)}}</p>
                                  </div>
                                  <div v-else-if="product.rejected" class="flex flex-col items-center">
                                    <p class="font-bold">Lead was rejected</p>
                                    <p class="text-green-500 cursor-pointer p-2 inline-block" @click="unrejectProduct(product)">
                                      Unreject
                                    </p>
                                  </div>
                                  <div v-else-if="product.single_product_sale" class="flex flex-col items-center">
                                    <p class="text-green-600 p-2 inline-block text-center">
                                      This lead cannot be rejected
                                    </p>
                                  </div>
                                </div>
                              </slot>
                            </div>
                            <template v-slot:summary>
                                <p class="text-gray-800 text-sm" v-if="productStore.scopedProductAssignments.data.length > 0 && pageCost > 0 && productStore.scopedProductAssignments.total_spend > 0">
                                    Total: <span class="text-green-500">
                                    ${{ pageCost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                    (${{ productStore.scopedProductAssignments.total_spend.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} total)
                                </span>
                                </p>
                            </template>
                        </Pagination>
                    </div>
                </div>
            </div>
            <!--    Lead Details modal  -->
            <slot
                name="product-details-modal"
                v-if="showProductDetailsModal"
                :lead="productStore.getProductById(productDetailId)"
                :handleModalClose="() => toggleDetailModal(false)"
            >
              <LeadDetailsModal
                  :lead="productStore.getProductById(productDetailId)"
                  @hideLeadDetailsModal="toggleDetailModal(false)"
                  :small="true"
              />
            </slot>
            <!--    Lead Rejection modal    -->
            <ProductRejectionModal
                v-if="productStore.productToReject"
                :product="productStore.productToReject"
                @close:modal="toggleRejectionModal(false)"
                :small="true"
            />
            <Modal
                v-if="showContractModal"
                @clicked:confirm="hideContractModal"
                confirm-label="Ok"
                :show-cancel-button="false"
            >
                <template v-slot:header>
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                        </svg>
                        <p class="ml-4 text-lg">Terms & Conditions</p>
                    </div>
                </template>
                <template v-slot:body>
                    <div class="overflow-auto h-full p-4 md:px-5 md:py-3 border-slate-300 border bg-slate-50">
                        <p v-html="companyContract" />
                    </div>
                </template>
            </Modal>
        </template>
    </Module>
</template>

<script setup lang="ts">
import { computed, ComputedRef, Ref, ref } from "vue";
import CustomInput from '@/components/inputs/CustomInput.vue';
import SolidButton from "@/components/inputs/SolidButton.vue";
import Module from "@/components/Module.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useFormatTimestamp } from "@/composables/useFormatTimestamp.js";
import { useProperCase } from "@/composables/useProperCase.js";
import { useRelativeTimeRemaining } from "@/composables/useRelativeTimeRemaining.js";
import LeadDetailsModal from "@/components/leads/Modals/LeadDetailsModal.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import Modal from "@/components/Modal.vue";
import { useCompanyStore } from "@/stores/company.js";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Badge from "@/components/Badge.vue";
import { useFutureCampaignStore } from "@/stores/v4/future-campaigns";
import { ProductAssignment, useProductAssignmentStore } from "@/stores/v4/products";
import { useAlertStore } from "@/stores/v4/alerts";
import { useProductConfigurationStore } from "@/stores/v4/product-configuration";
import ProductRejectionModal from "@/components/v4/products/modals/ProductRejectionModal.vue";

interface Props {
    storesInitialized: boolean,
    title: string
}
withDefaults(defineProps<Props>(), {
    storesInitialized: false,
    title: 'Leads'
});

const campaignStore = useFutureCampaignStore();
const companyStore = useCompanyStore();
const productStore = useProductAssignmentStore();
const productConfigurationStore = useProductConfigurationStore();
const alertStore = useAlertStore();

const loading = ref(false);
const saving = ref(false);
const downloading = ref(false);

const showProductDetailsModal = ref(false);
const showProductRejectionModal = ref(false);
const showContractModal = ref(false);
const productDetailId: Ref<number|null> = ref(null);

const pageCost = computed(() => productStore.scopedProductAssignments.data.filter(product => product.status === 'purchased').map(lead => lead.cost).reduce((sum, cost) => sum + cost, 0));

const campaignOptions: ComputedRef<CustomSelectOption[]> = computed(() => {
    return [
        { label: 'All', value: '' },
        ...campaignStore.scopedCampaignList.map(campaign => ({ label: campaign.name, value: campaign.reference })),
    ];
});

const productStatusOptions = productConfigurationStore.productStatuses;

const overBudgetOptions = productConfigurationStore.overBudgetOptions;

const companyContract = ref("");

const handlePageChange = (payload: GenericObject) => {
    submitSearch(payload.page);
}

const submitSearch = async (page: number = 1) => {
    loading.value = true;
    productStore.searchOptions.page = page;
    const { status, message } = await productStore.search();
    if (!status) {
        alertStore.showError(message ?? `An error occurred fetching ${productStore.productScope}s.`);
    }
    loading.value = false;
}

const clearSearch = () => {
    productStore.clearSearchOptions();
    submitSearch();
}

const canRejectProduct = (product: ProductAssignment) => {
  return product.rejection_timer > 0 && !product.rejected && !product.single_product_sale && productConfigurationStore.canRejectCurrentProduct();
};

const showTermsAndConditions = async () => {
    loading.value = true;
    const { status, contract, message } = await companyStore.getCompanyContractForUser();
    if (status) {
        companyContract.value = contract;
        showContractModal.value = true;
    }
    else {
        alertStore.showError(message ?? "An error occurred fetching the contract.");
    }
    loading.value = false;
}

const hideContractModal = () => {
    showContractModal.value = false;
}

const unrejectProduct = async (product: ProductAssignment) => {
    saving.value = true;
    const { status, message } = await productStore.unrejectProduct(product.id);
    if (!status) {
        alertStore.showError(message ?? `An error occurred while attempting to unreject this ${productStore.productScope}.`);
    }
    else {
        alertStore.showInfo(`The ${productConfigurationStore.productScope} was successfully unrejected`);
    }
    saving.value = false;
}

const downloadCsv = async () => {
    downloading.value = true;
    const { status, message } = await productStore.downloadCsv();

    if (!status) {
        alertStore.showError(message ?? `An error occurred fetching ${productStore.productScope}s.`);
    }

    downloading.value = false;
}

const showProductDetail = (productId: number) => {
    if (productId > 0) {
        productDetailId.value = productId;
        toggleDetailModal(true);
    }
}

const showLeadRejection = (product: ProductAssignment) => {
    if (product) {
        productStore.setProductToReject(product)
    }
}

const toggleRejectionModal = (show: boolean = true) => {
  productStore.setProductToReject(null)
  toggleModal(showProductRejectionModal, show);
}
const toggleDetailModal = (show: boolean = true) => toggleModal(showProductDetailsModal, show);

const toggleModal = (modalToggle: Ref<boolean>, show?: boolean) => {
    modalToggle.value = show === undefined
        ? !modalToggle.value
        : show;
}

</script>