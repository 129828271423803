import {ref} from "vue";
import {defineStore} from "pinia";

export const usePasswordReset = defineStore('password-reset', () => {
    const token = ref(null)
    const email = ref(null)

    const set = (payload) => {
        token.value = payload.token
        email.value = payload.email
    }

    const reset = () => {
        token.value = null
        email.value = null
    }

    return {
        reset,
        set,
        token,
        email
    }
}, {persit: true});