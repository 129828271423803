import { defineStore } from "pinia";
import { markRaw, Raw, ref, Ref, toRaw } from "vue";
import leadsImage from "@/assets/navigation/leads.svg";
import appointmentsImage from "@/assets/navigation/appointments.svg";
import directLeadsImage from "@/assets/navigation/direct-leads.svg";
import profitabilityImage from "@/assets/navigation/profitability.svg";
import companyProfileImage from "@/assets/navigation/company-profile.svg";
import billingImage from "@/assets/navigation/billing-payments.svg";
import dashboardImage from "@/assets/navigation/dashboard.svg";
import biddingImage from "@/assets/navigation/bidding.svg";
import leadsCampaigns from "@/assets/navigation/leads-campaigns.svg";
import CustomerReviewImage from "@/assets/navigation/customer-reviews.svg";
import { ModuleComponentNavigation } from "@/stores/v4/campaign-modules";
import { useCompanyStore } from "@/stores/company";

type NavLinkChild = {
    text: string,
    id: string,
    enabled: boolean,
};

type NavLink = {
    id: string,
    name: string,
    enabled: boolean,
    children?: NavLinkChild[],
    image?: Raw<object>,
    global?: boolean,
    text?: string,
}

export const useNavigationStore = defineStore('navigation', () => {
    const company = useCompanyStore();

    const selectedServiceSupportsAppointments = () => {
        return selectedServiceSupportProduct('Appointment');
    }

    const selectedServiceSupportProduct = (product: string): boolean => {
        const targetService = company.services[company.selectedIndustry]?.find(service => service.slug === company.selectedService) ?? null;

        if (!targetService || targetService.products === null) {
            return false;
        }

        // Transform products from array or object
        const products = Array.isArray(targetService.products)
            ? targetService.products
            : typeof targetService.products === 'object'
                ? Object.values(targetService.products)
                : []

        return products.includes(product);
    }

    const links: Ref<NavLink[]> = ref([

        {
            id: 'leads',
            name: 'Leads',
            enabled: true,
            image: markRaw(leadsImage),
            global: false,
        },
        {
            id: 'leads-campaigns',
            name: 'Campaigns',
            enabled: true,
            image: markRaw(leadsCampaigns),
            global: false,
        },
        {
            id: 'leads-bidding',
            name: 'Bidding',
            enabled: true,
            image: markRaw(biddingImage),
            global: false,
        },
        {
            id: 'leads-missed',
            name: 'Missed Leads',
            enabled: company.missedLeadsEnabled,
            image: markRaw(dashboardImage),
            global: false,
        },
        {
            id: 'dashboard',
            name: 'Analytics',
            enabled: company.status !== 'suspended',
            image: markRaw(dashboardImage),
            global: false,
        },
        {
            id: 'appointments',
            name: 'Appointments',
            enabled: company.appointmentsActive && selectedServiceSupportsAppointments(),
            children: [
                {text: 'Overview', id: 'appointments', enabled: true},
                {text: 'Campaigns', id: 'appointments-campaigns', enabled: false},
                {text: 'Bidding', id: 'appointments-bidding', enabled: false}
            ],
            image: markRaw(appointmentsImage),
            global: false,
        },
        {
            id: 'direct-leads',
            name: 'Direct Leads',
            enabled: company.futureCampaignsActive && selectedServiceSupportProduct('Direct Leads'),
            children: [
                {text: 'Overview', id: 'direct-leads', enabled: true},
                {text: 'Campaigns', id: 'direct-leads-campaigns', enabled: true},
                {text: 'Bidding', id: 'direct-leads-bidding', enabled: true},
            ],
            image: markRaw(directLeadsImage),
            global: false,
        },
        {
            id: 'company-profitability',
            name: "Profitability",
            enabled: true,
            image: markRaw(profitabilityImage),
            global: true,
        },
        {
            id: 'company-profile',
            name: 'Company Profile',
            enabled: true,
            children: [
                {text: 'Overview', id: 'company-profile', enabled: true},
                {text: 'Logo & Description', id: 'logo-and-description', enabled: true},
                // {text: 'Prescreened', id: 'prescreened', enabled: true}, // TODO: Discuss with Andy if we're keeping prescreened.
                {text: 'Upload Media', id: 'upload-media', enabled: true},
                {text: 'Licenses', id: 'licenses-certifications', enabled: true},
                // {text: 'Certifications', id: 'certifications', enabled: true},
                // {text: 'Services Offered', id: 'services-offered', enabled: true},
                {text: 'Addresses', id: 'addresses', enabled: true},
                {text: 'Contacts', id: 'contacts', enabled: true},
                {text: 'Notifications', id: 'notification-settings', enabled: true},
                // {text: 'Ranking Questions', id: 'ranking-questions', enabled: true},
            ],
            image: markRaw(companyProfileImage),
            global: true,
        },
        {
            id: 'customer-reviews',
            name: 'Customer Reviews',
            enabled: false // TODO: Discuss w/ Andy
        },
        {
            id: 'billing-and-payments',
            name: 'Billing & Payments',
            enabled: true,
            image: markRaw(billingImage),
            global: true,
            children: [
                {text: 'Overview', id: 'billing-and-payments', enabled: true},
                {text: 'Payment Methods', id: 'payment-methods', enabled: true},
            ]
        },
        {
            id: 'customer-reviews',
            name: 'Customer Reviews',
            enabled: company.reviewsEnabled,
            image: markRaw(CustomerReviewImage),
            global: true,
            children: [
                {text: 'Overview', id: 'customer-reviews', enabled: false},
            ]
        }
    ]);

    const updateNavigationItem = (moduleComponent: ModuleComponentNavigation): boolean => {
        const { id, parentRoute, child } = moduleComponent;

        const targetArray = child
            ? links.value.find(navItem => navItem.id === parentRoute)?.children
            : links.value;
        if (!targetArray) {
            console.error(`Could not add route: ${id}.`);
            return false;
        }
        const existingItemIndex: number = targetArray.findIndex(item => item.id === id);
        if (existingItemIndex > -1) {
            if (child) {
                const existingItem = links.value.find(item => item.id === parentRoute)?.children?.[existingItemIndex] ?? null;
                if (existingItem) {
                    Object.assign(existingItem, {
                        text: moduleComponent.name ?? existingItem.text,
                        id: moduleComponent.id ?? existingItem.id,
                        enabled: moduleComponent.enabled,
                    });
                }
            } else {
                const existingItem = links.value.find(item => item.id === id) ?? null;
                if (existingItem) {
                    Object.assign(targetArray[existingItemIndex], {
                        name: moduleComponent.name ?? existingItem.name,
                        enabled: moduleComponent.enabled,
                        image: toRaw(moduleComponent.image) ?? existingItem.image,
                        global: moduleComponent.global ?? existingItem.global,
                    });
                }
            }
        } else {
            if (child) {
                const newItem: NavLinkChild = { text: moduleComponent.name, id, enabled: moduleComponent.enabled };
                links.value.find(item => item.id === parentRoute)?.children?.push(newItem);
            } else {
                const newItem: NavLink = {
                    id,
                    name: moduleComponent.name,
                    children: [],
                    image: toRaw(moduleComponent.image ?? ''),
                    global: moduleComponent.global ?? false,
                }
                links.value.push(newItem);
            }
        }

        return true
    }

    return {
        links,

        updateNavigationItem,
        selectedServiceSupportsAppointments,
    }
});