<template>
  <div>
    <!--    NEW DELIVERY METHOD BUTTONS    -->
    <div class="grid grid-cols-1 gap-y-12"
         :class="[saving ? 'pointer-events-none grayscale-[50%] opacity-50' : '']"
    >
      <div class="grid md:grid-cols-4 max-w-5xl items-center pt-4 gap-2">
        <OutlineButton @click="toggleContactDeliveryModal(true)">
          New Contact Delivery
        </OutlineButton>
        <OutlineButton @click="toggleCrmDeliveryModal(true)">
          New CRM Delivery
        </OutlineButton>
        <OutlineButton @click="toggleCrmImportModal(true)">
          Import CRM Delivery
        </OutlineButton>
        <OutlineButton @click="createTemplate" color="orange">
          New CRM Template
        </OutlineButton>
      </div>
    </div>

    <LoadingSpinner v-if="loading" :small="true"/>
    <div v-else>
      <!--    CONTACT TABLE    -->
      <div class="py-4">
        <GridTable
            :tableSetup="contactTableSetup"
            :tableData="slideInputs.contact_deliveries"
            @action:edit="editContact"
            @action:delete="(id) => deleteDelivery(DeliveryType.Contact, id)"
        />
      </div>

      <!--	CRM TABLE	-->
      <div class="py-4 border-t border-slate-300">
        <GridTable
            :tableSetup="campaignCrmTableSetup"
            :tableData="slideInputs.crm_deliveries"
            @action:edit="editCrm"
            @action:delete="(id) => deleteDelivery(DeliveryType.Crm, id)"
        />
      </div>

        <!-- CRM TEMPLATE TABLE	-->
        <div class="py-4 border-t border-slate-300">
            <GridTable
                :tableSetup="crmTemplateTableSetup"
                :tableData="deliveryStore.companyCrmTemplates"
                @action:edit="editTemplate"
                @action:delete="(id) => deleteTemplate(id)"
            />
        </div>
    </div>

    <!--    Add Contact Delivery Modal  -->
    <ContactDeliveryModal
        v-if="showContactDeliveryModal"
        data-key="contact_deliveries"
        :future-campaigns="true"
        @hideContactDeliveryModal="toggleContactDeliveryModal(false)"
        @update:deliveries="handleContactModalUpdate"
    />

    <!--    Add CRM Delivery Modal      -->
    <CrmConfigurationModal
        v-if="showCrmDeliveryModal"
        data-key="crm_deliveries"
        :editing-id="editingCrmId"
        @close:modal="closeCrmModal"
        @update:delivery="handleCrmModalUpdate"
        @update:template-delivery="handleCrmTemplateUpdate"

    />

    <!--    Add contact modal    -->
    <CreateUpdateCompanyUserModal
        :deliveries-slide="true"
        @hideEditUserModal="closeEditContactModal"
        v-if="showEditContactModal"
    />

    <ImportCrmConfigurationModal
        v-if="showCrmImportModal"
        @close:modal="toggleCrmImportModal(false)"
        @import:crm="handleImportCrmConfiguration"
    />

    <!--    Delete confirm modal    -->
    <Modal
        v-if="showDeleteConfirmModal"
        @clicked:confirm="confirmDelete"
        @clicked:cancel="cancelConfirm"
        confirm-label="Delete"
        :small="true"
    >
      <template v-slot:header>
        <p>Delete Delivery Method</p>
      </template>
      <template v-slot:body>
        <p class="whitespace-pre-wrap">
          {{ getDeleteMessage() }}
        </p>
      </template>
    </Modal>
  </div>

</template>

<script setup lang="ts">

import OutlineButton from "@/components/inputs/OutlineButton.vue";
import CreateUpdateCompanyUserModal from "@/components/company/modals/CreateUpdateCompanyUserModal.vue";
import ContactDeliveryModal from "@/components/campaigns/EditorSlides/Modals/ContactDeliveryModal.vue";
import Modal from "@/components/Modal.vue";
import {onMounted, reactive, Ref, ref, watch} from "vue";
import {DeliveryCollection, DeliveryType, useDeliveryStore} from "@/stores/v4/delivery";
import {useAlertStore} from "@/stores/v4/alerts";
import {SlideInputs} from "@/stores/v4/wizard";
import {useCompanyUsersStore} from "@/stores/company-users";
import GridTable from "@/components/v4/wizard/generics/GridTable.vue";
import {ReactiveVariable} from "vue/macros";
import {ValidatorResults} from "@/services/v4/WizardValidationService";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import CrmConfigurationModal from "@/components/v4/campaigns/modals/CrmConfigurationModal.vue";
import ImportCrmConfigurationModal from "@/components/v4/campaigns/modals/ImportCrmConfigurationModal.vue";
import {useReferenceDataStore} from "@/stores/reference-data";

interface Props {
  initialSlideData: SlideInputs,
}

const props = defineProps<Props>();

const emit = defineEmits(['update:slideInput']);

const slideInputs: ReactiveVariable<DeliveryCollection> = reactive({
  contact_deliveries: [],
  crm_deliveries: [],
  schedules: [],
});

watch(() => slideInputs.contact_deliveries, (newValue) => handleInputUpdate(newValue, 'contact_deliveries'), {deep: true});
watch(() => slideInputs.crm_deliveries, (newValue) => handleInputUpdate(newValue, 'crm_deliveries'), {deep: true});

const alertStore = useAlertStore();
const deliveryStore = useDeliveryStore();
const companyUserStore = useCompanyUsersStore();
const referenceStore = useReferenceDataStore();

const loading: Ref<boolean> = ref(false);
const saving: Ref<boolean> = ref(false);

const showContactDeliveryModal: Ref<boolean> = ref(false);
const showCrmDeliveryModal: Ref<boolean> = ref(false);
const showDeleteConfirmModal: Ref<boolean> = ref(false);
const showEditContactModal: Ref<boolean> = ref(false);
const showCrmImportModal: Ref<boolean> = ref(false);

const deleting: Ref<{ id: number | null, type: DeliveryType | null, name: string }> = ref({
  type: null,
  id: null,
  name: '',
});

const editingCrmId: Ref<number | null> = ref(null);

const initialize = async () => {
  loading.value = true;

  Object.assign(slideInputs, props.initialSlideData);

  const [companyUserResult, deliveryResult] = await Promise.all([
    companyUserStore.initialize(),
    deliveryStore.initialize(),
    referenceStore.getTimezones()
  ]);

  const errorMessage = companyUserResult.message || deliveryResult?.message || null;
  if (errorMessage) alertStore.showError(errorMessage);

  slideInputs.crm_deliveries = slideInputs.crm_deliveries.filter(crm => crm.display_name);
  slideInputs.contact_deliveries = slideInputs.contact_deliveries.filter(contact => contact.name);

  loading.value = false;
}
onMounted(() => initialize());

const deleteDelivery = (type: DeliveryType, deliveryId: number) => {
  deleting.value = {
    type: type,
    id: deliveryId,
    name: getDeliveryName(type, deliveryId),
  }
  toggleShowDeleteConfirmModal(true);
}

const confirmDelete = async () => {
    if (deleting.value.type === DeliveryType.Crm) {
        const targetIndex = slideInputs.crm_deliveries.findIndex(crm => crm.id === deleting.value.id);
        if (targetIndex !== -1) {
            const usesTemplateId = slideInputs.crm_deliveries[targetIndex].template_id;
            slideInputs.crm_deliveries.splice(targetIndex, 1);
            if (usesTemplateId) {
                deliveryStore.removeCampaignFromTemplate(usesTemplateId);
            }
        }
    }
    else if (deleting.value.type === DeliveryType.Contact) {
        const targetIndex = slideInputs.contact_deliveries.findIndex(contact => contact.contact_id === deleting.value.id);
        if (targetIndex !== -1) slideInputs.contact_deliveries.splice(targetIndex, 1);
    }
    else if (deleting.value.type === DeliveryType.Template) {
        saving.value = true;
        const { status, message } = await deliveryStore.deleteCompanyCrmTemplate(deleting.value.id as number);
        if (!status)
            alertStore.showError(message ?? "An error occurred deleting the Template.");
        else
            alertStore.showInfo("The template was successfully removed");

        // Remove any Deliverers from the UI that were using this template
        handleCrmTemplateUpdate(false, deleting.value.id as number);
        saving.value = false;
    }

    cancelConfirm();
}

const deleteTemplate = (id: number) => {
    deleting.value = {
        type: DeliveryType.Template,
        id: id,
        name: getTemplateName(id),
    };

    toggleShowDeleteConfirmModal(true);
}

const getDeleteMessage = () => {
    if (deleting.value.type === DeliveryType.Crm) {
        const deliveryUsesTemplateId = slideInputs.crm_deliveries.find(delivery => delivery.id === deleting.value.id)?.template_id ?? null;
        return deliveryUsesTemplateId
            ? `Are you sure you wish to delete the CRM delivery method "${deleting.value.name}"?\n\nThe CRM Template will not be removed. It is used in ${Math.max(getTemplateCampaignCount(deliveryUsesTemplateId) - 1, 0)} other Campaign(s).`
            : `Are you sure you wish to delete the CRM Delivery method "${deleting.value.name}"?\n\nThis will completely remove this Campaign CRM delivery and its settings.`;
    }
    else if (deleting.value.type === DeliveryType.Contact)
        return `Are you sure you wish to delete the Contact delivery method for "${deleting.value.name}"?\nThis will remove them as a delivery option for this Campaign only - it will not delete the contact.`
    else if (deleting.value.type === DeliveryType.Template)
        return `Are you sure you wish to delete ${deleting.value.name}?
                \nThis will remove the template, and all Campaign deliveries linked to this template.
                \nThere are ${getTemplateCampaignCount(deleting.value.id as number)} Campaign(s) using this template.`;
}

const getTemplateName = (id: number): string => {
    return deliveryStore.companyCrmTemplates.find(template => template.id === id)?.display_name
        ?? 'this CRM Template';
}

const getTemplateCampaignCount = (id: number): number => {
    return deliveryStore.companyCrmTemplates.find(template => template.id === id)?.campaigns?.length ?? 0;
}

const cancelConfirm = () => {
  deleting.value = {
    type: null,
    id: null,
    name: '',
  }
  toggleShowDeleteConfirmModal(false);
}

const editCrm = (crmId: number) => {
    deliveryStore.editingDeliveryType = DeliveryType.Crm;
    const targetCrm = slideInputs.crm_deliveries.find(crm => crm.id === crmId);
    if (targetCrm) {
        deliveryStore.editingCrmConfiguration = targetCrm;
        editingCrmId.value = crmId;
        toggleCrmDeliveryModal(true);
    }
}

const createTemplate = () => {
    deliveryStore.editingDeliveryType = DeliveryType.Template;
    toggleCrmDeliveryModal(true);
}

const editTemplate = (templateId: number) => {
    deliveryStore.editingDeliveryType = DeliveryType.Template;
    const targetTemplate = deliveryStore.companyCrmTemplates.find(template => template.id === templateId);
    if (targetTemplate) {
        deliveryStore.editingCrmConfiguration = targetTemplate;
        editingCrmId.value = templateId;
        toggleCrmDeliveryModal(true);
    }
}

const closeCrmModal = () => {
  editingCrmId.value = null;
  toggleCrmDeliveryModal(false);
}

const editContact = (editContactId: number) => {
  companyUserStore.editCompanyUser(editContactId);
  toggleShowEditContactModal(true);
}

const closeEditContactModal = (user: any) => {
  updateContactDelivery(user);
  companyUserStore.clearEditing();
  toggleShowEditContactModal(false);
}

const updateContactDelivery = (user: any) => {
  const contact = slideInputs.contact_deliveries.find(contact => contact.contact_id === user.id);

  if (contact) {
    contact.email = user.email;
    contact.name = `${user.first_name} ${user.last_name}`;
    contact.cell_phone = user.cell_phone;
  }
}

const handleContactModalUpdate = (newValues: any) => {
  slideInputs.contact_deliveries.push(...newValues);
}

const handleCrmModalUpdate = (newValue: any) => {
  const targetCrmIndex = newValue.id
      ? slideInputs.crm_deliveries.findIndex(crm => crm.id === newValue.id)
      : -1;

  if (targetCrmIndex >= 0)
    slideInputs.crm_deliveries[targetCrmIndex] = {...newValue}
  else
    slideInputs.crm_deliveries.push({...newValue});
}

const handleCrmTemplateUpdate = (sync: boolean, payload: number|object) => {
    if (sync && typeof(payload) === 'object')
        handleCrmModalUpdate(payload);
    else if (typeof payload === 'number')
        slideInputs.crm_deliveries = slideInputs.crm_deliveries.filter(delivery => delivery.template_id !== payload);
}

const handleImportCrmConfiguration = (importId: number) => {
  const target = deliveryStore.crmImportOptions.find(crm => crm.id === importId);
  if (target) {
    const clone = JSON.parse(JSON.stringify(target));
    clone.id = `import-${Date.now()}`;
    slideInputs.crm_deliveries.push(clone);
  }
}

const handleInputUpdate = (newValue: any, inputKey: string) => {
  emit('update:slideInput', newValue, inputKey);
}

const toggleModal = (modalToggle: Ref<boolean>, show?: boolean) => {
  modalToggle.value = show === undefined
      ? !modalToggle.value
      : show;
}

const getDeliveryName = (type: DeliveryType, id: number): string => {
  return type === DeliveryType.Crm
      ? slideInputs.crm_deliveries.find(crm => crm.id === id)?.display_name ?? 'this CRM delivery'
      : slideInputs.contact_deliveries.find(contact => contact.contact_id === id)?.name ?? 'this contact delivery';
}

const customValidation = (): ValidatorResults => {
  const activeContacts: number = slideInputs.contact_deliveries.reduce((total: number, contact: GenericObject) => total + (contact.sms_active || contact.email_active) ? 1 : 0, 0);
  const activeCrm: number = slideInputs.crm_deliveries.reduce((total: number, crm: GenericObject) => total + crm.active ? 1 : 0, 0);
  const errors = []

  if (activeContacts + activeCrm === 0) {
    errors.push("At least one delivery method must be active.")
  }

  return errors.length === 0
      ? {valid: true}
      : {valid: false, errorBag: errors, message: errors.join('')}
}
defineExpose({customValidation});

const toggleContactDeliveryModal = (show?: boolean) => toggleModal(showContactDeliveryModal, show);
const toggleCrmDeliveryModal = (show?: boolean) => toggleModal(showCrmDeliveryModal, show);
const toggleShowDeleteConfirmModal = (show?: boolean) => toggleModal(showDeleteConfirmModal, show);
const toggleShowEditContactModal = (show?: boolean) => toggleModal(showEditContactModal, show);
const toggleCrmImportModal = (show?: boolean) => toggleModal(showCrmImportModal, show);

const contactTableSetup = {
  title: 'Company Contacts',
  subTitle: 'Decide who receives emails or texts for this campaign.',
  columns: [
    {label: 'Name', key: 'name'},
    {label: 'Email', key: 'email', checkbox: 'email', emitKey: 'email_active'},
    {label: 'Cell (SMS)', key: 'cell_phone', checkbox: 'cell_phone', emitKey: 'sms_active'},
    {label: 'Actions', key: '', editDelete: true, emitKey: 'contact_id'},
  ],
}

const campaignCrmTableSetup = {
    title: 'Campaign CRM Integrations',
    columns: [
        { label: 'Active', key: '', checkbox: true, emitKey: 'active' },
        { label: 'Name', key: 'display_name' },
        { label: 'CRM', key: 'crm_type_display' },
        { label: 'Template', key: 'template_id', defaultValue: '-', trueValue: 'Yes'  },
        { label: 'Actions', key: '', editDelete: true, emitKey: 'id' },
    ],
}

const crmTemplateTableSetup = {
    title: 'Company CRM Templates',
    columns: [
        {label: 'Name', key: 'display_name'},
        {label: 'CRM', key: 'crm_type_display'},
        {label: 'Actions', key: '', editDelete: true, emitKey: 'id'},
    ],
}

</script>
