<template>
    <Modal
        v-if="visible"
        @clicked:confirm="handleClose"
        confirm-label="Done"
        small
        :show-cancel-button="false"
    >
        <template v-slot:header>
            <div class="flex items-center">
                <h5 class="text-md">{{ modelHeading }}</h5>
            </div>
        </template>
        <template v-slot:body>
            <div class="flex flex-col items-center gap-5 justify-center px-6">
                <div v-if="paymentStatus === 'success'">
                    <CheckCircleIcon class="w-10 text-green-500" />
                </div>
                <div v-if="paymentStatus === 'failed'">
                    <ExclamationCircleIcon class="w-10 text-orange-400" />
                </div>
                <div v-if="paymentStatus === 'pending'">
                    <ClockIcon class="w-10 text-gray-500" />
                </div>
                <h1 class="text-xl text-center font-medium capitalize" >
                    {{ title ? title : paymentStatus }}
                </h1>
                <p v-if="paragraph" class="text-gray-500" >
                    {{ paragraph }}
                </p>
            </div>
        </template>
    </Modal>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import {ExclamationCircleIcon} from "@heroicons/vue/solid";
import {CheckCircleIcon} from "@heroicons/vue/solid";
import {ClockIcon} from "@heroicons/vue/solid";

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    paymentStatus: {
        type: String,
        required: true
    },
    modelHeading: {
        type: String,
        default: 'Purchase'
    },
    title: {
        type: String
    },
    paragraph: {
        type: String,
    },
})

const emits = defineEmits(["update:visible"]);

const handleClose = () => {
    emits('update:visible', false);
}

</script>