<template>
    <div>
        <Modal
            @clicked:confirm="closeModal"
            :show-cancel-button="false"
            confirm-label="Ok"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <h5 class="text-md">Lead Details</h5>
                </div>
            </template>
            <template v-slot:body>
                <div v-if="lead.rejected" class="px-4 pt-2 overflow-y-auto max-h-[16rem] mb-4 border-b border-slate-300">
                    <div class="flex items-center mb-2 text-slate-500">
                        Rejected Time
                    </div>
                    <div class="mb-4">
                        {{ (new Date(lead.rejected_time * 1000)).toLocaleString() }}
                    </div>

                    <div class="flex items-center mb-2 text-slate-500">
                        Rejection Reason
                    </div>
                    <div class="mb-4">
                        {{ lead.rejection_reason }}
                    </div>
                </div>
                <div class="mb-4 border-b border-slate-300">
                    <div class="grid xl:grid-cols-3 items-center gap-4 px-4 pb-6">
                        <div class="xl:col-span-1 mb-4">
                            <p>Date Delivered: {{ useFormatTimestamp(lead.date_sent) }}</p>
                        </div>
                        <div class="xl:col-span-2 mb-4" v-if="lead.single_product_sale && companyStore.missedLeadsEnabled">
                            <p class="font-medium">Purchased as missed lead</p>
                        </div>
                        <div>
                            <div class="flex items-center mb-1 text-slate-500">
                                <svg  class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                                <p class="ml-2">Name</p>
                            </div>
                            <p class="ml-2">{{ lead.name }}</p>
                        </div>
                        <div class="xl:col-span-2">
                            <div class="flex items-center mb-1 text-slate-500">
                                <svg  class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>
                                <p class="ml-2">Address</p>
                            </div>
                            <p class="ml-2">{{ lead.address }}</p>
                        </div>
                        <div>
                            <div class="flex items-center mb-1 text-slate-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                </svg>

                                <p class="ml-2">Phone</p>
                            </div>
                            <p class="ml-2">{{ lead.phone || '-' }}</p>
                        </div>
                        <div class="xl:col-span-2">
                            <div class="flex items-center mb-1 text-slate-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                                <p class="ml-2">Email</p>
                            </div>
                            <p class="ml-2">{{ lead.email || '-' }}</p>
                        </div>
                        <div>
                            <div class="flex items-center mb-1 text-slate-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                                </svg>
                                <p class="ml-2">Map</p>
                            </div>
                            <a target="_blank"
                               :href="googleMapLink"
                               class="ml-2 text-cyan-500"
                            >
                                Google Maps Link
                            </a>
                        </div>
                        <div>
                            <div class="flex items-center mb-1 text-slate-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                                </svg>
                                <p class="ml-2">Recording</p>
                            </div>
                            <p v-if="fetchingUrl" class="ml-2 text-slate-400">Loading...</p>
                            <a v-else-if="!fetchingUrl && !!watchdogUrl" target="_blank" :href="watchdogUrl" class="ml-2 text-cyan-500">View Recording</a>
                            <p v-else class="ml-2 text-slate-400">No Recording Available</p>
                        </div>
                    </div>
                </div>
                <div class="px-4 pt-2 overflow-y-auto max-h-[16rem]">
                    <div class="flex items-center mb-4 text-slate-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                        </svg>
                        <p class="ml-2">Service Details</p>
                    </div>
                    <div class="grid md:grid-cols-2 items-center justify-items-center gap-2 px-4 pb-6">
                        <div v-for="[key, value] in Object.entries(lead.data)">
                            {{ useProperCase(key) }}: {{ value }}
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import { useFormatTimestamp } from "@/composables/useFormatTimestamp.js";
import { computed, onMounted, ref } from "vue";
import { useProperCase } from "@/composables/useProperCase.js";
import {useCompanyStore} from "@/stores/company";
import { useServicesStore } from "@/stores/services";
import * as Sentry from "@sentry/browser";

const props = defineProps({
    lead: {
        type: Object,
        default: {},
    }
});

const watchdogUrl = ref(null);
const fetchingUrl = ref(true);

onMounted(async () => {
    await useServicesStore().apiService.getWatchdogPlaybackUrl(props.lead.id)
    .then(r => watchdogUrl.value = r.data.data.url)
    .catch(e => {
        console.log(e)

        Sentry.setTag('area', 'Watchdog')
    })
    .finally(() => fetchingUrl.value = false)
});

const companyStore = useCompanyStore()

const emit = defineEmits(['hideLeadDetailsModal']);

const googleMapLink = computed(() => {
    const addressQuery = encodeURIComponent(`${props.lead.address}, US`);
    return `https://www.google.com/maps/search/?api=1&query=${addressQuery}`;
});

const closeModal = () => {
    emit('hideLeadDetailsModal');
}

</script>
