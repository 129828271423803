import axios from "axios";
import { ApiResponse, ApiServiceV4Contract, BaseApiServiceV4 } from "@/services/api/v4/BaseApiServiceV4";
import { CrmDeliverer, CrmTemplate } from "@/stores/v4/delivery";
import { CampaignPausePayload, NewCampaignParameters } from "@/stores/v4/future-campaigns";
import { ProductAssignmentSearchOptions, RejectionPayload } from "@/stores/v4/products";

export class Api extends BaseApiServiceV4 implements ApiServiceV4Contract {
    constructor(baseUrl = null) {
        super();
        this.baseUrl = baseUrl;
    }

    get axios() {
        return axios.create({
            baseURL: `${this.baseUrl}/v4` ?? '',
            headers: {
                'X-CLIENT-BEARER': this.bearer ?? ''
            }
        });
    }

    getScopedRoute(suffixRoute?: string): string {
        suffixRoute = (suffixRoute ?? '').trim();
        suffixRoute = /^\//.test(suffixRoute)
            ? suffixRoute
            : `/${suffixRoute}`;

        return `/products/${this.productKey}/companies/${this.companyId}/${this.industryKey}/${this.serviceKey}${suffixRoute}`;
    }

    getModuleConfigurations(campaignType: number) {
        return this.axios.get(this.getScopedRoute('/campaigns/module-configurations'), {
            params: {
                type: campaignType,
            }
        });
    }

    getCrmConfigurations() {
        return this.axios.get(this.getScopedRoute('/campaigns/crm-configurations'));
    }

    saveCrmConfiguration(payload: CrmDeliverer) {
        return payload.id
            ? this.axios.patch(this.getScopedRoute('/campaigns/update-company-crm'), payload)
            : this.axios.post(this.getScopedRoute('/campaigns/create-company-crm'), payload);
    }

    newCampaign(campaignConfig: NewCampaignParameters) {
        return this.axios.get(this.getScopedRoute(`/campaigns/new-campaign-config`), {
            params: {
                customBudgetKey: campaignConfig.customBudgetType,
                zipCodeTargeted: campaignConfig.zipCodeTargeted ? 1 : 0,
            }
        })
    }

    getCampaignDetail(campaignReference: string) {
        return this.axios.get(this.getScopedRoute(`/campaigns/${campaignReference}`));
    }

    //TODO: typing for a campaign payload
    saveNewCampaign(payload: GenericObject) {
        return this.axios.post(this.getScopedRoute('/campaigns/new/save'), payload);
    }

    updateCampaign(payload: GenericObject, campaignReference: string) {
        return this.axios.post(this.getScopedRoute(`/campaigns/${campaignReference}/save`), payload);
    }

    getStateFloorPrices(stateLocationKey: string, campaignType: number): Promise<ApiResponse> {
        return this.axios.get(this.getScopedRoute('/campaigns/prices/state-floor'), {
            params: {
                state_location_key: stateLocationKey,
                type: campaignType,
            }
        });
    };

    getCountyFloorPrices(stateLocationKey: string, countyLocationKey: string, campaignType: number): Promise<ApiResponse> {
        return this.axios.get(this.getScopedRoute('/campaigns/prices/county-floor'), {
            params: {
                state_location_key: stateLocationKey,
                county_location_key: countyLocationKey,
                type: campaignType,
            }
        });
    };

    getStateBidPrices(stateLocationKey: string, campaignReference: string): Promise<ApiResponse> {
        return this.axios.get(`${this.getScopedRoute(`/campaigns/prices/${campaignReference}/state`)}`, {
            params: { state_abbr: stateLocationKey }
        });
    }

    getCountyBidPrices(stateLocationKey: string, countyLocationKey: string, campaignReference: string, propertyType: string): Promise<ApiResponse> {
        return this.axios.get(`${this.getScopedRoute(`/campaigns/prices/${campaignReference}/county`)}`, {
            params: { state_abbr: stateLocationKey, county_key: countyLocationKey, property_type_name: propertyType }
        });
    }

    getPriceRangeForZipCodes(zipCodes: number[], propertyTypes: string[], zipCodeTargeted?: boolean): Promise<ApiResponse> {
        return this.axios.post(`${this.getScopedRoute('/campaigns/prices/zip-code-price-range')}`, {
            zip_codes: zipCodes,
            property_types: propertyTypes,
            zip_code_targeted: zipCodeTargeted ? 1 : 0,
        });
    }

    getCampaigns(allProducts = false): Promise<ApiResponse> {
        return this.axios.get(this.getScopedRoute('/campaigns'), {
            params: { all_products: allProducts }
        });
    }

    getProductConfigurations(): Promise<ApiResponse> {
        return this.axios.get('/products/configurations', {
            params: { industry_slug: this.getIndustryKey() }
        });
    }

    pauseCampaigns(payload: CampaignPausePayload): Promise<ApiResponse> {
        return this.axios.patch(`${this.getScopedRoute('/campaigns/pause')}`, payload);
    }

    unpauseCampaigns(payload: GenericObject): Promise<ApiResponse> {
        return this.axios.patch(`${this.getScopedRoute('/campaigns/unpause')}`, payload);
    }

    deleteCampaign(campaignReference: string): Promise<ApiResponse> {
        return this.axios.delete(`${this.getScopedRoute(`/campaigns/${campaignReference}/delete`)}`);
    }

    executeCrmInteractable(crmId: number, methodName: string, payload: CrmDeliverer|CrmTemplate, isTemplate: boolean = false): Promise<ApiResponse> {
        return this.axios.post(`${this.getScopedRoute('/campaigns/crm/execute-method')}`, {
            crm_deliverer_id: payload.id ?? null,
            crm_type_id: crmId,
            method_name: methodName,
            payload: payload.payload,
            is_template: isTemplate,
        });
    }

    searchProductAssignments(options: ProductAssignmentSearchOptions): Promise<ApiResponse> {
        return this.axios.get(`${this.getScopedRoute('/product-assignments/search')}`, {
            params: options,
        });
    }

    rejectProductAssignment(payload: RejectionPayload): Promise<ApiResponse> {
        return this.axios.patch(`${this.getScopedRoute('/product-assignments/reject')}`, payload);
    }

    unrejectProductAssignment(productAssignmentId: number): Promise<ApiResponse> {
        return this.axios.patch(`${this.getScopedRoute('/product-assignments/unreject')}`, {
            product_assignment_id: productAssignmentId
        });
    }

    checkRejectionWillExceedQuota(productAssignmentId: number): Promise<ApiResponse> {
        return this.axios.get(`${this.getScopedRoute('/product-assignments/check-rejection-quota')}`, {
            params: { product_assignment_id: productAssignmentId }
        });
    }

    getCrmImportOptions(campaignReference: string): Promise<ApiResponse> {
        return this.axios.get(`${this.getScopedRoute(`/campaigns/crm/import-options`)}`, {
            params: { reference: campaignReference }
        });
    }
    getLeadVolume(params: Object): Promise<ApiResponse> {
        return this.axios.get(`${this.getScopedRoute(`/products/volume`)}`, {params});
    }
    getStatisticsByLocation(params: Object): Promise<ApiResponse> {
        return this.axios.get(`${this.getScopedRoute(`/products/location-statistics`)}`, {params});
    }

    getZipCodes(params: Object): Promise<ApiResponse> {
        return this.axios.get(`${this.getScopedRoute(`/campaigns/get-zip-codes`)}`, {params});
    }

    validateTargetedZipCodes(zipCodeArray: number[]) {
        return this.axios.post(this.getScopedRoute('/campaigns/validate-targeted-zip-codes'), {
            zip_codes: zipCodeArray,
        });
    }

    saveCompanyCrmTemplate(payload: CrmTemplate) {
        return this.axios.put(this.getScopedRoute('/campaigns/crm/save-template'), payload);
    }

    deleteCompanyCrmTemplate(templateId: number): Promise<ApiResponse> {
        return this.axios.delete(this.getScopedRoute('/campaigns/crm/delete-template'), {
            params: { template_id: templateId }
        });
    }

    createPaymentMethod(token: string, type = 'Credit Card') {
        return this.axios.post(`${this.getScopedRoute(`/billing/payment-methods`)}`, {
            stripe_token: token,
            payment_type: type
        });
    }

    searchInvoices(params: Object): Promise<ApiResponse> {
        return this.axios.get(`${this.getScopedRoute(`/billing`)}`, {params})
    }

    payInvoiceNow(invoiceId: number) {
        return this.axios.patch(`${this.getScopedRoute(`/billing/pay`)}`, {
            invoice_id: invoiceId
        });
    }

    getBillingSummary() {
        return this.axios.get(`${this.getScopedRoute(`/billing/summary`)}`)
    }

    getPaymentMethods() {
        return this.axios.get(`${this.getScopedRoute(`/billing/payment-methods`)}`);
    }

    makePaymentMethodPrimary(id: string) {
        return this.axios.post(`${this.getScopedRoute(`/billing/payment-methods/${id}/make-primary`)}`);
    }

    deletePaymentMethod(id: string) {
        return this.axios.delete(`${this.getScopedRoute(`/billing/payment-methods/${id}`)}`);
    }
}