<template>
    <div class="min-h-screen flex" v-if="isAuthenticated">
        <GlobalAlerts class="z-[1100]" />
        <div class="flex flex-col flex-1">
            <TopBar  class="relative z-[1000]" @launchIndustryServiceEditor="launchIndustryServiceEditor" />
            <div class="lg:flex flex-1 h-full bg-gray-100">
                <Sidebar
                    v-if="company.contractAccepted"
                    @launchIndustryServiceEditor="launchIndustryServiceEditor"
                    @select-industry-service="selectIndustryService"
                    :selecting-industry="selectingIndustry"
                />
                <div class="w-full flex-auto bg-gray-100 minimum-screen-height"
                     :class="{'lg:ml-64': company.contractAccepted}"
                >
                    <Banner :banner="bannerStore.banner"/>
                    <div v-if="route.name.startsWith('leads') || route.name.startsWith('dashboard')" class="bg-gray-100 h-20 w-full relative">
                        <div class="bg-white fixed top-16 md:top-20 z-[900] border-b border-gray-200 shadow-sm right-0 w-full lg:w-[calc(100%-16rem)] p-4">
                            <div class="flex items-center gap-3">
                                <svg v-if="!selectingIndustry" @click="selectingIndustry = ! selectingIndustry" class="fill-current text-cyan-500 hover:text-cyan-600 cursor-pointer" width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="28" height="36" rx="4"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3252 9.26197C13.7073 8.91268 14.2927 8.91268 14.6748 9.26197L19.6748 13.8334C20.0824 14.2061 20.1107 14.8386 19.738 15.2462C19.3654 15.6538 18.7328 15.6821 18.3252 15.3095L14 11.355L9.67477 15.3095C9.26717 15.6821 8.63464 15.6538 8.26198 15.2462C7.88931 14.8386 7.91764 14.2061 8.32524 13.8334L13.3252 9.26197ZM8.26198 20.7538C8.63464 20.3462 9.26717 20.3179 9.67477 20.6905L14 24.645L18.3252 20.6905C18.7328 20.3179 19.3654 20.3462 19.738 20.7538C20.1107 21.1614 20.0824 21.7939 19.6748 22.1666L14.6748 26.738C14.2927 27.0873 13.7073 27.0873 13.3252 26.738L8.32524 22.1666C7.91764 21.7939 7.88931 21.1614 8.26198 20.7538Z" fill="white"/>
                                </svg>
                                <svg v-else class="w-7 fill-current text-cyan-500 transform-all transition-all duration-300 animate-spin-2" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.08488 7.48542C3.4845 6.53543 4.06532 5.67237 4.79488 4.94442C5.52285 4.21441 6.38633 3.63354 7.33688 3.23442C9.29638 2.42035 11.4967 2.40564 13.4669 3.19342C13.4867 3.8409 13.758 4.45518 14.2232 4.90589C14.6885 5.35659 15.3111 5.60824 15.9589 5.60742C17.3449 5.60742 18.4589 4.49342 18.4589 3.10742C18.4589 1.72142 17.3449 0.607422 15.9589 0.607422C15.2699 0.607422 14.6469 0.883422 14.1959 1.33242C11.7649 0.359422 8.97288 0.374422 6.56088 1.39142C5.37088 1.89142 4.30088 2.61142 3.38088 3.53042C2.45196 4.45963 1.71565 5.56311 1.21422 6.77756C0.712792 7.99202 0.456103 9.29353 0.458885 10.6074H2.45888C2.45888 9.52142 2.66988 8.47142 3.08488 7.48542ZM17.8319 13.7294C17.4309 14.6814 16.8549 15.5374 16.1219 16.2704C15.3889 17.0034 14.5329 17.5794 13.5799 17.9804C11.6204 18.7945 9.42009 18.8092 7.44988 18.0214C7.43062 17.374 7.1596 16.7595 6.69444 16.3087C6.22927 15.858 5.60664 15.6064 4.95888 15.6074C3.57288 15.6074 2.45888 16.7214 2.45888 18.1074C2.45888 19.4934 3.57288 20.6074 4.95888 20.6074C5.64788 20.6074 6.27088 20.3314 6.72188 19.8824C7.90945 20.3618 9.17822 20.6079 10.4589 20.6074C12.4254 20.6112 14.3493 20.0341 15.989 18.9485C17.6288 17.863 18.9114 16.3173 19.6759 14.5054C20.1954 13.2717 20.4616 11.9461 20.4589 10.6074H18.4589C18.4614 11.6796 18.2482 12.7413 17.8319 13.7294Z"/>
                                    <path d="M10.4588 6.06946C7.95684 6.06946 5.92084 8.10546 5.92084 10.6075C5.92084 13.1095 7.95684 15.1455 10.4588 15.1455C12.9608 15.1455 14.9968 13.1095 14.9968 10.6075C14.9968 8.10546 12.9608 6.06946 10.4588 6.06946Z"/>
                                </svg>

                                <div>
                                    <p class="capitalize font-bold text-base lg:text-lg -mb-1">{{ useProperCase(company.selectedIndustry) }}</p>
                                    <p class="capitalize text-cyan-500 font-medium text-sm lg:text-base">{{ useProperCase(company.selectedService) || 'No Service Selected' }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ServiceSelector
                        class="lg:hidden"
                        @launchIndustryServiceEditor="launchIndustryServiceEditor"
                        @selectIndustryService="selectIndustryService"
                        :selecting-industry="selectingIndustry"
                    />
                    <RouterView v-show="v4Checked" />
                </div>
                <ErrorModal />
            </div>
        </div>
    </div>
    <div v-else>
        <RouterView />
    </div>
    <IndustryServiceModal
        key="industryServiceModal"
        @hideIndustryServiceModal="hideIndustryServiceEditor"
        v-if="showIndustryServiceEditor"
    />
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {RouterView, useRoute} from 'vue-router'

import {useAuthStore} from "@/stores/auth";
import {useUserStore} from "@/stores/user";
import {useCompanyStore} from "@/stores/company";
import {useBannerStore} from "@/stores/banner";
import {storePersistenceService} from "@/services/stores/persist-stores";
import TopBar from "@/components/navigation/TopBar.vue";
import Sidebar from "@/components/navigation/Sidebar.vue";
import IndustryServiceModal from "@/components/navigation/Modals/IndustryServiceModal.vue";
import ErrorModal from "./components/errors/error-modal.vue";
import Banner from "@/components/Banner.vue";
import GlobalAlerts from "@/components/v4/GlobalAlerts.vue";
import { useProperCase } from "@/composables/useProperCase.js";
import ServiceSelector from "@/components/navigation/ServiceSelector.vue";
import {usePasswordReset} from "@/stores/passwordReset";


const auth = useAuthStore();
const user = useUserStore();
const company = useCompanyStore();
const bannerStore = useBannerStore();
const passwordReset = usePasswordReset();
const route = useRoute();
const v4Checked = ref(false);

const isAuthenticated = ref(false);

const showIndustryServiceEditor = ref(false);

watch(() => route.meta.requiresAuth, async requiresAuth => isAuthenticated.value = requiresAuth && auth.isSessionValid());

const initializeStores = () => {
    storePersistenceService.load(auth, 'auth');
    storePersistenceService.load(user, 'user');
    storePersistenceService.load(company, 'company');
    storePersistenceService.load(passwordReset, 'password-reset');

    storePersistenceService.subscribe(auth, 'auth');
    storePersistenceService.subscribe(user, 'user');
    storePersistenceService.subscribe(company, 'company');
    storePersistenceService.subscribe(passwordReset, 'password-reset');
}

initializeStores();

onMounted(async () => {
    isAuthenticated.value = route.meta.requiresAuth && auth.isSessionValid();
    v4Checked.value = true;
})

const launchIndustryServiceEditor = () => { showIndustryServiceEditor.value = true; }
const hideIndustryServiceEditor = () => { showIndustryServiceEditor.value = false; }


const selectedTab = ref('Analytics');

const selectingIndustry = ref(false)
const selectIndustryService = () => { selectingIndustry.value = ! selectingIndustry.value; }
</script>

<style scoped>
 .minimum-screen-height {
    min-height: calc(100vh - 80px);;
 }
</style>
