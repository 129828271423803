import {BaseApiService} from "@/services/api/base";
import axios from "axios";

export class ApiServiceV3 extends BaseApiService {
    constructor(baseUrl = null) {
        super();

        this.baseUrl = baseUrl;
    }

    ROUTE_PROFITABILITY_ASSUMPTION = 'profitability-assumption';
    ROUTE_COMPANY_LOCATIONS = 'company-locations';
    ROUTE_COMPANY_USERS = 'users';
    ROUTE_LOCALITY_DATA = 'reference/locality-data';
    ROUTE_PRODUCTS = 'products'
    ROUTE_NOTIFICATION_SETTINGS = 'notification-settings';
    ROUTE_CONSUMER_PRODUCTS = 'products';
    ROUTE_UNSOLD_PRODUCTS = 'unsold-products';
    ROUTE_APPOINTMENTS = 'appointment';

    get axios() {
        return axios.create({
            baseURL: `${this.baseUrl}/v3` ?? '',
            headers: {
                'X-CLIENT-BEARER': this.bearer ?? ''
            }
        });
    }

    login(email, password) {
        return this.axios.post('/login', {email, password});
    }

    loginWithToken(token) {
        return this.axios.post('/login-with-token', {token});
    }

    updatePassword(payload) {
        return this.axios.post('/update-password', payload);
    }

    requestPasswordReset(email) {
        return this.axios.post('/reset-password', {
            email
        });
    }

    profile() {
        return this.axios.get('/users/me');
    }

    getCompany() {
        return this.axios.get(`/companies/${this.companyId}`);
    }

    getCompanyLogo() {
        return this.axios.get(`/companies/${this.companyId}/profile/logo`);
    }

    updateCompanyLogo(logo) {
        return this.axios.post(`/companies/${this.companyId}/profile/logo`, logo, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    getProfileProgress() {
        return this.axios.get(`/companies/${this.companyId}/profile-progress`);
    }

    getProfitabilityAssumption() {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_PROFITABILITY_ASSUMPTION}/`);
    }

    getGlobalProfitabilityAssumptions(servicesArray = null) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_PROFITABILITY_ASSUMPTION}/global`, {
            params: {
                services_filter: servicesArray
            }
        });
    }

    updateProfitabilityAssumption(payload) {
        return this.axios.put(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_PROFITABILITY_ASSUMPTION}/update`, payload);
    }

    getCompanyLocations() {
        return this.axios.get(`/companies/${this.companyId}/${this.ROUTE_COMPANY_LOCATIONS}`);
    }

    getCountries() {
        return this.axios.get(`${this.ROUTE_LOCALITY_DATA}/countries`);
    }

    getStates() {
        return this.axios.get(`${this.ROUTE_LOCALITY_DATA}/states`);
    }

    updateCompanyLocation(locationData) {
        return this.axios.patch(`/companies/${this.companyId}/${this.ROUTE_COMPANY_LOCATIONS}/${locationData.id}`, locationData);
    }

    createCompanyLocation(locationData) {
        return this.axios.post(`/companies/${this.companyId}/${this.ROUTE_COMPANY_LOCATIONS}`, locationData);
    }

    deleteCompanyLocation(locationId) {
        return this.axios.delete(`/companies/${this.companyId}/${this.ROUTE_COMPANY_LOCATIONS}/${locationId}`);
    }

    makeCompanyLocationPrimary(locationId) {
        return this.axios.patch(`/companies/${this.companyId}/${this.ROUTE_COMPANY_LOCATIONS}/make-primary/${locationId}`);
    }

    getLeadTypesPurchasedBreakdown(start, end, data = {}) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/products/purchased-breakdown`, {
            params: {
                start,
                end,
                ...data
            }
        })
    }

    getCampaignOverview() {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/overview`);
    }

    getLeadVolume(params = {}) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/products/volume`, {params});
    }

    getMissedReasons(params = {}) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/products/missed-reasons`, {params});
    }

    getCompanyUsers() {
        return this.axios.get(`/companies/${this.companyId}/${this.ROUTE_COMPANY_USERS}`);
    }

    updateCompanyUser(companyUserData) {
        return this.axios.patch(`/companies/${this.companyId}/${this.ROUTE_COMPANY_USERS}/${companyUserData.id}`, companyUserData);
    }

    createCompanyUser(companyUserData, saveSynchronously = false) {
        return this.axios.post(`/companies/${this.companyId}/${this.ROUTE_COMPANY_USERS}`, {
            ...companyUserData,
            save_synchronously: saveSynchronously,
        });
    }

    deleteCompanyUser(companyUserId) {
        return this.axios.delete(`/companies/${this.companyId}/${this.ROUTE_COMPANY_USERS}/${companyUserId}`);
    }

    getCompanyNotificationSettings() {
        return this.axios.get(`/companies/${this.companyId}/${this.ROUTE_NOTIFICATION_SETTINGS}`);
    }

    updateCompanyNotificationSettings(payload) {
        return this.axios.put(`/companies/${this.companyId}/${this.ROUTE_NOTIFICATION_SETTINGS}`, payload);
    }

    getCampaigns(product) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns?product=${product}`);
    }

    bulkUpdateCampaigns(payload) {
        return this.axios.patch(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/bulk-update`, payload);
    }

    getStateDetails(stateKey) {
        return this.axios.get(`${this.ROUTE_LOCALITY_DATA}/state/${stateKey}`);
    }

    getCountyDetails(countyKey, stateKey) {
        return this.axios.get(`${this.ROUTE_LOCALITY_DATA}/state/${stateKey}/county/${countyKey}`);
    }

    getStateZipCodes(stateKey) {
        return this.axios.get(`${this.ROUTE_LOCALITY_DATA}/state-zip-codes/${stateKey}`);
    }

    saveCampaign(campaignId, payload) {
        return campaignId
            ? this.axios.patch(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/${campaignId}/update`, payload)
            : this.axios.post(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/create`, payload);
    }

    getCampaignDetail(campaignUuid) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/${campaignUuid}`);
    }

    deleteCampaign(campaignUuid) {
        return this.axios.delete(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/${campaignUuid}/delete`);
    }

    searchLeads(payload) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_CONSUMER_PRODUCTS}/leads/get`, {params: payload});
    }

    checkRejectionQuota(leadId) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_CONSUMER_PRODUCTS}/legacy/${leadId}/check-rejection-quota`);
    }

    rejectLead(leadId, payload) {
        return this.axios.patch(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_CONSUMER_PRODUCTS}/legacy/${leadId}/reject-lead`, payload);
    }

    rejectAppointment(appointmentId, payload) {
        return this.axios.patch(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_CONSUMER_PRODUCTS}/appointments/${appointmentId}/reject`, payload);
    }

    unrejectLead(leadId) {
        return this.axios.patch(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_CONSUMER_PRODUCTS}/legacy/${leadId}/unreject-lead`);
    }

    getZipCodesByRadius(zipCode, radius) {
        return this.axios.get(`${this.ROUTE_LOCALITY_DATA}/zip-codes-radius/${zipCode}/${radius}`);
    }

    getZipCodesByZipCodeStrings(zipCodeArray) {
        return this.axios.post(`${this.ROUTE_LOCALITY_DATA}/zip-codes-by-string/`, {
            zip_codes: zipCodeArray,
        });
    }

    searchInvoices(searchOptions = {}, withBillingConfig = false) {
        return this.axios.get(`/companies/${this.companyId}/billing/invoices`, {
            params: {
                with_config: withBillingConfig ? 1 : 0,
                ...searchOptions,
            }
        });
    }


    downloadInvoice(invoiceId) {
        return this.axios.get(`/companies/${this.companyId}/billing/invoices/${invoiceId}/download`);
    }

    getTransactionsForInvoice(invoiceId) {
        return this.axios.get(`/companies/${this.companyId}/billing/invoices/${invoiceId}/transactions`);
    }

    payInvoiceNow(invoiceId) {
        return this.axios.post(`/companies/${this.companyId}/billing/invoices/${invoiceId}/pay-now`);
    }

    addCrmDeliveryIntegration(payload) {
        return this.axios.post(`companies/${this.companyId}/crm-deliveries/create`, payload);
    }

    updateCrmDeliveryIntegration(crmDeliveryId, payload) {
        return this.axios.patch(`companies/${this.companyId}/crm-deliveries/delivery/${crmDeliveryId}`, payload);
    }

    getCrmDeliveriesForCampaign(campaignUuid) {
        return this.axios.post(`companies/${this.companyId}/crm-deliveries/campaign/${campaignUuid}`);
    }

    getCrmDeliveryDetail(crmDeliveryId) {
        return this.axios.get(`companies/${this.companyId}/crm-deliveries/delivery/${crmDeliveryId}`);
    }

    deleteCrmDelivery(campaignUuid = null, crmDeliveryId) {
        return this.axios.delete(`companies/${this.companyId}/crm-deliveries/delivery/${campaignUuid}/${crmDeliveryId}`);
    }

    getAllServicesByIndustry() {
        return this.axios.get('reference/industry-services');
    }

    updateCompanyIndustryServices(serviceIds) {
        return this.axios.patch(`/companies/${this.companyId}/industry-services`, {
            'services': serviceIds,
        });
    }

    deleteContactDelivery(campaignUuid, leadDeliveryMethodId) {
        return this.axios.delete(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/${campaignUuid}/contact-delivery/${leadDeliveryMethodId}`)
    }

    getCompanyContractForUser() {
        return this.axios.get(`/companies/${this.companyId}/company-contract-signed`);
    }

    getNewCompanyContract() {
        return this.axios.get(`/companies/${this.companyId}/company-contract-new`);
    }

    agreeToCompanyContract(contractUuid) {
        return this.axios.post(`/companies/${this.companyId}/company-contract-agreed`, {
            reference: contractUuid,
        });
    }

    getZipCodesForCampaignList(campaignUuidArray) {
        return this.axios.post(`/companies/${this.companyId}/campaigns-zip-codes`, {
            uuids: campaignUuidArray
        });
    }

    updateCompanyProfile(payload) {
        return this.axios.patch(`/companies/${this.companyId}/profile/update-basic-info`, payload);
    }

    getCompanyMedia() {
        return this.axios.get(`/companies/${this.companyId}/profile/media-assets`);
    }

    uploadCompanyMedia(payload) {
        return this.axios.post(`/companies/${this.companyId}/profile/upload-media-assets`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    addYoutubeLink(urlString) {
        return this.axios.post(`/companies/${this.companyId}/profile/youtube-asset`, {
            url: urlString
        });
    }

    deleteCompanyMediaAsset(assetId) {
        return this.axios.delete(`/companies/${this.companyId}/profile/media-asset/${assetId}`);
    }

    getCompanyLicenses() {
        return this.axios.get(`/companies/${this.companyId}/profile/licenses`);
    }

    createCompanyLicense(payload) {
        return this.axios.post(`/companies/${this.companyId}/profile/licenses`, payload);
    }

    updateCompanyLicense(licenseId, payload) {
        return this.axios.patch(`/companies/${this.companyId}/profile/licenses/${licenseId}`, payload);
    }

    deleteCompanyLicense(licenseId) {
        return this.axios.delete(`/companies/${this.companyId}/profile/licenses/${licenseId}`);
    }

    searchAppointments(payload) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_CONSUMER_PRODUCTS}/${this.ROUTE_APPOINTMENTS}/get`, {params: payload});
    }

    getPriceRangeForCampaign(campaignUuid, productName) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/prices/campaign-price-range`, {
            params: {
                product_name: productName,
                uuid: campaignUuid,
            }
        });
    }

    getPriceRangeForZipCodes(zipCodeIds, productName) {
        return this.axios.post(`/companies/${this.companyId}/${this.industry}/${this.service}/prices/zipcode-price-range`, {
            product_name: productName,
            zip_codes: zipCodeIds,
        });
    }

    getProductStatisticsByLocation(params) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/products/location-statistics`, { params });
    }

    getPricesForLocation(campaignUuid, params) {
        if (params.county_key) {
            return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/${campaignUuid}/prices/county`, {
                params,
            });
        }
        else {
            return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/${campaignUuid}/prices/state`, {
                params,
            });
        }
    }

    updateBidPriceForLocation(campaignUuid, payload) {
        if (payload.county_key) {
            return this.axios.patch(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/${campaignUuid}/prices/bid-county`, payload);
        }
        else {
            return this.axios.patch(`/companies/${this.companyId}/${this.industry}/${this.service}/campaigns/${campaignUuid}/prices/bid-state`, payload);
        }
    }

    /**
     * @param searchOptions
     * @param includeSoldCount
     * @param productIds
     * @returns {Promise<AxiosResponse<any>>}
     */
    getUnsoldProducts(searchOptions = {}, includeSoldCount = false, productIds = []) {
        return this.axios.get(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_UNSOLD_PRODUCTS}`, {
            params: {
                include_sold_count : includeSoldCount ? 1 : 0,
                product_ids        : productIds,
                ...searchOptions,
            }
        });
    }

    getCountyZipCodes(zipCodes = []) {
        return this.axios.get(`/reference/locality-data/county-zip-codes`, {
            params: {
                zip_codes : zipCodes,
            }
        });
    }

    purchaseProducts(payload) {
        return this.axios.post(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_UNSOLD_PRODUCTS}/purchase-products`, payload);
    }


    reserveUnsoldProducts(payload) {
        return this.axios.post(`/companies/${this.companyId}/${this.industry}/${this.service}/${this.ROUTE_UNSOLD_PRODUCTS}/reserve-selection`, payload);
    }
    getTimezones() {
        return this.axios.get('/reference/timezones');
    }

    getAllCompanySchedules() {
        return this.axios.get(`/companies/${this.companyId}/schedules/all`);
    }

    createInternalCalendar(schedulePayload) {
        return this.axios.post(`/companies/${this.companyId}/schedules/create-static-calendar`, schedulePayload);
    }

    updateSchedule(schedulePayload) {
        return this.axios.patch(`/companies/${this.companyId}/schedules/update-schedule`, schedulePayload);
    }

    deleteSchedule(scheduleId) {
        return this.axios.delete(`/companies/${this.companyId}/schedules/delete-schedule`, {
            params: {
                id: scheduleId
            },
        });
    }

    getRecentReviews(params = {}) {
        const defaultParams = {
            offset: 0,
            limit: 10,
        };
        const mergedParams = {...defaultParams, ...params};
        return this.axios.get(`/companies/${this.companyId}/consumer-reviews/recent`, {
            params: mergedParams
        })
    }

    getCompanyRating() {
        return this.axios.get(`/companies/${this.companyId}/consumer-reviews/rating`);
    }

    getAllCustomerReviews(searchOptions = {}){
        return this.axios.get(`/companies/${this.companyId}/consumer-reviews`, {
            params: {
                ...searchOptions,
            }
        })
    }

    postReviewReply(reviewId, payload = {}) {
        return this.axios.post(`/companies/${this.companyId}/consumer-reviews/${reviewId}/reply`, payload);
    }

    getCalendarEventsByDateRange(calendarId, startDate, endDate) {
        return this.axios.get(`companies/${this.companyId}/schedules/events`, {
            params: {
                id: calendarId,
                start_date: startDate,
                end_date: endDate
            }
        });
    }

    getWatchdogPlaybackUrl(leadId) {
        return this.axios.get(`/${leadId}/watchdog`)
    }
}
