import { ref } from 'vue';
import {defineStore} from 'pinia';
import {useServicesStore} from "@/stores/services";

export const useReferenceDataStore = defineStore('referenceData', () => {
    const servicesStore = useServicesStore();

    const servicesLoaded = ref(false);
    const serviceOptionsLoaded = ref(false);
    const allServicesByIndustry = ref({});
    const timezones = ref({});
    const allServicesOptions = ref([]);

    async function getAllCompanyServices() {
        if (servicesLoaded.value) return { status: true }
        const resp = await servicesStore.apiService.getAllServicesByIndustry().catch(e=>e);
        if (resp.data?.data?.status) {
            allServicesByIndustry.value = resp.data.data.services;
            servicesLoaded.value = true;
            return { status: true }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    /**
     * Handles returning a list of all services, mainly intended to be used for dropdown options.
     * Also, handles preparing options based on the custom keys for dropdown options.
     *
     * @param optionKey - defines a key used to store the value of a user selected option.
     * @param optionLabel - defines a key for option title that user views while exploring dropdown options.
     * @returns {Promise<{message: (*|number|string), status: boolean}|{status: boolean}>}
     */
    const getAllIndustryServicesOptions = async(optionKey = 'value', optionLabel = 'label') => {
        if (serviceOptionsLoaded.value) return { status: true }
        allServicesOptions.value = [];
        const resp = await servicesStore.apiService.getAllServicesByIndustry().catch(e=>e);
        if (resp?.data?.data?.status) {
            Object.values(resp.data.data.services).forEach(serviceGroup => {
                Object.values(serviceGroup).map(service => {
                    allServicesOptions.value.push({
                        [optionKey]   : service.id,
                        [optionLabel] : service.name + ' (' + service.industry + ')'
                    });
                })
            });
            serviceOptionsLoaded.value = true;
            return { status: true }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    async function getTimezones() {
        if (timezones.value.length) return { status: true };
        const resp = await servicesStore.apiService.getTimezones().catch(e=>e);
        if (resp.data?.data?.status) {
            timezones.value = resp.data.data.timezones;
            return { status: true }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const genericErrorResponse = (response) => ({ status: false, message: response?.data?.data?.message || response.message | response.err || 'An unknown error occurred.' });

    const clearStore = () => {
        allServicesByIndustry.value = {};
        allServicesOptions.value = [];
        servicesLoaded.value = false;
        serviceOptionsLoaded.value = false;
    }

    const $reset = () => {
        clearStore();
    }

    return {
        allServicesByIndustry,
        timezones,
        allServicesOptions,
        getAllCompanyServices,
        getTimezones,
        getAllIndustryServicesOptions,
        $reset,
    }

});