<template>
    <div class="min-h-[12rem]">
        <LoadingSpinner v-if="loading" :small="true" />
        <div v-else>
            <div>
                <div v-if="productConfiguration.getRequiredBudgetKeys().length" class="py-4">
                    <h4 class="font-semibold pb-4">
                        Required {{ productConfiguration.getProductLabel(true) }}
                    </h4>
                    <div v-for="budget in productConfiguration.getRequiredBudgets()">
                        <div class="grid grid-cols-1 md:grid-cols-2 items-start gap-x-4 max-w-5xl">
                            <div class="flex items-center gap-x-4 w-full">
                                <CustomSelect
                                    class="w-full"
                                    :label="budget.display_name"
                                    :options="productConfiguration.getBudgetTypeOptions()"
                                    type="number"
                                    v-model="slideInputs.budgets[budget.key].type"
                                    @update:model-value="handleBudgetTypeChange"
                                />
                            </div>
                            <CustomInput
                                v-if="slideInputs.budgets[budget.key].type === BudgetType.TYPE_DAILY_SPEND"
                                input-type="number"
                                prefix-text="$"
                                v-model="slideInputs.budgets[budget.key].value"
                                label="Spend Limit"
                                @change:modelValue="validateBudgetInput"
                            />
                            <CustomInput
                                v-if="slideInputs.budgets[budget.key].type === BudgetType.TYPE_DAILY_UNITS"
                                input-type="number"
                                v-model="slideInputs.budgets[budget.key].value"
                                :label="`${productConfiguration.getProductLabel()} Limit`"
                                @change:modelValue="validateBudgetInput"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="productConfiguration.getOptionalBudgetKeys().length" class="max-w-5xl py-4">
                <h4 class="font-semibold pb-4">
                    Optional {{ productConfiguration.getProductLabel(true) }}
                </h4>
                <div class="relative hidden z-50 md:sticky md:top-20 md:grid md:grid-cols-6 bg-cyan-50 text-center font-bold py-2 items-center border-b border-gray-200 text-xs text-gray-700 uppercase">
                    <p>Active</p>
                    <p>Name</p>
                    <p class="col-span-2">{{ productConfiguration.usesLinkedBudgets ? budgetTypeLabel : "Daily Limit" }}</p>
                    <p class="col-span-2">{{ productConfiguration.getProductLabel() }} Price</p>
                </div>
                <div v-for="budgetKey in productConfiguration.getOptionalBudgetKeys()"
                     :key="budgetKey"
                >
                    <div v-if="slideInputs.budgets[budgetKey]"
                         class="relative border-b odd:bg-gray-50 text-gray-600 text-sm md:text-center items-center grid md:grid-cols-6 gap-4 md:gap-0 p-4 md:py-4 md:px-0"
                    >
                        <div>
                            <p class="font-bold text-sm md:hidden text-gray-900">Active</p>
                            <ToggleSwitch
                                v-model="slideInputs.budgets[budgetKey].status"
                                :small="true"
                                color="green"
                                @change="() => validateBudgetInput"
                            />
                        </div>
                        <div>
                            <p class="font-bold text-sm md:hidden text-gray-900">Name</p>
                            <div class="flex items-center md:justify-center">
                                <p class="inline">
                                    {{ useProperCase(budgetKey) ?? '' }}
                                </p>
                                <Tooltip class="inline" v-if="tooltips[budgetKey]">
                                    {{ tooltips[budgetKey] }}
                                </Tooltip>
                            </div>
                        </div>
                        <div  class="col-span-2">
                            <!--  Optional budgets have their type linked to required budget type e.g. Leads -->
                            <div v-if="productConfiguration.usesLinkedBudgets">
                                <p class="font-bold text-sm md:hidden text-gray-900">{{ budgetTypeLabel }}</p>
                                <div v-if="slideInputs.budgets[budgetKey].status"
                                     class="flex md:justify-center items-center"
                                >
                                    <CustomInput
                                        v-if="slideInputs.budgets[budgetKey]?.type === BudgetType.TYPE_DAILY_SPEND"
                                        type="text"
                                        prefix-text="$"
                                        classes="max-w-[10rem]"
                                        v-model="slideInputs.budgets[budgetKey].value"
                                        @change:modelValue="validateBudgetInput"
                                    />
                                    <CustomInput
                                        v-if="slideInputs.budgets[budgetKey].type === BudgetType.TYPE_DAILY_UNITS"
                                        type="text"
                                        input-pattern="\d+"
                                        classes="max-w-[10rem]"
                                        v-model="slideInputs.budgets[budgetKey].value"
                                        @change:modelValue="validateBudgetInput"
                                    />
                                </div>
                            </div>
                            <!--  Optional budgets are independent e.g. Appointments -->
                            <div v-else>
                                <p class="font-bold text-sm md:hidden text-gray-900">Budget settings</p>
                                <div v-if="slideInputs.budgets[budgetKey].status"
                                     class="flex md:justify-center items-start gap-x-2"
                                >
                                    <CustomSelect
                                        :label="null"
                                        :options="productConfiguration.getBudgetTypeOptions()"
                                        type="number"
                                        v-model="slideInputs.budgets[budgetKey].type"
                                    />
                                    <div class="w-24">
                                        <CustomInput
                                            v-if="slideInputs.budgets[budgetKey]?.type === BudgetType.TYPE_DAILY_SPEND"
                                            type="text"
                                            prefix-text="$"
                                            classes="max-w-[10rem]"
                                            v-model="slideInputs.budgets[budgetKey].value"
                                            @change:modelValue="validateBudgetInput"
                                        />
                                        <CustomInput
                                            v-if="slideInputs.budgets[budgetKey].type === BudgetType.TYPE_DAILY_UNITS"
                                            type="text"
                                            input-pattern="\d+"
                                            classes="max-w-[6rem]"
                                            v-model="slideInputs.budgets[budgetKey].value"
                                            @change:modelValue="validateBudgetInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-2">
                            <p v-if="prices[budgetKey]?.max">
                                ${{ prices[budgetKey].min }} - ${{ prices[budgetKey].max }}
                            </p>
                            <p v-else-if="prices[budgetKey]?.min">
                                ${{ prices[budgetKey].min }}
                            </p>
                            <div v-else>
                                <p v-if="loading" class="text-slate-700">Searching prices...</p>
                                <p v-else class="text-slate-700">Could not fetch price.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="slideInputs.options" class="col-start-1 my-10 mx-2 md:mx-0">
                    <h4 class="font-semibold pb-2">
                        Other Options
                    </h4>
                    <div class="flex items-center gap-x-2">
                        <CustomCheckbox
                            v-model="slideInputs.options.allow_non_budget_premium_leads"
                        />
                        <p class="text-sm md:text-base">Accept Qualified Premium Leads When Over Budget</p>
                    </div>
                </div>

            </div>
            <div v-if="productConfiguration.getRequiredBudgetKeys().length && productConfiguration.showPricing"
                 class="justify-center flex flex-col max-w-5xl pt-6"
            >
                <div class="flex flex-row items-center pb-4">
                    <h4 class="font-semibold">
                        Required {{ productConfiguration.getProductLabel() }} Prices
                    </h4>
                    <Tooltip v-if="tooltips.required">{{ tooltips.required[productConfiguration.defaultBudgetKey] ?? tooltips.required.default }}</Tooltip>
                </div>
                <table class="w-full text-sm text-left text-gray-500">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-100 text-center">
                    <tr>
                        <th class="px-6 py-3">Name</th>
                        <th class="px-6 py-3">{{ productConfiguration.getProductLabel() }} Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="{ key, name } in productConfiguration.getSalesTypes(productConfiguration.defaultBudgetKey, true)"
                        :key="key"
                        class="bg-white even:bg-gray-50 border-b border-gray-200 last:border-none text-center h-20"
                    >
                        <td class="px-6 py-3">
                            <div class="flex items-center justify-center relative">
                                <p class="inline">
                                    {{ name }}
                                </p>
                                <Tooltip v-if="tooltips[key]">
                                    {{ tooltips[key] }}
                                </Tooltip>
                            </div>
                        </td>
                        <td class="px-6 py-3">
                            <p v-if="prices[key]?.max">
                                ${{ prices[key].min }} - ${{ prices[key].max }}
                            </p>
                            <p v-else-if="prices[key]?.min">
                                ${{ prices[key].min }}
                            </p>
                            <div v-else>
                                <p v-if="loading" class="text-slate-700">Searching prices...</p>
                                <p v-else class="text-slate-700">Could not fetch price.</p>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {CustomSlideValidator, useWizardStore} from "@/stores/v4/wizard";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import {useFutureCampaignStore} from "@/stores/v4/future-campaigns";
import {computed, ComputedRef, onMounted, reactive, ref, Ref, watch} from "vue";
import {ReactiveVariable} from "vue/macros";
import Tooltip from "@/components/Tooltip.vue";
import ToggleSwitch from "@/components/inputs/ToggleSwitch.vue";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import {useProperCase} from "@/composables/useProperCase";
import {BudgetType, useProductConfigurationStore} from "@/stores/v4/product-configuration";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {useBiddingStore} from "@/stores/v4/bidding";
import {useAlertStore} from "@/stores/v4/alerts";
import {ProductType} from "@/stores/services";

interface Props {
    initialSlideData: GenericObject,
}
const props = defineProps<Props>();

const emit = defineEmits(['update:slideInput']);

const campaignStore = useFutureCampaignStore();
const wizardStore = useWizardStore();
const biddingStore = useBiddingStore();
const productConfiguration = useProductConfigurationStore();
const alertStore = useAlertStore();

const slideInputs: ReactiveVariable<GenericObject> = reactive({
    budgets: {}
    // disabled for now, causing error options: { allow_non_budget_premium_leads: false },
});

const budgetTypeLabel: ComputedRef<string> = computed(() => productConfiguration.getBudgetTypeLabel(slideInputs.budgets[productConfiguration.defaultBudgetKey]?.type));

const prices: Ref<GenericObject> = ref({});
const loading: Ref<boolean> = ref(true);

const initialize = async () => {
    loading.value = true;

    Object.assign(slideInputs, props.initialSlideData);

    productConfiguration.getRequiredBudgets().forEach(budget => {
        slideInputs.budgets[budget.key] = slideInputs.budgets[budget.key]?.key
            ? slideInputs.budgets[budget.key]
            : productConfiguration.getBudgetDefault(budget.key, true);
        slideInputs.budgets[budget.key].status = true;
    });
    productConfiguration.getOptionalBudgets().forEach(budget => {
        slideInputs.budgets[budget.key] = slideInputs.budgets[budget.key]?.key
            ? slideInputs.budgets[budget.key]
            : productConfiguration.getBudgetDefault(budget.key, false);
    });

    const currentBudgetType = slideInputs.budgets[productConfiguration.defaultBudgetKey]?.type;
    if (currentBudgetType > 0)
        handleBudgetTypeChange(currentBudgetType);

    const { data, message } = await biddingStore.getPriceRangeForZipCodes();
    if (data)
        prices.value = data;
    else
        alertStore.showError(message ?? "An error occurred fetching current prices.");

    loading.value = false;
}

onMounted(() => initialize());

const handleBudgetTypeChange = (newValue: number) => {
    if (!productConfiguration.usesLinkedBudgets) return;

    for (const budgetKey in slideInputs.budgets) {
        if (!slideInputs.budgets[budgetKey].required) {
            slideInputs.budgets[budgetKey].type = newValue;
        }
    }
}

const handleInputUpdate = (newValue: any, inputKey: string) => {
    emit('update:slideInput', newValue, inputKey);
}

watch(() => slideInputs.budgets, (newValue) => { handleInputUpdate(newValue, 'budgets')}, { deep: true});

const tooltipStore: GenericObject = {
    lead: {
      required: {
        default: 'Exclusive, Duo, Trio and Quad are required lead types and must be selected.',
        exclusive_only: 'This Campaign only receives Exclusive leads.',
      },
      exclusive: 'Matched only to you',
      duo: 'Matched with 2 installers',
      trio: 'Matched with 3 installers',
      quad: 'Matched with 4 installers',
      email_only: 'Communication by email only',
      unverified: 'Phone number not verified',
    },
    direct_leads: {
        required: 'Exclusive, Duo, and Trio are required lead types and must be selected ',
        exclusive: 'Matched only to you',
        duo: 'Matched with 2 companies',
        trio: 'Matched with 3 companies',
        quad: 'Matched with 4 companies',
        email_only: 'Communication by email only',
        unverified: 'Phone number not verified',
    },
}
const tooltips: ComputedRef<GenericObject> = computed(() => {
    if (productConfiguration.productScope === ProductType.DirectLeads) {
        return tooltipStore['direct_leads'] ?? {};
    }

    return tooltipStore[productConfiguration.productScope] ?? {};
});

const validateBudgetInput: CustomSlideValidator = () => {
    const budgetErrors = [];
    const minimumDailySpend = productConfiguration.minimumDailySpend;
    const minimumDailyProducts = productConfiguration.minimumDailyProducts;
    const defaultBudgetName = productConfiguration.getBudgetName(productConfiguration.defaultBudgetKey, true);

    if (slideInputs.budgets[productConfiguration.defaultBudgetKey].type === BudgetType.TYPE_DAILY_SPEND) {
        const maxDailySpend = productConfiguration.usesLinkedBudgets ? slideInputs.budgets[productConfiguration.defaultBudgetKey].value : null;
        if (maxDailySpend != null && maxDailySpend < minimumDailySpend)
            budgetErrors.push(`The ${defaultBudgetName} ${productConfiguration.getProductLabel(true)} spend must be at least $${minimumDailySpend}.`);
        else {
            for (const budgetKey of productConfiguration.getOptionalBudgetKeys()) {
                if (slideInputs.budgets[budgetKey]?.status) {

                    if (slideInputs.budgets[budgetKey].value < minimumDailySpend) {
                        budgetErrors.push(`The ${productConfiguration.getBudgetName(budgetKey)} ${productConfiguration.getProductLabel(true)} spend must be at least $${minimumDailySpend}`);
                    } else if (maxDailySpend != null && slideInputs.budgets[budgetKey].value > maxDailySpend) {
                        budgetErrors.push(`The ${productConfiguration.getBudgetName(budgetKey)} ${productConfiguration.getProductLabel(true)} spend cannot be more than the ${defaultBudgetName} daily spend`);
                    }
                }
            }
        }
    }
    else if (slideInputs.budgets[productConfiguration.defaultBudgetKey].type === BudgetType.TYPE_DAILY_UNITS) {
        const maxDailyProducts = productConfiguration.usesLinkedBudgets ? slideInputs.budgets[productConfiguration.defaultBudgetKey].value : null;
        if (maxDailyProducts != null && maxDailyProducts < minimumDailyProducts)
            budgetErrors.push(`The ${productConfiguration.getBudgetName(productConfiguration.defaultBudgetKey, true)} ${productConfiguration.getProductLabel(true)} limit must be at least $${minimumDailyProducts}`);
        else {
            for (const budgetKey of productConfiguration.getOptionalBudgetKeys()) {
                if (slideInputs.budgets[budgetKey]?.status) {
                    if (slideInputs.budgets[budgetKey].value < minimumDailyProducts) {
                        budgetErrors.push(`The ${productConfiguration.getBudgetName(budgetKey)} ${productConfiguration.getProductLabel(true)} limit must be at least ${minimumDailyProducts}`);
                    } else if (maxDailyProducts != null && slideInputs.budgets[budgetKey].value > maxDailyProducts) {
                        budgetErrors.push(`The ${productConfiguration.getBudgetName(budgetKey)} ${productConfiguration.getProductLabel(true)} limit cannot be more than the ${defaultBudgetName} daily limit`);
                    }
                }
            }
        }
    }

    return budgetErrors.length
        ? { valid: false, message: wizardStore.validationService.getErrorBagSummary(budgetErrors), errorBag: budgetErrors }
        : { valid: true };
}

defineExpose({ customValidation: validateBudgetInput });

</script>