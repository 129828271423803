<template>
    <Module>
        <template v-slot:title>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="text-gray-500 w-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>

                <h5 class="text-md">Invoices</h5>
            </div>
        </template>


        <div class="flex flex-col">
            <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div class="md:col-span-2">
                    <p class="text-gray-500 mb-2">Date Range</p>
                    <DatePicker
                        v-model="billingStore.searchOptions.date_range"
                        range
                        input-class-name="h-11 rounded-md"
                        model-type="timestamp"
                        :enable-time-picker="false"
                        @cleared="clearDateRange"
                    />
                </div>
                <div class="md:col-span-2">
                    <p class="text-gray-500 mb-2">Billing Status</p>
                    <CustomSelect
                        :options="billingStatusOptions"
                        v-model="billingStore.searchOptions.status"
                        :label="null"
                    />
                </div>

                <div class="md:col-span-1">
                    <p class="text-gray-500 mb-2">Invoice No.</p>
                    <input type="text" class="relative transition-colors w-full cursor-pointer border border-gray-200 px-4 py-2 relative flex hover:border-cyan-500 focus:border-cyan-500 active:border-cyan-500 rounded-lg outline-none"
                        v-model="billingStore.searchOptions.invoice_id"
                    />
                </div>
            </div>
            <div class="flex mt-4">
                <SolidButton class="ml-auto"
                    @click="submitSearch"
                >Search</SolidButton>
            </div>
            <div v-if="errorMessage" class="py-4">
                <p class="text-red-900 text-center whitespace-pre">{{ errorMessage }}</p>
            </div>
        </div>

        <div class="relative min-h-[12rem]">
            <LoadingSpinner v-if="!storesInitialized || loading" :small="true" />
            <div v-else class="mt-4 relative overflow-x-auto"
                :class="[loading ? 'pointer-events-none grayscale-[50%] opacity-50' : '' ]"
            >
                <Pagination
                    :limit="billingStore.paginationLimit"
                    :total="billingStore.paginationTotal"
                    :offset="billingStore.paginationOffset"
                    @change-page="handlePageChange"
                >
                    <table class="w-full text-sm text-left text-gray-500"
                       :class="[ saving ? 'grayscale-[60%] opacity-50 pointer-events-none' : '' ]"
                    >
                        <thead class="text-xs text-gray-700 uppercase bg-cyan-50 text-center">
                        <tr>
                            <th class="px-6 py-3">Invoice ID</th>
                            <th class="px-6 py-3">Date Created</th>
                            <th class="px-6 py-3">Total Cost</th>
                            <th class="px-6 py-3">Paid</th>
                            <th class="px-6 py-3">Date Due</th>
                            <th class="px-6 py-3">PDF</th>
                            <th class="px-6 py-3">Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="invoice in billingStore.invoices"
                            :key="invoice.id"
                            class="bg-white even:bg-gray-50 border-b border-gray-200 last:border-none text-center"
                        >
                            <td class="px-6 py-3">{{ invoice.id }}</td>
                            <td class="px-6 py-3">{{ useFormatTimestamp(invoice.created_at )}}<br>{{ useFormatTimestamp(invoice.created_at, { time: true, date: false })}}</td>
                            <td class="px-6 py-3">${{ invoice.total?.toFixed?.(2) || '0.00' }}</td>
                            <td class="px-6 py-3">{{ invoice.status === 'initial' ? 'Not Paid' :  useProperCase(invoice.status) }}</td>
                            <td class="px-6 py-3">{{ useFormatTimestamp(invoice.due_at )}}<br>{{ useFormatTimestamp(invoice.due_at, { time: true, date: false })}}</td>
                            <td class="px-6 py-3"><OutlineButton class="text-xs" @click="downloadPdf(invoice)" >PDF</OutlineButton></td>
                            <td class="px-6 py-3">
                                <div class="flex flex-col items-center">
                                    <button v-if="canPayInvoice(invoice)" @click="payInvoice(invoice)" class="text-xs text-cyan-500 cursor-pointer">Pay Now</button>
                                    <button @click="getTransactionInfo(invoice.id)" class="text-xs text-cyan-500 cursor-pointer">Payments</button>
                                    <button @click="goToLeads(invoice.id)" class="text-xs text-cyan-500 cursor-pointer">Leads</button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <template v-slot:summary>
                        <div>
                            Total:
                            <span class="text-green-500">{{ getPageInvoiceTotal() }} this page</span>
                            <span class="text-green-500 ml-4">({{ getInvoiceGrandTotal() }} total)</span>
                        </div>
                    </template>
                </Pagination>
            </div>
        </div>
        <PurchaseStatus :visible="showPaymentStatusModel"
                        :payment-status="paymentStatus"
                        model-heading="Pay Invoice"
                        :paragraph="paymentMessage"
                        @update:visible="showPaymentStatusModel = $event"
        />
        <Modal
            v-if="showTransactionModal"
            :show-cancel-button="false"
            confirm-label="Ok"
            @clicked:confirm="hideTransactionModal"
            :small="true"
        >
            <template v-slot:header>
                <p>Payment Attempts</p>
            </template>
            <template v-slot:body>
                <div>
                    <table>
                        <thead class="text-xs text-gray-700 uppercase bg-cyan-50 text-center">
                            <tr>
                                <th class="px-6 py-3">Type</th>
                                <th class="px-6 py-3">Status</th>
                                <th class="px-6 py-3">Amount</th>
                                <th class="px-6 py-3">Date Paid</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="transaction in billingStore.transactions"
                                :key="transaction.id"
                                class="bg-white even:bg-gray-50 border-b border-gray-200 last:border-none text-center"
                            >
                                <td class="px-6 py-3">{{ transaction.type }}</td>
                                <td class="px-6 py-3">{{ useProperCase(transaction.status) }}</td>
                                <td class="px-6 py-3">${{ new Intl.NumberFormat('en-US').format(transaction.amount ?? 0) }}</td>
                                <td class="px-6 py-3">{{ useFormatTimestamp(transaction.paid_on) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </Modal>
    </Module>
</template>

<script setup>
import Module from "@/components/Module.vue";
import SolidButton from "@/components/inputs/SolidButton.vue";
import { onMounted, ref } from "vue";
import OutlineButton from "@/components/inputs/OutlineButton.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import { useBillingStore } from "@/stores/billing.js";
import { useFormatTimestamp } from "@/composables/useFormatTimestamp.js";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { useLeadsStore } from "@/stores/leads.js";
import { useErrorStore } from "@/stores/errors.js";
import { useRouter } from "vue-router";
import Modal from "@/components/Modal.vue";
import { useProperCase } from "@/composables/useProperCase.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useDownloadFile } from "@/composables/useDownloadFile.js";
import PurchaseStatus from "@/components/billing/PurchaseStatus.vue";
import {useProductAssignmentStore} from "@/stores/v4/products";

const props = defineProps({
    storesInitialized: {
        type: Boolean,
        default: false,
    }
});

const leadStore = useLeadsStore();
const billingStore = useBillingStore();
const errors = useErrorStore();
const productStore = useProductAssignmentStore();
const router = useRouter();

const loading = ref(false);
const saving = ref(false);
const errorMessage = ref(null);

const billingStatusOptions = ref([]);

const showTransactionModal = ref(false);
const showPaymentStatusModel = ref(false);
const paymentStatus = ref('');
const paymentMessage = ref('');

onMounted(() => {
    billingStatusOptions.value = billingStore.billingConfigData.billing_status_options.map(status => ({ label: status.name, value: status.id }));
});

const submitInvoiceSearch = async (page = 1) => {
    loading.value = true;
    const { status, message } = await billingStore.search(page);
    if (!status) { 
        errorMessage.value = message;
        errors.report(message);
    }
    loading.value = false;
}

const getInvoiceGrandTotal = () => {
    const companySummaryTotal = parseFloat(billingStore.billingConfigData.company_summary?.total) ?? null;
    return companySummaryTotal
        ? `$${new Intl.NumberFormat('en-US').format(companySummaryTotal)}`
        : '$0';
}

const getPageInvoiceTotal = () => {
    const total = billingStore.invoices.reduce((output, invoice) => {
        const invoiceTotal = parseFloat(invoice.total);
        return invoiceTotal
            ? output + invoiceTotal
            : output;
    }, 0);
    return `$${new Intl.NumberFormat('en-US').format(total)}`;
}

const submitSearch = async () => {
    if (loading.value) return;
    loading.value = true;
    const { status, message } = await billingStore.search();
    if (!status) {
        errorMessage.value = message;
        errors.report(message);
    }
    loading.value = false;
}

const downloadPdf = async (invoice) => {
    saving.value = true;

    try {
        if (invoice.source === 'legacy') {
            await downloadLegacyPdf(invoice.id)
        } else {
            window.open(invoice.pdf)
        }
    } catch (err) {
        console.error(err)
    }

    saving.value = false;
}

const downloadLegacyPdf = async (invoiceId) => {
    const { pdf, filename, status, message } = await billingStore.downloadInvoice(invoiceId);
    if (status) {
        const anchorHref = `data:application/pdf;base64,${pdf}`;
        useDownloadFile(anchorHref, filename);
    } else {
        errorMessage.value = message;
        errors.report(message);
    }
}

const getTransactionInfo = async (invoiceId) => {
    loading.value = true;
    const { status, message } = await billingStore.getTransactionsForInvoice(invoiceId);
    if (!status) {
        errorMessage.value = message;
        errors.report(message);
    }
    else {
        showTransactionModal.value = true;
    }
    loading.value = false;
}

const goToLeads = (invoiceId) => {
    productStore.setSearchOptions({ invoice_id: invoiceId })
    leadStore.initialize({ invoice_id: invoiceId });
    router.push('/leads');
}

const canPayInvoice = (invoice) => {
    return invoice.status === 'initial' || invoice.status === 'issued';
}

const payInvoice = async (invoice) => {
    loading.value = true;
    const resp = await billingStore.payInvoiceNow(invoice.id);
    if (!resp.status && resp.message) {
        errorMessage.value = resp.message;
        errors.report(resp.message);
    } else if (resp.status === 'initial') {
        paymentStatus.value = 'pending';
        paymentMessage.value = 'Payment request successfully submitted and being processed.';
        showPaymentStatusModel.value = true;
    } else if (resp.status === 'paid') {
        paymentStatus.value = 'success';
        paymentMessage.value = 'Payment successful';
        showPaymentStatusModel.value = true;
    }
    invoice.status = 'paid'; // Temporary optimistic ui update
    loading.value = false;
    // await submitInvoiceSearch()
}

const hideTransactionModal = () => {
    showTransactionModal.value = false;
}

const clearDateRange = () => {
    billingStore.searchOptions.date_start = null;
    billingStore.searchOptions.date_end = null;
}

const handlePageChange = (payload) => {
    submitInvoiceSearch(payload.page);
}


</script>