import { defineStore } from "pinia";
import { useServicesStore } from "@/stores/services";
import { reactive, ref, toRaw } from "vue";
import {useCreateCsvString} from "@/composables/useCreateCsvString";
import {useDownloadFile} from "@/composables/useDownloadFile";


export const useLeadsStore = defineStore('leads', () => {
    const servicesStore = useServicesStore();

    const leads = ref([]);
    const totalSpend = ref(0);

    const searchOptions = reactive({
        date_range: null,
        lead_id: null,
        contact_id: null,
        contact_name: '',
        status: null,
        address: '',
        invoice_id: null,
        missed_only: null,
        campaign_id: null,
        property_type_id: null,
        over_budget: null,
        page: 1,
    });

    const leadConfigData = {
        show_over_budget: true,
        over_budget_options: [
            { name: 'All', id: '' },
            { name: 'Qualified Over Budget', id: 1 },
            { name: 'Within Budget', id: 0 },
        ],
        lead_statuses: [
            { name: 'All', id: '' },
            { name: 'Purchased', id: 'purchased' },
            { name: 'Non Chargeable', id: 'non_chargeable' },
            { name: 'Rejected', id: 'rejected' },
        ],
        lead_rejection_reasons: [
            { name: 'Bad Phone', id: 'ph' },
            { name: 'Duplicate Lead', id: 'du' },
            { name: 'Other', id: 'ot' }
        ]
    };

    const initialized = ref(false);

    const getLeadById = (leadId) => {
        return leads.value.find(lead => lead.id === leadId);
    }

    const paginationLimit = ref(0);
    const paginationTotal = ref(0);
    const paginationOffset = ref(0);

    const getLeadStatusById = (statusId) => {
        return leadConfigData.lead_statuses?.find(status => status.id === statusId);
    }
    const getOverBudgetOptionById = (optionId) => {
        return leadConfigData.over_budget_options?.find(option => option.id === optionId);
    }

    const initialize = async (initialSearchConditions = null) => {
        if (initialized.value) return { status: true }
        initialized.value = true;
        if (initialSearchConditions) {
            Object.assign(searchOptions, initialSearchConditions);
        }
        search();
        return { status: true }
    }

    const search = async (page = 1) => {
        searchOptions.page = page;
        const payload = { ...toRaw(searchOptions) };
        processRequestData(payload);
        const resp = await servicesStore.apiService.searchLeads(payload).catch(e=>e);
        if (resp.data?.data?.status) {
            leads.value = resp.data.data.products;
            paginationTotal.value = resp.data.data.total;
            paginationLimit.value = resp.data.data.limit;
            paginationOffset.value = resp.data.data.offset;
            totalSpend.value = resp.data.data.total_spend;
            return { status: true }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const download = async () => {
        const payload = { ...toRaw(searchOptions), download: true };
        processRequestData(payload);
        const resp = await servicesStore.apiService.searchLeads(payload).catch(e=>e);

        if (resp.data?.data?.status) {
            try {
                const header = ["Lead ID", "Campaign Name", "Email Address", "Phone Number", "Name",
                    "Street", "City", "State", "Zip Code", "Date Sent", "Status", "Quote Cost", "Invoice ID", "Sale Type", "Best Time To Call"];
                const formattedLeads = resp.data.data.products.map(lead => [
                    lead.id, lead.campaign_name, lead.email, lead.phone, lead.name, lead.street_address, lead.city,
                    lead.state, lead.zip_code, lead.date_sent, lead.status, lead.effective_cost, lead.invoice_id,
                    lead.sale_type, lead.best_time_to_call
                ]);
                const filename = `Fixr_Leads_${Date.now()}.csv`;

                const encodedCsv = useCreateCsvString(header, formattedLeads);
                useDownloadFile(encodedCsv, filename);
            } catch (e) {
                errors.report(e, 'There was an error downloading leads, please contact support.');
            }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const checkRejectionQuota = async (leadId) => {
        const resp = await servicesStore.apiService.checkRejectionQuota(leadId).catch(e=>e);
        if (resp.data?.data?.status) {
            return { status: true, exceeds_warning: resp.data.data.exceeds_warning }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const rejectLead = async (legacyLeadId, payload, unreject = false) => {
        const resp = unreject
            ? await servicesStore.apiService.unrejectLead(legacyLeadId).catch(e=>e)
            : await servicesStore.apiService.rejectLead(legacyLeadId, payload).catch(e=>e);
        if (resp.data?.data?.status) {
            return { status: true, rejection_percentage: resp.data.data.rejection_percentage }
        }
        else {
            return genericErrorResponse(resp);
        }
    }

    const unrejectLead = async (legacyLeadId) => await rejectLead(legacyLeadId, null,true);

    const clearSearchOptions = () => {
        Object.assign(searchOptions, {
            date_range: null,
            lead_id: null,
            contact_id: null,
            contact_name: '',
            status: null,
            address: '',
            invoice_id: null,
            missed_only: null,
            campaign_id: null,
            property_type_id: null,
            over_budget: null,
            page: 1,
        });
    }

    const processRequestData = (payload) => {
        [ payload.date_start, payload.date_end ] = payload.date_range || [ null, null ];
    }
    const clearStore = () => {
        leads.value = [];
    }

    const genericErrorResponse = (response) => ({ status: false, message: response?.response?.data?.message || response.message | response.err || 'An unknown error occurred.' });

    const $reset = () => {
        clearSearchOptions();
        clearStore();
    }

    return {
        leads,
        leadConfigData,
        searchOptions,
        paginationLimit,
        paginationTotal,
        paginationOffset,
        initialize,
        search,
        getLeadById,
        getLeadStatusById,
        getOverBudgetOptionById,
        checkRejectionQuota,
        rejectLead,
        unrejectLead,
        clearSearchOptions,
        $reset,
        totalSpend,
        download
    }
});

