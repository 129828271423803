<template>
    <div>
        <Modal
            @clicked:confirm="importCrmConfiguration"
            @clicked:cancel="closeModal"
            confirm-label="Import"
            key="crmImportModal"
        >
            <template v-slot:header>
                <div class="flex items-center">
                    <svg  class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                    <h5 class="text-md">Import CRM Delivery</h5>
                </div>
            </template>
            <template v-slot:body>
                <LoadingSpinner v-if="loading" :small="true" />
                <div v-else>
                    <div v-if="!deliveryStore.crmImportOptions?.length">
                        No importable CRM configurations were found.
                    </div>
                    <div v-else class="grid grid-cols-2 items-start gap-4">
                        <CustomSelect
                            :options="crmOptions"
                            label="Available CRM configurations"
                            v-model="selectedCrmId"
                            type="number"
                        />
                    </div>
                    <div v-if="selectedCrm">
                        <!--   SYSTEM FIELDS   -->
                        <div v-if="selectedCrm.payload.system_fields" class="border-t border-gray-200 pt-6 mt-8">
                            <h2 class="font-semibold pb-3">System Fields</h2>
                            <div class="grid grid-cols-2 items-start gap-x-4">
                                <div v-for="systemField in selectedCrm.payload.system_fields"
                                     :key="systemField.key"
                                >
                                    <CustomInput
                                        :input-disabled="true"
                                        :model-value="systemField.value"
                                        :label="systemField.display_name"
                                    />
                                </div>
                            </div>
                        </div>
                        <!--   CUSTOM HEADERS   -->
                        <div v-if="selectedCrm.payload.headers?.length" class="border-t border-gray-200 pt-6 mt-8">
                            <h2 class="font-semibold pb-3">Custom Headers</h2>
                            <div class="grid grid-cols-2 items-start gap-4 gap-y-6">
                                <div v-for="(customHeader, index) in selectedCrm.payload.headers"
                                     :key="index"
                                >
                                    <div class="grid grid-cols-2 gap-x-2">
                                        <CustomInput
                                            label="Key"
                                            v-model="customHeader.key"
                                            :input-disabled="true"
                                        />
                                        <div class="relative">
                                            <CustomInput
                                                label="Value"
                                                v-model="customHeader.value"
                                                :input-disabled="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--   ADDITIONAL FIELDS   -->
                        <div v-if="selectedCrm.payload.additional_fields.length && showPresetFields" class="border-t border-gray-200 pt-6 mt-8">
                            <h2 class="font-semibold pb-3">Fields</h2>
                            <div class="grid grid-cols-2 items-start gap-4 gap-y-6">
                                <div v-for="additionalField in selectedCrm.payload.additional_fields"
                                     :key="additionalField.key"
                                >
                                    <CustomInput
                                        :input-disabled="true"
                                        :model-value="additionalField.value"
                                        :label="additionalField.display_name"
                                    />
                                </div>
                            </div>
                        </div>
                        <!--   CUSTOM FIELDS   -->
                        <div v-if="selectedCrm.payload.custom_fields?.length" class="border-t border-gray-200 pt-6 mt-8">
                            <h2 class="font-semibold pb-3">Fields</h2>
                            <div class="grid grid-cols-2 items-start gap-4 gap-y-6">
                                <div v-for="customField in selectedCrm.payload.custom_fields"
                                     :key="customField.key"
                                >
                                    <div class="grid grid-cols-2 gap-x-2">
                                        <CustomInput
                                            label="Key"
                                            v-model="customField.key"
                                            :input-disabled="true"
                                        />
                                        <div class="relative">
                                            <CustomInput
                                                label="Value"
                                                v-model="customField.value"
                                                :input-disabled="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--   JSON FIELDS   -->
                        <div v-if="selectedCrm.payload.json_fields?.length" class="border-t border-gray-200 pt-6 mt-8">
                            <h2 class="font-semibold pb-3">Fields</h2>
                            <div class="flex flex-col gap-y-6">
                                <div v-for="jsonField in selectedCrm.payload.json_fields"
                                     :key="jsonField.key"
                                >
                                    <div class="flex items-start gap-x-2">
                                        <CustomInput
                                            class="w-64"
                                            label="Key"
                                            :model-value="jsonField.key"
                                            :input-disabled="true"
                                        />
                                        <div class="relative w-full">
                                            <textarea
                                                class="rounded text-sm font-medium w-full border focus:outline-none outline-none focus:ring-0 focus:border-primary-500 focus:shadow-lg focus:shadow-primary-500/10"
                                                :value="jsonField.value"
                                                :name="jsonField.key"
                                                rows="4"
                                                :disabled="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import { computed, ComputedRef, onMounted, Ref, ref } from "vue";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import { CrmDeliverer, CrmType, useDeliveryStore } from "@/stores/v4/delivery";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const deliveryStore = useDeliveryStore();

const emit = defineEmits(['close:modal', 'import:crm']);

const loading = ref(true);

const selectedCrmId: Ref<number|null> = ref(null);
const selectedCrm: ComputedRef<CrmDeliverer|null> = computed(() => {
    return selectedCrmId
        ? deliveryStore.crmImportOptions.find(crm => crm.id === selectedCrmId.value) as CrmDeliverer
        : null;
});

const crmOptions: ComputedRef<CustomSelectOption[]> = computed(() => [
    { label: '-', value: null },
    ...deliveryStore.crmImportOptions.map(config => ({ label: `${config.display_name}${config.campaign_name ? ` (${config.campaign_name})` : ''}`, value: config.id })),
]);

const showPresetFields: ComputedRef<boolean> = computed(() => {
    if (selectedCrm.value?.crm_type === CrmType.StandardWebForm) {
        const filledFields = selectedCrm.value?.payload.additional_fields?.filter(v => v.value);
        if (!filledFields?.length)
            return false;
    }
    return true;
});

onMounted(async () => {
    initialize();
});

const initialize = async () => {
    await deliveryStore.getCrmImportOptions();
    loading.value = false;
}

const importCrmConfiguration = () => {
    emit('import:crm', selectedCrmId.value);
    closeModal();
}

const closeModal = () => {
    deliveryStore.clearEditingConfiguration();
    emit('close:modal');
}


</script>