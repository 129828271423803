import { defineStore } from "pinia";
import { computed, ComputedRef, ref, Ref } from "vue";
import { ProductType, useServicesStore } from "@/stores/services";
import { useLocalityDataStore } from "@/stores/locality-data";
import { useProperCase } from "@/composables/useProperCase";
import { AxiosResponse } from "axios";
import { BaseApiServiceV4 } from "@/services/api/v4/BaseApiServiceV4";
import { KeyedZipCodeCollection } from "@/components/v4/wizard/slides/components/LocationCheckboxSelect.vue";
import { useProductConfigurationStore } from "@/stores/v4/product-configuration";
import { ReservedComponent } from "@/stores/v4/wizard";
import { CampaignSlide } from "@/stores/v4/campaign-modules";
import { useBiddingStore } from "@/stores/v4/bidding";
import {useDownloadFile} from "@/composables/useDownloadFile";
import {useCreateCsvString} from "@/composables/useCreateCsvString";
import { useCompanyStore } from "@/stores/company";

export enum CampaignType {
    LEAD_CAMPAIGN = 0,
    APPOINTMENT_CAMPAIGN = 1,
    SOLAR_LEAD_CAMPAIGN = 2,
    SOLAR_APPOINTMENT_CAMPAIGN = 3,
    EXCLUSIVE_ONLY = 5,
}

export type CampaignSummary = {
    name: string,
    reference: string,
    status: number,
    status_display: string,
    active: boolean,
    state_count: number,
    zip_code_count: number,
    average_daily_spend: number,
    average_daily_won: number,
    average_daily_available: number,
    product: string,
}

export type CampaignSummaryStore = {
    [key in ProductType]: {
        [serviceKey: string]: CampaignSummary[],
    }
}

export enum CampaignStatus {
    PausedPermanently = 0,
    PausedTemporarily = 1,
    Active = 2,
}

export type CampaignPausePayload = {
    status: number,
    reason: string,
    references: string[],
}

export type CampaignUnpausePayload = {
    references: string[],
}

type ZipCodeCountyExceptionCollection = {
    [stateKey: string]: string[],
}

export type NewCampaignParameters = {
    zipCodeTargeted?: boolean,
    customBudgetType?: string,
}

const getDefaultCampaignSummary = () => ({
    [ProductType.Lead]: {},
    [ProductType.Appointment]: {},
    [ProductType.DirectLeads]: {},
});

/**
 * All v4 / future campaign components should pass through here, to keep all legacy store reference in one place
 */
export const useFutureCampaignStore = defineStore('futureCampaigns', () => {
    const services = useServicesStore();
    const productConfigurationStore = useProductConfigurationStore();
    const biddingStore = useBiddingStore();
    const companyStore = useCompanyStore();

    const initialized: Ref<boolean> = ref(false);

    const legacyLocalityStore = useLocalityDataStore();

    const campaigns: Ref<CampaignSummaryStore> = ref(getDefaultCampaignSummary());
    const editingCampaign: Ref<GenericObject> = ref({});
    const scopedCampaignList: ComputedRef<CampaignSummary[]> = computed(() => {
        return campaigns.value[productScope.value]?.[serviceScope.value] ?? [];
    });

    const productScope: ComputedRef<ProductType> = computed(() => services.apiServiceV4.getProductKey());
    const serviceScope: ComputedRef<string> = computed(() => services.apiServiceV4.getServiceKey());
    const currentCampaignType: ComputedRef<CampaignType> = computed(() => editingCampaign.value.type ?? 0);

    const zipCodeCountyExceptions: Ref<ZipCodeCountyExceptionCollection> = ref({});
    const zipCodeTargetingEnabled: ComputedRef<boolean> = computed(() => {
        for (const state in zipCodeCountyExceptions.value) {
            if (zipCodeCountyExceptions.value[state].length)
                return true;
        }
        return false;
    });
    const zipCodeCampaign: Ref<boolean> = computed(() => !!editingCampaign.value.zip_code_targeted);
    const usesCustomFloorPrices: Ref<boolean> = computed(() => !!editingCampaign.value.uses_custom_floor_prices);
    const unrestrictedZipCodes: Ref<boolean> = computed(() => companyStore.unrestrictedZipCodes);

    const activeZipCodes: Ref<KeyedZipCodeCollection> = ref({});

    const defaultPauseReason = 'budget';

    const statistics: Ref<GenericObject> = ref({});

    const initialize = async (forceInitialize: boolean = false): Promise<StatusResponse> => {
        if (initialized.value && !forceInitialize) return { status: true }
        const productConfigResponse = await productConfigurationStore.initialize();
        const getCampaignsResponse = await getCampaigns().catch(e => e);
        if (getCampaignsResponse.status && productConfigResponse.status) {
            initialized.value = true;

            return { status: true }
        }

        return { status: false, message: 'Failed to initialize Campaign Store.'};
    }

    const updateModulePayload = (slideKey: string, payload: GenericObject) => {
        editingCampaign.value[slideKey] = payload;
    }

    const updateActiveZipCodes = (zipCodes: KeyedZipCodeCollection) => {
        activeZipCodes.value = zipCodes;
    }

    const getActiveZipCodes = (): KeyedZipCodeCollection => {
        return JSON.parse(JSON.stringify(activeZipCodes.value ?? []));
    }

    const getCampaigns = async (): Promise<StatusResponse> => {
        const resp = await services.apiServiceV4.getCampaigns(true).catch(e => e);
        if (resp.data?.data?.status) {
            sortCampaignsByProductScope(resp.data.data.campaigns);
            zipCodeCountyExceptions.value = resp.data.data.zip_code_exceptions ?? [];

            return { status: true }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const getCampaignList = async (forceRefresh?: boolean): Promise<DataResponse> => {
        if (!forceRefresh) {
            const cached = campaigns.value[productScope.value]?.[serviceScope.value];
            if (cached?.length)
                return { status: true, data: cached }
        }

        const fetchCampaignResponse = await getCampaigns();
        if (fetchCampaignResponse.status) {
            return { status: true, data: campaigns.value[productScope.value][serviceScope.value] }
        }
        else {
            return fetchCampaignResponse
        }
    }

    const newCampaignConfiguration = async (configuration: NewCampaignParameters): Promise<StatusResponse> => {
        const resp = await services.apiServiceV4.newCampaign(configuration).catch(e => e);
        if (resp.data.data.status) {
            editingCampaign.value = resp.data.data.campaign;
            setZipCodeTargeting(!!configuration.zipCodeTargeted);
            productConfigurationStore.currentBudgetConfiguration = resp.data.data.campaign_configuration?.budgets ?? productConfigurationStore.currentBudgetConfiguration;

            return { status: true }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const getCampaignDetailByUuid = async (campaignReference: string): Promise<StatusResponse> => {
        const resp = await services.apiServiceV4.getCampaignDetail(campaignReference).catch(e => e);
        if (resp.data?.data?.status) {
            editingCampaign.value = resp.data.data.campaign;
            productConfigurationStore.currentBudgetConfiguration = resp.data.data.campaign_configuration?.budgets ?? productConfigurationStore.currentBudgetConfiguration;
            if (editingCampaign.value.location?.zip_codes) {
                updateActiveZipCodes(editingCampaign.value.location?.zip_codes);
            }
            return { status: true }
        }
        else
            return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const saveCampaign = async (payloadModules?: string[]): Promise<StatusResponse> => {
        const payload = prepareCampaignPayload(payloadModules ?? undefined);
        const resp = editingCampaign.value.reference
            ? await services.apiServiceV4.updateCampaign(payload, editingCampaign.value.reference).catch(e => e)
            : await services.apiServiceV4.saveNewCampaign(payload).catch((e: AxiosResponse)  => e);
        if (resp.data?.data?.status) {
            if (!payloadModules || payloadModules.includes('bidding'))
                biddingStore.$reset();

            return { status: true }
        }
        else {
            return BaseApiServiceV4.transformErrorResponse(resp);
        }
    }

    const saveCampaignModule = async (moduleName: string) => {
        return await saveCampaign([moduleName]);
    }

    const prepareCampaignPayload = (includeModules?: string[]) => {
        const basePayload: GenericObject = {};
        includeModules = includeModules ?? Object.values(CampaignSlide);

        for (const slideKey in editingCampaign.value) {
            if (includeModules.includes(slideKey)) {
                basePayload[slideKey] = editingCampaign.value[slideKey];
            }
        }

        return {
            status: editingCampaign.value.status ?? CampaignStatus.Active,
            name: editingCampaign.value.name,
            type: editingCampaign.value.type,
            reference: editingCampaign.value.reference ?? null,
            product: productScope.value,
            service: serviceScope.value,
            zip_code_targeted: companyStore.unrestrictedZipCodes || (editingCampaign.value.zip_code_targeted ?? false),
            property_types: editingCampaign.value.property_types,
            payload: JSON.parse(JSON.stringify(basePayload)),
        }
    }

    /**
     * This currently appends header data from the Wizard (campaign name and property types)
     */
    const appendAdditionalCampaignComponents = (payload: GenericObject) => {
        const headerData = payload[ReservedComponent.Header];
        if (headerData)
            Object.assign(editingCampaign.value, headerData);
    }

    const pauseCampaigns = async (payload: CampaignPausePayload): Promise<StatusResponse> => {
        const resp = await services.apiServiceV4.pauseCampaigns(payload).catch(e => e);
        if (resp.data?.data?.status) {
            toggleCampaignActiveFlags(payload.references, false);

            return { status: true }
        }
        else {
            toggleCampaignActiveFlags(payload.references, true);

            return BaseApiServiceV4.transformErrorResponse(resp);
        }
    }

    const unpauseCampaigns = async (payload: CampaignUnpausePayload): Promise<StatusResponse> => {
        const resp = await services.apiServiceV4.unpauseCampaigns(payload).catch(e => e);
        if (resp.data?.data?.status) {
            toggleCampaignActiveFlags(payload.references, true);

            return { status: true }
        }
        else {
            toggleCampaignActiveFlags(payload.references, false);

            return BaseApiServiceV4.transformErrorResponse(resp);
        }
    }

    const deleteCampaign = async (campaignReference: string): Promise<StatusResponse> => {
        const resp = await services.apiServiceV4.deleteCampaign(campaignReference).catch(e => e);
        if (resp.data?.data?.status) {
            const targetIndex = scopedCampaignList.value.findIndex(campaign => campaign.reference === campaignReference);
            if (targetIndex !== -1)
                scopedCampaignList.value.splice(targetIndex, 1);

            return { status: true }
        }
        else return BaseApiServiceV4.transformErrorResponse(resp);
    }

    const fetchModuleInputValue = (payloadKey: string|null, inputKey: string): any => {
        return payloadKey
            ? editingCampaign.value[payloadKey]?.[inputKey] ?? null
            : editingCampaign.value[inputKey] ?? null;
    }

    const getCampaignByUuid = (uuid: string) => scopedCampaignList.value.find(campaign => campaign.reference === uuid) ?? null;

    const getCampaignNameByUuid = (uuid: string) => getCampaignByUuid(uuid)?.name ?? "Unknown Campaign";

    const toggleCampaignActiveFlags = (campaignReferences: string[], newValue: boolean) => {
        campaignReferences.forEach(campaignReference => {
            const target = getCampaignByUuid(campaignReference);
            if (target)
                target.active = newValue;
        });
    }

    const getProductLabel = (plural?: boolean) => {
        const label = useProperCase(productScope.value);
        return plural
            ? `${label}s`
            : label;
    }

    const $reset = () => {
        editingCampaign.value = {};
    }

    const loadBidingLocationStatistics = (stateLocationId: number, countyLocationId: number|null, property: string) => {
        const date = new Date();
        const params = {
            state_location_id: stateLocationId,
            county_location_id: countyLocationId || null,
            property: property,
            grouping: 'location',
            start_timestamp: (date.setMonth(date.getMonth() - 1))/1000,
            //@ts-ignore
            end_timestamp: date/1000
        };

        services.apiServiceV4.getStatisticsByLocation(params)
            .then(resp => statistics.value = resp.data.data?.statistics ?? {})
            .catch(e => BaseApiServiceV4.transformErrorResponse(e));
    };

    const downloadAllCampaignsZipCodes = async ()=> {
        const uuids: string[] = campaigns.value[productScope.value][serviceScope.value].map((campaign: CampaignSummary) => campaign.reference);
        const resp = await services.apiServiceV4.getZipCodes({uuids: uuids});

        if (resp.data?.data?.status) {
            useDownloadFile(
                useCreateCsvString(['Zip Codes'], resp.data.data.zip_codes),
                'All_Campaigns_Zip_Codes'
            );

            return {status: true, message: ''};
        }

        return { status: false, message: 'Failed to download zip codes.'};
    }

    const sortCampaignsByProductScope = (campaignsArray: CampaignSummary[]) => {
        campaigns.value = getDefaultCampaignSummary();
        campaignsArray.forEach(campaign => {
            const productKey = campaign.product?.toLowerCase() ?? productScope.value;
            const serviceKey = serviceScope.value;
            campaigns.value[productKey as ProductType][serviceKey] = campaigns.value[productKey as ProductType][serviceKey] ?? [];
            campaigns.value[productKey as ProductType][serviceKey].push(campaign);
        });
    }

    // This can only be set on a fresh campaign
    const setZipCodeTargeting = (newSetting?: boolean) => {
        if (editingCampaign.value.reference)
            console.error("Cannot convert a standard campaign to zip code targeting");
        else
            editingCampaign.value.zip_code_targeted = !!newSetting;
    }

    return {
        productScope,
        campaigns,
        editingCampaign,
        scopedCampaignList,
        productConfiguration: productConfigurationStore.configuration,
        activeZipCodes,
        statusConfiguration: productConfigurationStore.campaignStatusConfiguration,
        defaultPauseReason,
        statistics,
        zipCodeCountyExceptions,
        zipCodeTargetingEnabled,
        zipCodeCampaign,
        currentCampaignType,
        usesCustomFloorPrices,
        unrestrictedZipCodes,

        initialize,
        getCampaigns,
        getCampaignList,
        pauseCampaigns,
        unpauseCampaigns,
        deleteCampaign,
        getCampaignNameByUuid,
        getCampaignDetailByUuid,
        updateModulePayload,
        fetchModuleInputValue,
        getProductLabel,
        saveCampaign,
        appendAdditionalCampaignComponents,
        getActiveZipCodes,
        updateActiveZipCodes,
        saveCampaignModule,
        $reset,
        loadBidingLocationStatistics,
        downloadAllCampaignsZipCodes,
        setZipCodeTargeting,
        newCampaignConfiguration,

        // Legacy passthroughs - replace with /v4 data when possible
        localityStore: legacyLocalityStore,
    }
});